import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ContactModel } from "types";
import { ConversationService, SharedService } from "../../../modules/core";
import { NetworkParams } from "../network/network.component";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss", "./../network-style.scss"],
})
export class ContactComponent implements OnInit {
  userContact: ContactModel;
  contactId: string;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private conversationService: ConversationService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: NetworkParams) => {
      if (!params.contactId) return;
      // this.contactId = params.contactId;
      this.loadContact(params.contactId);
      // window.scrollTo(0,0);
    });
  }

  loadContact(contactId) {
    this.userContact = undefined;
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.conversationService.getUserV2(contactId).subscribe((user) => {
      this.userContact = user;
    });
  }
}
