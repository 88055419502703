import { Location } from "@angular/common";
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { isOnline as isInternetOnline } from "@utils";
import { Observable, Subject } from "rxjs";
import { retry } from "rxjs/operators";
import {
  ActionInvitationResponseModel,
  BasicContactModelPagedResult,
  CompanyBasicModel,
  CompanyContactsRequestOptions,
  ContactModelPagedResult,
  ContactsV2RequestOptions,
  ConversationModelV2,
  ConversationParticipantModel,
  SuggestedUserModel,
  UserProfileModelApiPagedResult,
  UsersV2RequestOptions
} from "types";
import { environment } from "../../../../environments/environment";
import { MuteMenuComponent } from "../../conversations/conversation-list/mute-menu/mute-menu.component";
import { AuthService } from "../services/auth.service";
import { AlertService } from "./alert.service";
import { AnalyticsService } from "./analytics.service";

interface ActionInvitationSuccess {
  errored: false;
  data: ActionInvitationResponseModel;
}

interface ActionInvitationError {
  errored: true;
  data: any; // typed as 'any' to support legacy code
}

export type ActionInvitationData =
  | ActionInvitationSuccess
  | ActionInvitationError;

/** @deprecated Do not use this service in any new components or services. */
@Injectable()
export class SharedService {
  INVITATION_VALIDITY_IN_SECONDS = 600;
  countries: any;
  sub1: any;
  count: number;
  pageTitle: string;
  dialogRef: any;
  protected basePath = environment.celoApiEndpoint;
  private onFocusSubject = new Subject<any>();
  public onFocusChange = this.onFocusSubject.asObservable();

  public onBlocklistSubject = new Subject<void>();
  public onBlocklistChange = this.onFocusSubject.asObservable();

  apps = [
    {
      target_url:
        "https://apps.apple.com/nz/app/celo-connecting-healthcare/id1176847079",
      qrcode_url: "../../../assets/qrcode-ios.png",
      badge_url: "../../../assets/app-store-badge.svg",
    },
    {
      target_url:
        "https://play.google.com/store/apps/details?id=nz.co.celo.app",
      qrcode_url: "../../../assets/qrcode-android.png",
      badge_url: "../../../assets/google-play-badge.svg",
    },
  ];

  STANDARD_ERROR_MESSAGE = "Oops, something went wrong!";
  readyToDownload: boolean;
  dummyProfessions: any = [
    {
      category: "Medical Practitioner",
      profession: "Practitioner",
      verificationStatus: "Verified",
    },
  ];

  isLoading = false; // user-resolve service informs app.component to show spinner
  // isLoadingConversation = false;
  public defaultHeaders: Headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    CeloAuthorization: environment.celoAuth,
    AppVersion: environment.appVersion,
  });

  private onCallSource = new Subject<boolean>();
  onCallEmitted$ = this.onCallSource.asObservable();
  private notificationCountSource = new Subject<number>();
  notificationCount$ = this.notificationCountSource.asObservable();
  private toggleProfileSource = new Subject<boolean>();
  toggleProfileEmitted$ = this.toggleProfileSource.asObservable();

  private conversations: ConversationModelV2[] = [];
  private remoteUnreadConversationIds: string[] = [];

  // `conversations` can be replaced by other services, e.g. when conversations are loaded
  // due to a user navigating to the conversations tab. This is a record of any conversation
  // that has been seen by this service
  private allConversations: Map<string, ConversationModelV2> = new Map();

  public removeConversationById(conversationId: string) {
    this.allConversations.delete(conversationId.toLowerCase());
  }

  standardErrorDialog() {
    this.alertService.confirm("", this.STANDARD_ERROR_MESSAGE, "Ok", "", true);
  }

  formatByField(array, fieldName) {
    let pivot = 0;
    for (let index = 0; index < array.length; index++) {
      const contact = array[index];
      if (contact[fieldName] == true || contact[fieldName] == "true") {
        array.splice(index, 1);
        array.splice(pivot++, 0, contact);
      }
    }
  }

  standardErrorToast() {
    this.alertService.showSnackBar(this.STANDARD_ERROR_MESSAGE, 4);
  }

  getCodeFromInvitationLink(linkified_text: string) {
    return this.getParameterByName("code", linkified_text);
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  getImageQuality(): boolean {
    return localStorage.getItem("highQualityImages") == "true" ? true : false;
  }

  setImageQuality() {
    localStorage.setItem("highQualityImages", "true");
  }

  removeImageQuality() {
    localStorage.removeItem("highQualityImages");
  }

  navigateToNetwork() {
    this.navigate(["/network"]);
  }

  navigateToDirectory(workspace_id: any) {
    this.navigate(["/network/workspace", workspace_id]);
  }

  removeInviteCode() {
    localStorage.removeItem("invite_code");
  }

  handleInvitationCode(inviteCode: any, callback?) {
    const path =
      environment.celoApiEndpoint + "/api/v2/invitations/" + inviteCode;

    this.getObjectById(path).subscribe(
      (res) => {
        if (callback) {
          callback(res);
        }
      },
      (err) => {
        if (callback) {
          callback(err);
        }
      }
    );
  }

  invite(invite_type, link?, id?, object?, workspace_name?) {
    const payload = [
      {
        type: "email_invite",
        invite_type,
        link,
        id,
        payload: object,
        workspace_name,
      },
    ];
    let title = "";
    switch (invite_type) {
      case "user":
        title = "Add Colleagues";
        break;
      case "group":
        title = "Invite via email";
        break;
      case "case":
        title = "Invite via email";
        break;
      case "workspace":
        title = "Invite via email";
        break;
    }
    this.alertService.customDialog(
      title,
      "",
      "",
      "",
      false,
      "",
      payload,
      false
    );
  }

  extractIdsFromArray(array): string[] {
    const ids: string[] = [];
    for (const item of array) {
      ids.push(item["id"]);
    }
    return ids;
  }

  extractIds(prefix, qp): string[] {
    const ids: string[] = [];
    let idx = 0;
    while (true) {
      if (qp[`${prefix}${idx}`]) {
        ids.push(qp[`${prefix}${idx}`]);
        idx += 1;
      } else {
        break;
      }
    }
    return ids;
  }

  constructor(
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private analyticsService: AnalyticsService,
    private _location: Location,
    private titleService: Title,
    private meta: Meta
  ) {
    window.onfocus = () => {
      this.onFocusSubject.next(true);
    };
    window.onblur = () => {
      this.onFocusSubject.next(false);
    };

    // Do not remove. The response of this call is intercepted to find out and set the auth_region
    this.getAndSetRegion();
    this.sub1 = this.notificationCount$.subscribe((count) => {
      this.count = count;
      if (location.pathname.startsWith('/call')) return;
      this.setTitle(this.pageTitle);
    });
  }

  closeAllDialogs() {
    this.dialog.closeAll();
  }

  onWorkspaceRemoveSuccess(data, emails) {
    const payload = [
      {
        type: "removedWorkspace",
        emails,
      },
    ];
    const dialogRef = this.alertService.customDialog(
      "",
      "",
      "DONE",
      "",
      false,
      "vertical",
      payload
    );
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getAndSetRegion() {
    const path =
      environment.celoAuthApiEndpoint + "/.well-known/openid-configuration";
    this.getObjectById(path).subscribe((res) => {});
  }

  isValidInput(src) {
    if (!src) {
      return false;
    }
    if (!src.trim().length) {
      return false;
    }
    return true;
  }

  public setTitle(pageTitle?: string) {
    this.pageTitle = pageTitle;
    pageTitle = pageTitle ? pageTitle : "Celo";
    this.titleService.setTitle(
      (this.count ? "(" + this.count + ") " : "") + pageTitle
    );
  }

  public setMeta(name: string, content: string) {
    this.meta.updateTag({ name, content });
  }

  checkCommonWorkspace(workspaces1, workspaces2) {
    if (!workspaces1 || !workspaces2) return false;
    for (const workspace1 of workspaces1) {
      for (const workspace2 of workspaces2) {
        if (
          workspace1.isActive &&
          workspace2.isActive &&
          workspace1.companyId == workspace2.id
        ) {
          return true;
        }
      }
    }
    return false;
  }

  getActiveWorkspaces(workspaces) {
    const active = [];
    for (const workspace of workspaces) {
      if (workspace.isActive) {
        active.push(workspace);
      }
    }
    return active;
  }

  getVerifiedWorkspaces(workspaces) {
    const active = [];
    for (const workspace of workspaces) {
      if (workspace.verificationStatus == "Verified") {
        // if(workspace.verificationStatus){
        active.push(workspace);
      }
    }
    return active;
  }

  displayErrorDialog(err: any, buttonText?) {
    let reason;
    reason = this.getErrorMessage(err);
    return this.alertService.confirm(
      "",
      reason,
      buttonText ? buttonText : "Cancel",
      "",
      true
    );
  }

  pullToTop(array: any[], fieldName, fieldValue: string, allowPartialMatch?) {
    if (!array || !fieldName) {
      return;
    }
    let value: string;
    for (let index = 0; index < array.length; index++) {
      value = array[index][fieldName];
      if (
        value &&
        (value.toLowerCase() === fieldValue.toLowerCase() ||
          (allowPartialMatch &&
            value.toLowerCase().indexOf(fieldValue.toLowerCase()) != -1))
      ) {
        array.splice(0, 0, array[index]);
        array.splice(index + 1, 1);
        return;
      }
    }
  }

  scrollIntoView(el: HTMLElement) {
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  spliceArrayByFieldValue(array: any[], fieldName, fieldValue: string) {
    if (!array || !fieldName) {
      return;
    }
    let value: string;
    for (let index = 0; index < array.length; index++) {
      value = array[index][fieldName];
      if (value.toLowerCase() === fieldValue.toLowerCase()) {
        array.splice(index, 1);
        return;
      }
    }
  }

  sortArrayByField(array: any[], fieldName) {
    if (!array || !array.length || !fieldName) {
      return;
    }
    let keyA;
    let keyB;
    array.sort((a, b) => {
      keyA = a[fieldName].toLowerCase();
      keyB = b[fieldName].toLowerCase();
      return keyA > keyB ? 1 : keyB > keyA ? -1 : 0;
    });
  }

  sortParticipantsByFullname(
    participants: ConversationParticipantModel[]
  ): void {
    if (!participants || !participants.length) {
      return;
    }
    participants.sort((a, b) => {
      const keyA = a.firstName.toLowerCase() + a.lastName.toLowerCase();
      const keyB = b.firstName.toLowerCase() + b.lastName.toLowerCase();
      return keyA > keyB ? 1 : keyB > keyA ? -1 : 0;
    });
  }

  sortAdmins(
    participants: ConversationParticipantModel[],
    adminUserIds: string[]
  ): void {
    const admins = new Set(adminUserIds);
    participants.sort((a, b) => {
      const isAdminA = admins.has(a.userId);
      const isAdminB = admins.has(b.userId);
      if (isAdminA && isAdminB) {
        return 0;
      }
      if (isAdminA) {
        return -1;
      }
      return 1;
    });
  }

  sortDateArrayByField(array: any[], fieldName) {
    if (!array || !array.length || !fieldName) {
      return;
    }
    let keyA;
    let keyB;
    array.sort((a, b) => {
      keyA = a[fieldName];
      keyB = b[fieldName];
      return keyA > keyB ? 1 : keyB > keyA ? -1 : 0;
    });
  }

  openMuteDialog(data): Observable<any> {
    const config = new MatDialogConfig();
    config.panelClass = "mute-dialog-box";
    config.data = data;
    const ref = this.dialog.open(MuteMenuComponent, config);
    ref.componentInstance["title"] = "Mute notifications for this conversation";
    ref.componentInstance["subtitle"] = "";
    ref.componentInstance["action"] = "MUTE";
    ref.componentInstance["configData"] = config.data;
    return ref.afterClosed();
  }

  openDNDDialog(data): Observable<any> {
    const config = new MatDialogConfig();
    config.panelClass = "mute-dialog-box";
    config.data = data;
    const ref = this.dialog.open(MuteMenuComponent, config);
    ref.componentInstance["title"] = "Do not disturb";
    ref.componentInstance["subtitle"] =
      "You will stop receiving Celo notifications within the selected time.";
    ref.componentInstance["action"] = "DONE";
    ref.componentInstance["configData"] = config.data;
    return ref.afterClosed();
  }

  getContacts(
    params?: CompanyContactsRequestOptions
  ): Observable<ContactModelPagedResult> {
    const path = `${environment.celoApiEndpoint}/api/Contacts`;
    return this.getObjectById(path, params);
  }

  getContactsV2(
    params?: ContactsV2RequestOptions
  ): Observable<UserProfileModelApiPagedResult> {
    const path = `${environment.celoApiEndpoint}/api/v2/Contacts`;
    return this.getObjectById(path, params);
  }

  getCompanyContacts(
    companyId: string,
    params?: any
  ): Observable<ContactModelPagedResult> {
    const path = `${environment.celoApiEndpoint}/api/Companies/${companyId}/Contacts`;
    return this.getObjectById(path, params);
  }

  getSuggestedContacts(): Observable<SuggestedUserModel[]> {
    const path = `${environment.celoApiEndpoint}/api/v2/users/suggestions`;
    return this.getObjectById(path);
  }

  getUsersV2(
    params?: UsersV2RequestOptions
  ): Observable<BasicContactModelPagedResult> {
    const path = `${environment.celoApiEndpoint}/api/v2/users`;
    return this.getObjectById(path, params);
  }

  getObjectById<T = any>(path: string, params: any = {}): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        CeloAuthorization: environment.celoAuth,
        AppVersion: environment.appVersion,
      }),
      params,
    };
    return this.http.get<T>(path, httpOptions);
  }

  deleteObjectById<T = any>(path: string, body?: any): Observable<T> {
    if (!this.isOnline()) {
      this.noInternetSnackbar();
      return;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        CeloAuthorization: environment.celoAuth,
        Authorization: "Bearer " + this.authService.getAccessToken(),
        AppVersion: environment.appVersion,
      }),
      body,
    };
    return this.http.delete<T>(path, httpOptions);
  }

  putObjectById<T = any>(
    path: string,
    params?: any,
    bodyParams?: any
  ): Observable<T> {
    if (!this.isOnline()) {
      this.noInternetSnackbar();
      return;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        CeloAuthorization: environment.celoAuth,
        Authorization: "Bearer " + this.authService.getAccessToken(),
        AppVersion: environment.appVersion,
      }),
      params,
    };

    return this.http.put<T>(path, bodyParams, httpOptions);
  }

  postObjectById<T = any>(
    path: string,
    params?: any,
    bodyParams?: any,
    customHeaders?: any
  ): Observable<T> {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      CeloAuthorization: environment.celoAuth,
      Authorization: this.authService.getAccessToken()
        ? "Bearer " + this.authService.getAccessToken()
        : "",
      AppVersion: environment.appVersion,
    };
    for (const key in customHeaders) {
      if (Object.prototype.hasOwnProperty.call(customHeaders, key)) {
        const element = customHeaders[key];
        headers[key] = element;
      }
    }

    const httpOptions = {
      headers: new HttpHeaders(headers),
      params,
    };

    return this.http.post<T>(path, bodyParams, httpOptions);
  }

  patchObjectById(path: string, patch?: any): Observable<any> {
    if (!this.isOnline()) {
      this.noInternetSnackbar();
      return;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/json",
        CeloAuthorization: environment.celoAuth,
        Authorization: this.authService.getAccessToken()
          ? "Bearer " + this.authService.getAccessToken()
          : "",
      }),
    };
    return this.http.patch(path, patch, httpOptions);
  }

  formatCompanies(companies) {
    const formated = [];
    for (const company of companies) {
      const workspace = {
        companyId: company["id"],
        companyName: company["name"],
        isActive: company["isActive"],
        countryCode: company["countryCode"],
      };
      formated.push(workspace);
    }
    return formated;
  }

  getContact(id, callback) {
    const path = environment.celoApiEndpoint + "/api/v2/users/" + id;
    this.getObjectById(path).subscribe(
      (contact) => {
        if (contact) {
          callback(contact);
        }
      },
      (err) => {
        callback(null);
      }
    );
  }

  emitOnCall(isOnCall: boolean) {
    this.onCallSource.next(isOnCall);
  }

  updateNotificationCount(count: number) {
    this.notificationCountSource.next(count);
  }

  private updateUnreadConversationsCountInternal(): number {
    /** One of the following must be true for a conversation to be considered unread:
     *   - Conversation is loaded AND it has at least one unread messages
     *   - Conversation is NOT loaded
     */

    const conversations = Array.from(this.allConversations.values());

    const unreadConversationIds = new Set(
      conversations
        .filter((c) => c.unreadMessageIds && c.unreadMessageIds?.length > 0)
        .map((c) => c.id)
    );

    const remoteUnloadedConversationIds =
      this.remoteUnreadConversationIds.filter(
        (id) => !conversations.some((c) => c.id === id)
      );

    remoteUnloadedConversationIds.forEach((id) =>
      unreadConversationIds.add(id)
    );

    const numberOfUnreadConversations = unreadConversationIds.size;
    this.updateNotificationCount(numberOfUnreadConversations);
    return numberOfUnreadConversations;
  }

  public updateRemoteUnreadConversationIds(conversationIds: string[]) {
    this.remoteUnreadConversationIds = conversationIds;
    this.updateUnreadConversationsCountInternal();
  }

  public updateNotificationAfterCounting = (
    conversations: ConversationModelV2[]
  ) => {
    this.conversations = conversations;

    conversations.forEach((c) => {
      if (!c.id) return;
      this.allConversations.set(c.id.toLowerCase(), c);
    });

    return this.updateUnreadConversationsCountInternal();
  };

  toggleProfile(show: boolean) {
    this.toggleProfileSource.next(show);
  }

  getPartneredCompanies(callback?) {
    const page = "0";
    const pageSize = "1000";
    const path = `${environment.celoApiEndpoint}/api/v2/Companies`;
    const params = new HttpParams().set("Page", page).set("PageSize", pageSize);

    // todo: make companies an optional input?
    this.httpClient
      .get<any>(path, { params })
      .pipe(retry(3))
      .subscribe((companies: any) => {
        callback(companies);
      });
  }

  filterCompanies(companies, val) {
    if (val && val.name) {
      val = val.name;
    }
    return val
      ? companies.filter((s) => this.nearMatch(s.name, val))
      : companies;
  }

  filterCompaniesByIds(
    companies: CompanyBasicModel[],
    ids: string[],
    isWhiteList: boolean = true
  ) {
    if (!ids.length) {
      return companies;
    }

    let predicate = (c: CompanyBasicModel) => ids.includes(c.id);
    if (!isWhiteList) {
      predicate = (c: CompanyBasicModel) => !ids.includes(c.id);
    }

    return companies.filter(predicate);
  }

  nearMatch = (s, txt) => {
    s = s.toLowerCase();
    txt = txt.toLowerCase();
    if (s.indexOf(txt) !== -1) {
      // sub string
      return true;
    }
    if (txt.length > 2) {
      const d = this.levenshtein(txt, s.substr(0, txt.length + 1));
      return d < 4;
    }
    return false;
  };

  getChatId(receiver_id, sender_id) {
    const ids = [];
    ids.push(receiver_id);
    ids.push(sender_id);
    ids.sort();
    const conversationId = ids.join("_");
    return conversationId;
  }

  levenshtein = (a, b) => {
    const alen = a.length;
    const blen = b.length;
    if (alen === 0) {
      return blen;
    }
    if (blen === 0) {
      return alen;
    }
    let tmp;
    let i;
    let j;
    let prev;
    let val;
    let row;
    let ma;
    let mb;
    let mc;
    let md;
    let bprev;

    if (alen > blen) {
      tmp = a;
      a = b;
      b = tmp;
    }

    row = new Int8Array(alen + 1);
    // init the row
    for (i = 0; i <= alen; i++) {
      row[i] = i;
    }

    // fill in the rest
    for (i = 1; i <= blen; i++) {
      prev = i;
      bprev = b[i - 1];
      for (j = 1; j <= alen; j++) {
        if (bprev === a[j - 1]) {
          val = row[j - 1];
        } else {
          ma = prev + 1;
          mb = row[j] + 1;
          mc = ma - ((ma - mb) & ((mb - ma) >> 7));
          md = row[j - 1] + 1;
          val = mc - ((mc - md) & ((md - mc) >> 7));
        }
        row[j - 1] = prev;
        prev = val;
      }
      row[alen] = prev;
    }
    return row[alen];
  };

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  getErrorMessage(err) {
    let reason;
    try {
      err = JSON.parse(err._body);
      if (err[""]) {
        reason = err[""][0];
      } else if (err["errors"]) {
        reason = err["errors"][0];
        reason = reason.message ? reason.message : reason;
      }
    } catch (error) {
      reason = "";
    }
    reason = reason ? reason : "An error has occurred";
    return reason;
  }

  getErrorMessageFromErrorObject(err) {
    let reason;
    try {
      if (err[""]) {
        reason = err[""][0];
      } else if (err["errors"]) {
        reason = err["errors"][0];
        reason = reason.message ? reason.message : reason;
      }
    } catch (error) {
      reason = "";
    }
    reason = reason ? reason : "An error has occurred";
    return reason;
  }

  getOnboardingErrorMessage(err, defaultError?: string) {
    if (err && err.error && err.error.errors) {
      for (const key in err.error.errors) {
        if (Object.prototype.hasOwnProperty.call(err.error.errors, key)) {
          const element = err.error.errors[key];
          if (element[0]) {
            return element[0];
          }
        }
      }
    }
    if (err && err.error && err.error[0] && err.error[0].error) {
      return err.error[0].error;
    }
    return defaultError;
  }

  getPasswordErrorMessage(err, defaultError?: string) {
    if (err && err.error) {
      for (const key in err.error) {
        if (Object.prototype.hasOwnProperty.call(err.error, key)) {
          const element = err.error[key];
          if (element[0]) {
            return element[0];
          }
        }
      }
    }
    if (err && err.error && err.error[0] && err.error[0].error) {
      return err.error[0].error;
    }
    return defaultError;
  }

  getErrorCode(err, defaultError?: string) {
    let code = null;
    if (err && err.error && err.error.errors && err.error.errors[0]) {
      code = err.error.errors[0].code;
    }
    return code;
  }

  getErrorMessageFromError(err, defaultError?: string) {
    let reason = defaultError;
    if (err && err.error && err.error.errors && err.error.errors[0]) {
      reason = err.error.errors[0].message
        ? err.error.errors[0].message
        : "An error has occurred";
    }
    return reason;
  }

  getCompanyById(id: string, contact: any) {
    let companies;
    companies = contact && contact["workplaces"] ? contact["workplaces"] : [];
    for (const company of companies) {
      if (company.companyId == id) {
        return company;
      }
    }
    return null;
  }

  getCompaniesInfo(profile: any) {
    if (!profile["workplaces"]) {
      profile["workplaces"] = [];
    }
    return profile["workplaces"];
  }

  redirectTo(uri: string) {
    this.navigateByUrl("/", { skipLocationChange: true }).then(() =>
      this.navigate([uri])
    );
  }

  navigateToConversation(conversationId) {
    this.navigateTo("conversations/" + conversationId + "/messages");
  }

  navigateToProfile() {
    this.navigateTo("profile");
  }

  navigateToNewGroup() {
    this.navigateTo("/conversations/new/group");
  }

  navigateToDirectorySearch() {
    this.navigateTo("/network");
  }

  navigateTo(route: string, queryParams?: {}) {
    if (queryParams) {
      this.router.navigate([route], { queryParams });
    } else {
      this.router.navigate([route]);
    }
  }

  navigate(routes: any[], extras?) {
    this.router.navigate(routes, extras);
  }

  navigateBack() {
    this._location.back();
  }

  navigateByUrl(url: string, extras?) {
    return this.router.navigateByUrl(url, extras);
  }

  getCompaniesFromProfile(profile) {
    let companies = [];
    if (profile) {
      companies = this.getCompaniesInfo(profile);
    }

    return companies;
  }

  /**
   * Calls `callback` if there is an active network connection, otherwise shows a no internet snackbar.
   */
  public ifOnline(callback: Function, disableSnackbar: boolean = false): void {
    if (this.isOnline()) {
      callback();
    } else if (!disableSnackbar) {
      this.noInternetSnackbar();
    }
  }

  isOnline() {
    return isInternetOnline();
  }

  noInternetSnackbar() {
    this.alertService.showSnackBar("Please connect to the internet", 3);
  }

  offlineGenericErrorMessageSnackbar() {
    this.alertService.showSnackBar(
      "Oops, something went wrong! Please check your internet connection and try again.",
      4
    );
  }

  save(key, value) {
    return localStorage.setItem(key, value);
  }

  userUnavailableDialog() {
    this.alertService.confirm(
      "The user is unavailable to contact on Celo. ",
      "This can be due to the following reasons:<br> <br>- You're no longer in the user’s Celo network. <br>- The user’s privacy settings don't allow you to contact them. <br> - The user no longer uses Celo. <br> - The user may have blocked you. <br>  <br> For further help, contact us: <br> <a href = 'mailto: support@celohealth.com'>support@celohealth.com</a> ",
      "Done",
      "Cancel"
    );
  }

  getSettingsApi(callback) {
    const path = environment.celoApiEndpoint + "/api/Settings";
    this.getObjectById(path).subscribe((settings) => {
      if (settings && callback) {
        callback(settings);
      }
    });
  }

  getSupportedCountries(important, callback) {
    if (!important && this.countries) {
      callback(this.countries);
    }

    const path = environment.celoApiEndpoint + "/api/settings/countries";
    this.getObjectById(path).subscribe((countries) => {
      this.countries = countries;
      if (countries) {
        callback(countries);
      }
    });
  }

  getTitles(callback) {
    const path = environment.celoApiEndpoint + "/api/Settings/Titles";
    this.getObjectById(path).subscribe((titles) => {
      if (titles && callback) {
        callback(titles);
      }
    });
  }

  canSendEmail(lastMessageTime) {
    const previous = new Date(lastMessageTime);
    const now = new Date();
    if (
      (now.getTime() - previous.getTime()) / 1000 <
      environment.emailThrottleInSeconds
    ) {
      this.alertService.showSnackBar(
        "Please wait " +
          (30 - Math.floor((now.getTime() - previous.getTime()) / 1000)) +
          " seconds to send another code",
        2
      );
      return false;
    }
    return true;
  }

  sendOnboardEmail(email, region, is_resending, callback) {
    if (!this.isOnline()) {
      this.offlineGenericErrorMessageSnackbar();
      callback(null);
      return;
    }
    const lastMessageTime = this.getLastSuccessfulEmailTime();
    if (lastMessageTime && !this.canSendEmail(lastMessageTime)) {
      if (callback) {
        callback(null);
      }
      return;
    }
    const body = {
      email,
    };
    const path = environment.celoApiEndpoint + "/api/Onboard/Email/Verify";
    this.postObjectById(path, {}, body).subscribe(
      (response) => {
        this.analyticsService.raiseEvent("email_submitted", {
          flow: "signing_up",
          success: "true",
          is_resending,
        });

        this.setLastSuccessfulEmailTime();
        if (response["verificationId"]) {
          this.alertService.showSnackBar("We’ve sent the code to " + email, 3);
          callback(response["verificationId"]);
          return;
        }
        callback();
      },
      (err) => {
        let message = this.STANDARD_ERROR_MESSAGE;
        message = this.getErrorMessage(err);
        this.alertService.alert("", message, true);

        this.analyticsService.raiseEvent("country_submitted", {
          flow: "signing_up",
          success: "false",
          is_resending,
        });

        callback();
      }
    );
  }

  getLastSuccessfulEmailTime() {
    return localStorage.getItem("email_time");
  }

  setLastSuccessfulEmailTime() {
    return localStorage.setItem("email_time", new Date().toISOString());
  }

  getLastSuccessfulMessageTime() {
    return localStorage.getItem("message_time");
  }

  setLastSuccessfulMessageTime() {
    return localStorage.setItem("message_time", new Date().toISOString());
  }

  createWorkspace(name: string) {
    const path = this.basePath + "/api/v2/workspaces";
    return this.postObjectById(path, {}, { name });
  }

  joinWorkspace(
    email,
    verificationId,
    companyId,
    departmentId,
    position,
    onboarding?
  ) {
    const onboardingEndpoint = "/api/Onboard/Workplaces";
    const endpoint = "/api/v2/account/workplaces";
    const path =
      environment.celoApiEndpoint +
      (onboarding ? onboardingEndpoint : endpoint);
    const body = {
      email,
      verificationId,
      companyId,
      departmentId,
      position,
    };
    return this.postObjectById(path, {}, body);
  }

  canContact(contact, userAccount, myCompaniesAndPartnerCompanies) {
    if (contact["connection"] && contact["connection"].state == "Accepted") {
      return true;
    }
    const hasCommonWorkspace = this.checkCommonWorkspace(
      contact["workplaces"],
      myCompaniesAndPartnerCompanies
    );
    if (hasCommonWorkspace) {
      return true;
    }
    if (contact["blockedByMe"] && contact["blockedByMe"].isBlocked) {
      return false;
    }
    if (contact["blockedMe"] && contact["blockedMe"].isBlocked) {
      return false;
    }
    return false;
  }

  removeInvalidChars(text: string) {
    return text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
  }

  copyWorkspaceLink(workspace_name, content) {
    const instance = this;
    const text =
      "Follow this link to join the " +
      workspace_name +
      " workspace on Celo \n" +
      content;
    this.copy(text, function () {
      instance.alertService.showSnackBar("Link copied", 3);
    });
  }

  copyConversationLink(conversation, type, content) {
    const instance = this;
    const text =
      "Follow this link to join the " +
      conversation.name +
      " " +
      type.toLowerCase() +
      " on Celo \n" +
      content;
    this.copy(text, function () {
      instance.alertService.showSnackBar("Link copied", 3);
    });
  }

  copy(val: string, callback?) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    if (callback) {
      callback();
    }
  }

  getUrlParameter(url, name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(url);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  action(
    invitationId: string,
    callback: (response: ActionInvitationData) => void
  ) {
    //to remove
    // this.loading = false;
    // this.ref.close({
    //   // errored:true
    //   resultType:this.data.invitationObj.type
    // });
    // return;

    const path =
      environment.celoApiEndpoint +
      "/api/v2/invitations/" +
      invitationId +
      "/action";
    this.postObjectById<ActionInvitationResponseModel>(path).subscribe(
      (res) => {
        const response = {
          errored: false,
          data: res,
        };
        callback(response);
      },
      (err) => {
        const response = {
          errored: true,
          data: err,
        };
        callback(response);
      }
    );
  }

  pullToBottom<T = object>(
    array: T[],
    fieldName: keyof T,
    fieldValue: string
  ): void {
    if (!array || !fieldName) {
      return;
    }
    for (let index = 0; index < array.length; index++) {
      const value = array[index][fieldName];

      if (typeof value !== "string") {
        throw Error(
          `Type of property '${fieldValue}' must be 'string' but was ${typeof value}`
        );
      }

      if (value.toLowerCase() === fieldValue.toLowerCase()) {
        const copy = array[index];
        array.splice(index, 1);
        array[array.length] = copy;
      }
    }
  }
}
