import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";
import { BehaviorSubject, Observable, defer, merge, pipe } from "rxjs";
import {
  bufferTime,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  ApiRequestOptions,
  CaseExportEntry,
  ConversationModel,
  ConversationModelV2,
  ConversationModelV2ApiPagedResult,
  ConversationParticipantModelV2,
  ConversationType,
  ConversationsV2RequestOptions,
  CreateConversationModel,
  CreateMessageMediaModel,
  CreateMessageModel,
  CreateMessagePatientFileModel,
  CreateParticipantModel,
  GetUnreadConversationRequestOptions,
  MessageModel,
  MessageUpdate,
  MessagesV2RequestOptions,
  ParticipantRole,
  PatientDataModel,
  UnreadConversationsModel,
  UnreadMessagesModel,
} from "types";
import {
  concatNotNull,
  distinct,
  isNotNullOrUndefined,
  isNullOrUndefined,
  removeRange,
  toMap,
} from "utils";
import { v4 as uuidv4 } from "uuid";
import { ConService } from "../old/conn.service";
import { SubscriptionContainer } from "./../../../../utils/subscription-container";
import { MessageService } from "./../old/message.service";
import { ApiService } from "./api.service";
import { SnackbarService } from "./snackbar.service";
import { UserService } from "./user.service";
import { getConversationName } from "utils/conversation-utils";

export interface ConversationServiceMetadata {
  /**
   * If this conversation was returned by making a search request to the backend, this is the query that resulted in
   * this conversation being returned.
   */
  query?: string | null;
}

export interface ConversationModelV2WithMetadata extends ConversationModelV2 {
  conversationServiceMetadata?: ConversationServiceMetadata;
}

export interface ConversationModelV2WithMetadataApiPagedResult
  extends ConversationModelV2ApiPagedResult {
  data?: ConversationModelV2WithMetadata[] | null;
}

export const CONVERSATIONS_SERVICE =
  new InjectionToken<ConversationsServiceProvider>("CONVERSATIONS_SERVICE");

export interface ConversationsServiceProvider {
  conversations$: Observable<ConversationModelV2WithMetadata[] | null>;
  unreadConversationIds$: Observable<string[]>;
  getConversation(
    conversationId: string,
    options?: Partial<{ latestOnly: boolean }>
  ): Observable<ConversationModelV2>;
  getConversations(
    options: ConversationsV2RequestOptions & ApiRequestOptions
  ): Observable<ConversationModelV2WithMetadataApiPagedResult>;
  getTeamConversations(
    options: ConversationsV2RequestOptions &
      ApiRequestOptions & { teamId: string }
  ): Observable<ConversationModelV2WithMetadataApiPagedResult>;
  getExternalConversations(
    options?: Omit<ConversationsV2RequestOptions, "isExternal"> &
      ApiRequestOptions
  ): Observable<ConversationModelV2WithMetadataApiPagedResult>;
  createChat(otherUserId: string): Observable<ConversationModelV2>;
  createTeamChat(
    teamId: string,
    userId?: string
  ): Observable<ConversationModelV2>;
  createGroup(
    name: string,
    participantUserIds?: string[]
  ): Observable<ConversationModelV2>;
  createCase(
    name: string,
    participantUserIds?: string[],
    patientData?: PatientDataModel
  ): Observable<ConversationModelV2>;
  createExternalChat(
    patientData: PatientDataModel
  ): Observable<ConversationModelV2>;
  updateConversationPatientData(
    conversationId: string,
    patientData: PatientDataModel
  ): Observable<ConversationModelV2>;
  leaveConversation(conversationId: string): Observable<ConversationModelV2>;
  pinConversation(conversationId: string): Observable<void>;
  unpinConversation(conversationId: string): Observable<void>;
  getMessage(
    conversationId: string,
    messageId: string
  ): Observable<MessageModel>;
  getMessages(options: MessagesV2RequestOptions): Observable<MessageModel[]>;
  deleteMessage(
    conversationId: string,
    messageId: string
  ): Observable<MessageModel>;
  exportToPdf(conversationId: string): Observable<{ operationId: string }>;
  downloadExportedPdf(conversationId: string): void;
  getUnreadConversationIds(
    options?: GetUnreadConversationRequestOptions
  ): Observable<UnreadConversationsModel>;
  getUnreadMessageIds(conversationId: string): Observable<UnreadMessagesModel>;
  removeUnreadConversationId(conversationId: string): void;
  updateConversationRole(
    userId: string,
    conversationId: string,
    role: ParticipantRole
  ): Observable<ConversationModelV2>;
  addParticipants(
    conversationId: string,
    participantUserIds: string[]
  ): Observable<ConversationModelV2>;
  removeParticipant(
    userId: string,
    conversationId: string
  ): Observable<ConversationModelV2>;
  getConversationProfileUri(conversationId: string, photoId: string): string;
  isConversationMutedForParticipant(
    participant: ConversationParticipantModelV2
  ): boolean;
  createAndNavigateToChatConversation(recipientUserId: string): void;
  isParticipantLastActiveConversationAdmin(
    conversationId: string,
    participantId: string
  ): boolean;
  getConversationParticipants(
    conversationId: string,
    role?: ParticipantRole
  ): ConversationParticipantModelV2[];
  isParticipantLastActiveConversationParticipant(
    conversationId: string,
    participantId: string
  ): boolean;
  navigateToConversation(conversationId: string, teamId?: string | null): void;
  navigateToExternalConversation(conversationId: string): void;
  tryParsePotentialTeamId(conversationId: string): string | null;
  isUserMemberOfTeamForTeamChat(
    userId: string,
    conversationId: string
  ): boolean;
  sendMessage(
    conversationId: string,
    message: CreateMessageModel
  ): Observable<MessageModel>;
  sendMedia(
    conversationId: string,
    media: CreateMessageMediaModel[]
  ): Observable<MessageModel>;
  sendPatientFiles(
    conversationId: string,
    patientFiles: CreateMessagePatientFileModel[]
  ): Observable<MessageModel>;
  getConversationName(conversation: ConversationModelV2): string;
}

@Injectable({
  providedIn: "root",
})
export class ConversationsService
  implements OnDestroy, ConversationsServiceProvider
{
  private conversationsSubject = new BehaviorSubject<
    ConversationModelV2WithMetadata[] | null
  >(null);

  public conversations$ = this.conversationsSubject.asObservable();

  private unreadConversationIdsSubject = new BehaviorSubject<string[]>([]);
  public unreadConversationIds$ =
    this.unreadConversationIdsSubject.asObservable();

  private subscriptions = new SubscriptionContainer();

  private loadingConversationIds: Set<string> = new Set();
  private lastOpIds: Set<string> = new Set();

  public constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
    private conService: ConService,
    private messageService: MessageService,
    private snackbarService: SnackbarService
  ) {
    const unreadConversationsSubscription = this.getUnreadConversationIds({
      conversationTypes: [
        ConversationType.Chat,
        ConversationType.Case,
        ConversationType.Group,
        ConversationType.TeamChat,
      ],
      isNonTeamParticipant: true,
    }).subscribe();

    const messageSubscription = this.conService.Message$.subscribe(
      this.handleMessageUpdates.bind(this)
    );

    const messageViewSubscription = this.messageService.onFirstMessageView
      .pipe(
        bufferTime(500),
        filter((s) => s.length > 0)
      )
      .subscribe(this.handleMessageViewed.bind(this));

    const externalConversationsSubscription =
      this.getExternalConversations().subscribe();

    const exportedPdfSubscription =
      this.conService.conversationExportEntrySubjectDetails$.subscribe(
        (caseExportEntry: CaseExportEntry) => {
          if (this.lastOpIds.has(caseExportEntry.operationId)) {
            this.snackbarService.show(
              "Case Exported! Your download will begin shortly...",
              10
            );
            this.downloadExportedPdf(caseExportEntry.conversationId);
          }
        }
      );

    this.subscriptions.add(
      messageSubscription,
      messageViewSubscription,
      unreadConversationsSubscription,
      externalConversationsSubscription,
      exportedPdfSubscription
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateConversations(
    conversations: ConversationModelV2[],
    ignoredProperty: keyof ConversationModelV2WithMetadata | null = null
  ) {
    const conversationMap = toMap(
      conversations,
      (c) => c.id,
      (c) => c
    );

    const updatedConversations =
      this.conversationsSubject.value?.map((c) => {
        if (!c.id) {
          return c;
        }
        if (!c[ignoredProperty]) {
          return conversationMap.get(c.id) ?? c;
        }
        return {
          ...(conversationMap.get(c.id) ?? c),
          [ignoredProperty]: c[ignoredProperty],
        };
      }) ?? [];

    const newConversations = distinct(
      [...updatedConversations, ...conversations],
      (c) => c.id
    );

    this.conversationsSubject.next(newConversations);
  }

  private handleMessageViewed(messages: MessageModel[]) {
    const conversationIds = distinct(
      messages.map((m) => m.conversationId)
    ).filter((m) => m) as string[];

    const updatedConversations = conversationIds
      .map((conversationId) => {
        const conversationMessages = messages.filter(
          (m) => m.conversationId === conversationId
        );

        const conversation = this.conversationsSubject.value?.find(
          (s) => s.id === conversationId
        );

        if (conversation) {
          const updatedConversation = this.updateConversationUnreadMessages(
            conversation,
            conversationMessages,
            true
          );
          return updatedConversation;
        } else {
          // Reload the conversation
          this.getConversation(conversationId).subscribe();
          return null;
        }
      })
      .filter(isNotNullOrUndefined);

    this.updateConversations(updatedConversations);
  }

  private handleMessageUpdates(messageUpdates: MessageUpdate[]) {
    messageUpdates.forEach(this.handleMessageUpdate.bind(this));
  }

  private handleMessageUpdate(messageUpdate: MessageUpdate) {
    const conversationId = messageUpdate.conversationId ?? null;
    const conversation = this.conversationsSubject.value?.find(
      (s) => s.id === conversationId
    );

    if (conversationId && conversation) {
      let updatedConversation = this.updateConversationLatestMessage(
        conversation,
        messageUpdate
      );

      // Deleted messages are excluded as deleting a message should not impact the read
      // status for a message
      if (!messageUpdate.deletedOnUtc) {
        updatedConversation = this.updateConversationUnreadMessages(
          updatedConversation,
          [messageUpdate]
        );
      }

      // Reload the conversation if the user who sent the message is not stored locally in the associated conversation
      if (
        !conversation.participants?.find(
          (p) => p.userId === messageUpdate.sentBy
        )
      ) {
        this.getConversation(conversationId).subscribe();
      }

      this.updateConversations([updatedConversation]);
    } else if (conversationId) {
      // Fetch the conversation as we haven't don't have a local copy
      this.getConversation(conversationId).subscribe();
    } else {
      throw new Error("Invalid message update");
    }
  }

  private updateConversationUnreadMessages(
    conversation: ConversationModelV2WithMetadata,
    messages: MessageModel[],
    isRead: boolean = false
  ) {
    const currentUserId = this.userService.getUserId(true);
    const copy = { ...conversation };

    const messageIds = messages
      .filter((m) => m.id != null && m.sentBy !== currentUserId)
      .map((m) => m.id) as number[];

    if (isRead) {
      if (copy.unreadMessageIds) {
        copy.unreadMessageIds = removeRange(copy.unreadMessageIds, messageIds);
      } else {
        copy.unreadMessageIds = [];
      }
    } else {
      if (copy.unreadMessageIds) {
        copy.unreadMessageIds = distinct([
          ...copy.unreadMessageIds,
          ...messageIds,
        ]);
      } else {
        copy.unreadMessageIds = messageIds;
      }
    }

    return copy;
  }

  private updateConversationLatestMessage(
    conversation: ConversationModelV2WithMetadata,
    messageUpdate: MessageUpdate
  ) {
    const copy = { ...conversation };

    if (copy.lastMessage) {
      const lastMessageSentOnUtc = copy.lastMessage.sentOnUtc
        ? Date.parse(copy.lastMessage.sentOnUtc)
        : null;
      const messageSentOnUtc = messageUpdate.sentOnUtc
        ? Date.parse(messageUpdate.sentOnUtc)
        : null;

      if (
        (lastMessageSentOnUtc === null && messageSentOnUtc === null) ||
        messageSentOnUtc === null
      ) {
        return copy;
      }

      if (
        lastMessageSentOnUtc === null ||
        messageSentOnUtc >= lastMessageSentOnUtc
      ) {
        copy.lastMessage = messageUpdate;
      }
    } else {
      copy.lastMessage = messageUpdate;
    }

    return copy;
  }

  public getConversation(
    conversationId: string,
    options?: Partial<{ latestOnly: boolean }>
  ): Observable<ConversationModelV2> {
    const path = `/api/v2/Conversations/${conversationId}`;

    const latestConversation$ = defer(async () => {
      this.loadingConversationIds.add(conversationId);
    }).pipe(
      switchMap(() =>
        this.apiService.get<ConversationModelV2>({ path }).pipe(
          finalize(() => this.loadingConversationIds.delete(conversationId)),
          this.updateConversationTap("conversationServiceMetadata")
        )
      )
    );

    if (options?.latestOnly) return latestConversation$;

    const conversation$ = this.conversationsSubject.pipe(
      map((s) => s?.find((c) => c.id === conversationId)),
      filter(isNotNullOrUndefined)
    );

    if (this.loadingConversationIds.has(conversationId)) return conversation$;

    return merge(conversation$, latestConversation$);
  }

  private updateUnreadConversationIdsTap() {
    return pipe(
      tap<UnreadConversationsModel>({
        next: ({ unreadConversationIds }) => {
          if (!unreadConversationIds) return;
          this.unreadConversationIdsSubject.next(unreadConversationIds);
        },
      })
    );
  }

  private updateConversationsTap() {
    const pages: ConversationModelV2ApiPagedResult[] = [];
    return pipe(
      tap<ConversationModelV2ApiPagedResult>({
        next: (page) => pages.push(page),
        complete: () =>
          this.updateConversations(pages.flatMap((page) => page.data ?? [])),
      })
    );
  }

  private updateConversationTap(
    ignoredProperty: keyof ConversationModelV2WithMetadata | null = null
  ) {
    return pipe(
      tap<ConversationModelV2>({
        next: (conversation) =>
          this.updateConversations([conversation], ignoredProperty),
      })
    );
  }

  private mapConversations(
    conversations: ConversationModelV2[],
    options: ConversationsV2RequestOptions
  ): ConversationModelV2WithMetadata[] {
    const conversationServiceMetadata: ConversationServiceMetadata = {
      query: options.search ?? null,
    };
    return conversations.map((c) => ({ ...c, conversationServiceMetadata }));
  }

  private mapConversationsPage(options: ConversationsV2RequestOptions) {
    return pipe(
      map<
        ConversationModelV2ApiPagedResult,
        ConversationModelV2WithMetadataApiPagedResult
      >((page) => {
        const data = this.mapConversations(page.data ?? [], options);
        return { ...page, data };
      })
    );
  }

  public getConversations({
    fetchAll,
    ...options
  }: ConversationsV2RequestOptions &
    ApiRequestOptions): Observable<ConversationModelV2WithMetadataApiPagedResult> {
    const path = `/api/v2/Conversations`;
    if (fetchAll) {
      return this.apiService
        .getAllByOffset<ConversationModelV2ApiPagedResult>({
          path,
          queryParams: { ...options },
        })
        .pipe(
          this.mapConversationsPage(options),
          this.updateConversationsTap()
        );
    }
    return this.apiService
      .get<ConversationModelV2ApiPagedResult>({
        path,
        queryParams: { ...options },
      })
      .pipe(this.mapConversationsPage(options), this.updateConversationsTap());
  }

  public getTeamConversations({
    fetchAll,
    teamId,
    ...options
  }: ConversationsV2RequestOptions &
    ApiRequestOptions & {
      teamId: string;
    }): Observable<ConversationModelV2WithMetadataApiPagedResult> {
    const path = `/api/v2/Teams/${teamId}/conversations`;
    if (fetchAll) {
      return this.apiService
        .getAllByOffset<ConversationModelV2ApiPagedResult>({
          path,
          queryParams: { ...options },
        })
        .pipe(
          this.mapConversationsPage(options),
          this.updateConversationsTap()
        );
    }
    return this.apiService
      .get<ConversationModelV2ApiPagedResult>({
        path,
        queryParams: { ...options },
      })
      .pipe(this.mapConversationsPage(options), this.updateConversationsTap());
  }

  public getExternalConversations({
    fetchAll,
    ...options
  }: Omit<ConversationsV2RequestOptions, "isExternal"> &
    ApiRequestOptions = {}): Observable<ConversationModelV2WithMetadataApiPagedResult> {
    const path = `/api/v2/Conversations`;

    const mergedOptions: ConversationsV2RequestOptions & ApiRequestOptions = {
      ...options,
      isExternal: true,
    };

    if (fetchAll) {
      return this.apiService
        .getAllByOffset<ConversationModelV2ApiPagedResult>({
          path,
          queryParams: { ...mergedOptions },
        })
        .pipe(
          this.mapConversationsPage(options),
          this.updateConversationsTap()
        );
    }
    return this.apiService
      .get<ConversationModelV2ApiPagedResult>({
        path,
        queryParams: { ...mergedOptions },
      })
      .pipe(
        this.mapConversationsPage(mergedOptions),
        this.updateConversationsTap()
      );
  }

  private createChatId(participantId: string) {
    return [participantId, this.userService.getUserId(true)].sort().join("_");
  }

  public createTeamChatId(teamId: string, userId?: string) {
    return [userId ?? this.userService.getUserId(true), teamId].join("_");
  }

  /**
   * Returns a string if a potential team id is found in `conversationId`, otherwise returns null.
   *
   * Note: if a string is returned this does *not* guarantee the id is a team id, only that it may be.
   */
  public tryParsePotentialTeamId(conversationId: string): string | null {
    const parts = conversationId.split("_");
    if (parts.length !== 2) return null;
    return parts[1];
  }

  /**
   * Returns true if the user with `userId` is a member of the team associated with `conversationId`.
   *
   * Note: this method assumes the given conversationId is a TeamChat
   */
  public isUserMemberOfTeamForTeamChat(
    userId: string,
    conversationId: string
  ): boolean {
    const parts = conversationId.split("_");
    if (parts.length !== 2) throw Error("Invalid TeamChat conversationId");
    return parts[0] !== userId;
  }

  private mapUserIdsToParticipants(
    userIds: string[]
  ): CreateParticipantModel[] {
    return userIds.map((userId) => ({ userId }));
  }

  private mapTeamIdsToParticipants(
    teamIds: string[]
  ): CreateParticipantModel[] {
    return teamIds.map((teamId) => ({ teamId }));
  }

  private getCreateChatModel(
    participantId: string,
    type: ConversationType.Chat | ConversationType.SelfChat
  ): CreateConversationModel {
    return {
      id: this.createChatId(participantId),
      participants: this.mapUserIdsToParticipants([participantId]),
      type,
    };
  }

  private getCreateTeamChatModel(
    teamId: string,
    userId?: string
  ): CreateConversationModel {
    return {
      id: this.createTeamChatId(teamId, userId),
      participants: [
        ...this.mapTeamIdsToParticipants([teamId]),
        ...this.mapUserIdsToParticipants([
          userId ?? this.userService.getUserId(true),
        ]),
      ],
      type: ConversationType.TeamChat,
    };
  }

  private createConversation(
    createConversationModel: CreateConversationModel
  ): Observable<ConversationModelV2> {
    const path = "/api/v2/Conversations";

    const body: CreateConversationModel = { ...createConversationModel };
    if (!body.createOnUtc) {
      body.createOnUtc = new Date().toISOString();
    }

    return this.apiService
      .post<ConversationModelV2>({ path, body })
      .pipe(this.updateConversationTap());
  }

  public createChat(otherUserId: string): Observable<ConversationModelV2> {
    const currentUserId = this.userService.getUserId(true);
    let type =
      otherUserId === currentUserId
        ? ConversationType.SelfChat
        : ConversationType.Chat;
    const createConversationModel = this.getCreateChatModel(otherUserId, type);
    return this.createConversation(createConversationModel);
  }

  /**
   * Create a team chat. If no `userId` is specified then a chat between the current user and the specified `teamId`
   * will be created.
   */
  public createTeamChat(
    teamId: string,
    userId?: string
  ): Observable<ConversationModelV2> {
    const createConversationModel = this.getCreateTeamChatModel(teamId, userId);
    return this.createConversation(createConversationModel);
  }

  public createGroup(
    name: string,
    participantUserIds: string[] = []
  ): Observable<ConversationModelV2> {
    return this.createConversation({
      id: uuidv4(),
      name,
      participants: this.mapUserIdsToParticipants(participantUserIds),
      type: ConversationType.Group,
    });
  }

  public createCase(
    name: string,
    participantUserIds: string[] = [],
    patientData?: PatientDataModel
  ): Observable<ConversationModelV2> {
    return this.createConversation({
      id: uuidv4(),
      name,
      patientData,
      participants: this.mapUserIdsToParticipants(participantUserIds),
      type: ConversationType.Case,
    });
  }

  public createExternalChat(
    patientData: PatientDataModel
  ): Observable<ConversationModelV2> {
    return this.createConversation({
      id: uuidv4(),
      patientData,
      type: ConversationType.External,
    });
  }

  public updateConversationPatientData(
    conversationId: string,
    patientData: PatientDataModel
  ) {
    const path = `/api/v2/Conversations/${conversationId}`;
    return this.apiService
      .patch<ConversationModel>({ path, body: { patientData } })
      .pipe(this.updateConversationTap());
  }

  public isAlreadyAtConversationRoute(conversationId: string) {
    const path = this.router.url;
    return path.includes(`/conversations/${conversationId}`);
  }

  public navigateToConversation(
    conversationId: string,
    teamId: string | null = null
  ) {
    if (teamId) {
      this.router.navigate([
        "roles",
        teamId,
        "conversations",
        conversationId,
        "messages",
      ]);
    } else {
      this.router.navigate(["conversations", conversationId, "messages"]);
    }
  }

  public navigateToExternalConversation(conversationId: string) {
    this.router.navigate(["external", conversationId, "messages"]);
  }

  public uploadProfilePhoto(
    formData: FormData,
    conversationId: string
  ): Observable<string> {
    return this.apiService.request<string>("put", {
      body: formData,
      path: `/api/v2/conversations/${conversationId}/profilePhoto`,
    });
  }

  public removeProfilePhoto(conversationId: string): Observable<void> {
    return this.apiService.request("delete", {
      path: `/api/v2/conversations/${conversationId}/profilePhoto`,
    });
  }

  /**
   * Returns a shallow copy of `conversations` containing only TeamChats where the current user is member of
   * that conversation's team.
   */
  public filterCurrentUserTeamChatsById(
    conversations: ConversationModelV2WithMetadata[],
    teamId: string
  ): ConversationModelV2WithMetadata[] {
    const currentUserId = this.userService.getUserId(true);
    const filtered = conversations.filter(
      (c) =>
        c.type === ConversationType.TeamChat &&
        c.participants?.find((p) => p.userId === currentUserId)?.teamId ===
          teamId
    );
    return filtered;
  }

  /**
   * Returns a shallow copy of `conversations` containing only External chats where the current user is an active
   * participant.
   */
  public filterExternalConversations(
    conversations: ConversationModelV2WithMetadata[]
  ) {
    const currentUserId = this.userService.getUserId(true);
    const filtered = conversations.filter(
      (c) =>
        c.type === ConversationType.External &&
        c.participants?.some(
          (p) => p.userId === currentUserId && isNullOrUndefined(p.leftOnUtc)
        )
    );
    return filtered;
  }

  public sendMedia(
    conversationId: string,
    media: CreateMessageMediaModel[]
  ): Observable<MessageModel> {
    const path = `/api/v2/Conversations/${conversationId}/media/send`;
    return this.apiService.post({ path, body: media });
  }

  public sendPatientFiles(
    conversationId: string,
    patientFiles: CreateMessagePatientFileModel[]
  ): Observable<MessageModel> {
    const path = `/api/Conversations/${conversationId}/SendPatientFile`;
    return this.apiService.post({ path, body: patientFiles });
  }

  public sendMessage(conversationId: string, message: CreateMessageModel) {
    const path = `/api/v2/Conversations/${conversationId}/message`;
    return this.apiService.post<MessageModel>({ path, body: message });
  }

  public getUnreadConversationIds(
    options?: GetUnreadConversationRequestOptions
  ): Observable<UnreadConversationsModel> {
    const path = `/api/v2/Conversations/unread`;
    return this.apiService
      .get({ path, queryParams: { ...options } })
      .pipe(this.updateUnreadConversationIdsTap());
  }

  public getUnreadMessageIds(
    conversationId: string
  ): Observable<UnreadMessagesModel> {
    const path = `/api/v2/Conversations/${conversationId}/unread`;
    return this.apiService.get({ path });
  }

  public removeUnreadConversationId(conversationId: string) {
    const newUnreadConversationIds =
      this.unreadConversationIdsSubject.value.filter(
        (id) => id !== conversationId
      );
    this.unreadConversationIdsSubject.next(newUnreadConversationIds);
  }

  public updateConversationRole(
    userId: string,
    conversationId: string,
    role: ParticipantRole
  ) {
    const path = `/api/Conversations/${conversationId}/Participants/Roles`;

    const roleUpdateArray = [
      {
        userId,
        role,
      },
    ];

    return this.apiService.post<ConversationModelV2>({
      path,
      body: roleUpdateArray,
    });
  }

  public addParticipants(conversationId: string, participantUserIds: string[]) {
    const path = `/api/Conversations/${conversationId}/Participants`;
    const payload = participantUserIds.map((userId) => ({ userId }));

    return this.apiService.post<ConversationModelV2>({ path, body: payload });
  }

  public removeParticipant(userId: string, conversationId: string) {
    const path = `/api/Conversations/${conversationId}/Participants`;
    const userIds = [{ userId }];

    return this.apiService.delete<ConversationModelV2>({ path, body: userIds });
  }

  public getConversationProfileUri(
    conversationId: string,
    photoId: string
  ): string {
    return `/api/v2/Conversations/${conversationId}/media/${photoId}`;
  }

  public leaveConversation(conversationId: string) {
    const path = `/api/v2/Conversations/${conversationId}/Leave`;
    return this.apiService
      .post<ConversationModelV2>({ path })
      .pipe(this.updateConversationTap());
  }

  public createAndNavigateToChatConversation(recipientUserId: string) {
    this.createChat(recipientUserId).subscribe({
      next: (data) => {
        this.router.navigateByUrl(`/conversations/${data?.id}/messages`);
      },
    });
  }

  public isParticipantLastActiveConversationAdmin(
    conversationId: string,
    participantId: string
  ) {
    const conversationAdmins = this.getConversationParticipants(
      conversationId,
      ParticipantRole.Administrator
    );

    const activeConversationAdmins = conversationAdmins.filter(
      (admin) => !admin.leftOnUtc
    );

    return (
      activeConversationAdmins.length === 1 &&
      activeConversationAdmins[0].userId === participantId
    );
  }

  public getConversationParticipants(
    conversationId: string,
    role?: ParticipantRole
  ) {
    const participants = this.conversationsSubject.value.find(
      (conversation) => conversation.id === conversationId
    )?.participants;

    if (!role) {
      return participants;
    }

    return participants.filter((participant) => participant.role === role);
  }

  public isParticipantLastActiveConversationParticipant(
    conversationId: string,
    participantId: string
  ) {
    const conversationParticipants =
      this.getConversationParticipants(conversationId);

    const activeConversationParticipants = conversationParticipants.filter(
      (participant) => !participant.leftOnUtc
    );

    return (
      activeConversationParticipants.length === 1 &&
      activeConversationParticipants[0].userId === participantId
    );
  }

  public pinConversation(conversationId: string) {
    const path = `/api/v2/Conversations/${conversationId}/pin`;
    return this.apiService.post<void>({ path });
  }

  public unpinConversation(conversationId: string) {
    const path = `/api/v2/Conversations/${conversationId}/unpin`;
    return this.apiService.post<void>({ path });
  }

  public getMessage(conversationId: string, messageId: string) {
    const path = `/api/v2/Conversations/${conversationId}/messages/${messageId}`;
    return this.apiService.get<MessageModel>({
      path,
    });
  }

  public getMessages({ conversationId, ...options }: MessagesV2RequestOptions) {
    const path = `/api/v2/Conversations/${conversationId}/messages`;
    return this.apiService.get<MessageModel[]>({
      path,
      queryParams: { ...options },
    });
  }

  public deleteMessage(conversationId: string, messageId: string) {
    const path = `/api/v2/Conversations/${conversationId}/messages/${messageId}`;
    return this.apiService.delete<MessageModel>({
      path,
    });
  }

  public exportToPdf(
    conversationId: string
  ): Observable<{ operationId: string }> {
    const path = `/api/v2/Conversations/${conversationId}/export`;
    let res = this.apiService.post<{ operationId: string }>({ path }).pipe(
      tap({
        next: (r) => {
          this.lastOpIds.add(r.operationId);
        },
      })
    );

    return res;
  }

  public downloadExportedPdf(conversationId: string) {
    const path = `/api/v2/Conversations/${conversationId}/export`;
    this.apiService
      .request("get", { path }, { responseType: "blob" })
      .subscribe((b) => {
        saveAs(b, conversationId);
      });
  }

  public isConversationMutedForParticipant(
    participant: ConversationParticipantModelV2
  ): boolean {
    if (!participant.mutedToUtc) return false;
    return new Date(participant.mutedToUtc) >= new Date();
  }

  public getConversationName(conversation: ConversationModelV2): string {
    return getConversationName(conversation, this.userService.getUserId());
  }
}
