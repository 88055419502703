import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { SharedService } from "../../modules/core";

@Component({
  selector: "app-onboard",
  templateUrl: "./onboard.component.html",
  styleUrls: ["./onboard.component.scss"],
})
export class OnboardComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private oauthService: OAuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.sharedService.setTitle("Join | Celo");
    if (
      this.oauthService.hasValidAccessToken() &&
      this.router.url.indexOf("/onboard/workspace-add") == -1
    ) {
      this.sharedService.navigateTo("/conversations");
    }
  }
}
