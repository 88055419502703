import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MediaService } from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { ExportConfirmationDialogComponent } from "@modules/shared/export-confirmation-dialog/export-confirmation-dialog.component";
import { IntegrationItem } from "@modules/shared/integrations-picker/integrations-picker.component";
import { ConversationModelV2, MediaType } from "@types";
import { createDialogConfig } from "@utils";
import { concat } from "rxjs";
import { LibraryService } from "../library.service";

export interface LibrarySelectImgsComponentData {
  conversation?: ConversationModelV2 | null;
  onlyShowPatientFiles?: boolean;
  disableExportedFiles?: boolean;
  title?: string | null;
  isExportFlow?: boolean;
}

export interface LibrarySelectImgsComponentResult {
  photos?: SelectedPhoto[];
  integration?: IntegrationItem | null;
}

export interface SelectedPhoto {
  content: undefined;
  type: MediaType;
  mediaType: MediaType;
  photoId: string;
  marker: string;
}

enum ExportFlowState {
  SelectMedia,
  SelectClinicalRecord,
  Exporting,
}

@Component({
  selector: "app-library-select-imgs",
  templateUrl: "./library-select-imgs.component.html",
  styleUrls: ["./library-select-imgs.component.scss"],
})
export class LibrarySelectImgsComponent implements OnInit {
  photoGroups = [];
  showSpinner = true;
  selected: any[];
  patientId: string;
  hasPatientId: boolean;
  loading = true;

  public onlyShowPatientFiles: boolean = false;
  public disableExportedFiles: boolean = false;
  public title: string = null;
  public isExportFlow: boolean = false;
  public states = ExportFlowState;
  public state: ExportFlowState = ExportFlowState.SelectMedia;
  public hideVideos: boolean = false;

  public selectedIntegration: IntegrationItem | null = null;
  public exportProgress: number = 0;
  public isExported: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: LibrarySelectImgsComponentData,
    private matDialogRef: MatDialogRef<
      LibrarySelectImgsComponent,
      LibrarySelectImgsComponentResult
    >,
    private libraryService: LibraryService,
    private mediaService: MediaService,
    private matDialog: MatDialog
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: LibrarySelectImgsComponentData
  ) {
    const config = createDialogConfig<LibrarySelectImgsComponentData>(data, {
      disableMatPanelReset: true,
    });
    return matDialog.open<
      LibrarySelectImgsComponent,
      LibrarySelectImgsComponentData,
      LibrarySelectImgsComponentResult
    >(LibrarySelectImgsComponent, config);
  }

  ngOnInit() {
    if (this.data.conversation?.patientData) {
      this.patientId = this.data.conversation.patientData.uid;
      this.hasPatientId = true;
    }

    this.onlyShowPatientFiles =
      this.data.onlyShowPatientFiles ?? this.onlyShowPatientFiles;
    this.disableExportedFiles =
      this.data.disableExportedFiles ?? this.disableExportedFiles;
    this.title = this.data.title;
    this.isExportFlow = this.data.isExportFlow ?? false;
    this.hideVideos = this.isExportFlow;
  }

  submit() {
    const photos = this.getPhotos();

    if (this.isExportFlow) {
      if (this.state === ExportFlowState.SelectMedia) {
        this.state = ExportFlowState.SelectClinicalRecord;
        return;
      } else if (this.state === ExportFlowState.SelectClinicalRecord) {
        const { serviceCode, services } = this
          .selectedIntegration as IntegrationItem;
        const service = services?.find((s) => s.code === serviceCode);
        if (!service) return;
        const isConfirmationRequired =
          service.confirmation !== null && service.confirmation !== undefined;
        if (!isConfirmationRequired) {
          this.state = ExportFlowState.Exporting;
          this.export(photos);
          return;
        }

        const confirmation = service.confirmation;
        ExportConfirmationDialogComponent.openDialog(this.matDialog, {
          ...confirmation,
        })
          .afterClosed()
          .subscribe({
            next: (result) => {
              if (!result?.isConfirmed) return;
              this.state = ExportFlowState.Exporting;
              this.export(photos);
            },
          });
        return;
      }
    }

    if (photos.length) {
      this.matDialogRef.close({
        photos,
        integration: this.selectedIntegration,
      });
    } else {
      AlertComponent.openDialog(this.matDialog, {
        acceptOnly: true,
        acceptButtonText: "Ok",
        title: "Select Photo",
        message: "Please select at least one photo",
      });
    }
  }

  private getPhotos() {
    const photos: SelectedPhoto[] = [];
    for (const photo of this.selected) {
      photos.push({
        content: undefined,
        type: photo.mediaType,
        mediaType: photo.mediaType,
        photoId: photo.id,
        marker: crypto.randomUUID(),
      });
    }
    return photos;
  }

  private export(photos: SelectedPhoto[]) {
    if (!this.selectedIntegration)
      throw new Error("Selected integration is null");

    const observables = photos.map((photo) => {
      return this.mediaService.export({
        mediaId: photo.photoId,
        request: {
          companyId: this.selectedIntegration.companyId,
          integrationId: this.selectedIntegration.integrationId,
          serviceCode: this.selectedIntegration.serviceCode,
        },
      });
    });

    this.exportProgress = 0;
    let processedCount = 0;

    concat(...observables).subscribe({
      next: (media) => {
        processedCount += 1;
        this.exportProgress = (processedCount / photos.length) * 100;
      },
      error: () => {
        this.state = ExportFlowState.SelectClinicalRecord;
        AlertComponent.openErrorDialog(this.matDialog);
      },
      complete: () => {
        this.isExported = true;
      },
    });
  }

  cancel() {
    if (
      this.isExportFlow &&
      this.state === ExportFlowState.SelectClinicalRecord
    ) {
      this.state = ExportFlowState.SelectMedia;
      return;
    }
    this.matDialogRef.close();
  }

  selsectionChange(selectedPhotos: any[]) {
    this.selected = selectedPhotos;
  }

  resetSelection() {
    this.selected = [];
    this.libraryService.emitCanSelectReset(true);
  }

  listLength(length) {
    this.loading = false;
  }

  public handleIntegrationSelection(item: IntegrationItem) {
    this.selectedIntegration = item;
  }
}
