import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { environment } from "../../../environments/environment";
import {
  AlertService,
  ConversationService,
  SharedService,
} from "../../modules/core";

@Component({
  selector: "app-admin-settings",
  templateUrl: "./admin-settings.component.html",
  styleUrls: ["./admin-settings.component.scss"],
})
export class AdminSettingsComponent implements OnChanges {
  @Input() participants: any[] = [];
  @Input() title: string;
  @Input() selectedParticipantIds: string[] = [];
  @Input() removedParticipantIds: string[] = [];
  @Input() conversation: any;
  @Output() closeClick = new EventEmitter<any>();

  hasChanged = false;
  toRemove = [];
  saving: boolean;
  previewMode = false;
  previewId = "";

  constructor(
    private sharedService: SharedService,
    private conversationService: ConversationService,
    private alertService: AlertService
  ) {}

  ngOnChanges() {
    this.setAdmins();
  }

  openPreview(id) {
    this.previewId = id;
    this.previewMode = true;
  }

  setAdmins() {
    this.selectedParticipantIds = [];
    this.participants.forEach((participant) => {
      if (participant["role"] == "Administrator") {
        this.selectedParticipantIds.push(participant.userId);
      }
    });
  }

  onUpdate(changePacket) {
    this.toRemove = changePacket["toRemove"] ? changePacket["toRemove"] : [];
    this.selectedParticipantIds = changePacket["selected"]
      ? changePacket["selected"]
      : [];
    this.hasChanged = true;
  }

  cancel() {
    this.closeWindow();
  }

  closeWindow() {
    this.closeClick.emit(null);
  }

  saveParticipants() {
    this.closeWindow();
    const list = [];
    this.selectedParticipantIds.forEach((userId) => {
      list.push({
        userId,
        role: "Administrator",
      });
    });
    this.toRemove.forEach((userId) => {
      list.push({
        userId,
        role: "Contributor",
      });
    });
    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/" +
      this.conversation.id +
      "/Participants/Roles";
    this.sharedService.postObjectById(path, {}, list).subscribe(
      (conversation) => {
        // #FIXME should be calling replaceConversationById?
        this.conversationService.removeConversationById(conversation);
        this.saving = false;
        this.closeClick.emit(conversation);
        // this.alertService.showSnackBar('Conversation updated successfully',3);
      },
      (err) => {
        this.saving = false;
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessage(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      }
    );
  }
}
