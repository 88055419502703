import { Component, ElementRef, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConversationService, ConversationsService, SnackbarService } from '@modules/core';
import { AlertComponent } from '@modules/core/components';

const acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];

@Component({
  selector: 'app-conversation-picture',
  templateUrl: './conversation-picture.component.html',
  styleUrls: ['./conversation-picture.component.scss']
})
export class ConversationPictureComponent implements OnChanges {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Input() pictureId: string | null;
  @Input() conversationId: string;
  @Output() pictureIdChange = new EventEmitter<string>();

  public pictureUri: string = "";

  constructor(
    private conversationsService: ConversationsService,
    private conversationService: ConversationService,
    private snackbarService: SnackbarService,
    private matDialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.pictureUri = this.conversationsService.getConversationProfileUri(this.conversationId, this.pictureId);
  }


  createEditDialog(): void {
    this.fileInput.nativeElement.click();
  }

  removePhoto(): void {
    this.conversationsService.removeProfilePhoto(this.conversationId).subscribe(_ => {
      this.snackbarService.show("Conversation Profile Photo Removed!", 3);

      this.pictureIdChange.emit(null);
      this.conversationService.updateConversationPhoto(this.conversationId, null);
    });
  }


  async handleFileUpload(): Promise<void> {
    let files = this.fileInput.nativeElement.files;
    let file = files.item(0);

    if (!!file) {
      if(!acceptedFileTypes.includes(file.type)) {
        const ref = AlertComponent.openDialog(this.matDialog, {
          title: "Image Not Supported",
          message: "At this time we do not support direct upload of some of the files in your selection.",
          acceptButtonText: "Close",
          closeButtonText: "Cancel",
          acceptOnly: true
        });

        return;
      }

      let formData = new FormData();
      formData.append("fileData", new Blob([file]));

      this.conversationsService.uploadProfilePhoto(formData, this.conversationId)
        .subscribe((s) => {
          this.snackbarService.show("Conversation Profile Photo Uploaded!", 3);

          this.conversationsService.getConversation(this.conversationId).subscribe(c => {
            this.pictureIdChange.emit(c.photoId);
            this.conversationService.updateConversationPhoto(this.conversationId, c.photoId);
          });
        });

      this.snackbarService.show("Uploading Profile Picture...", 10);
      this.fileInput.nativeElement.value = null;
    }

  }
}
