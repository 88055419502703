import { Subscription } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import {
  AuthService,
  SharedService,
  UserAccountService,
  UserService,
} from "@modules/core";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-logged-in-oidc",
  templateUrl: "./logged-in-oidc.component.html",
  styleUrls: ["./logged-in-oidc.component.scss"],
})
export class LoggedInOidcComponent implements OnInit, OnDestroy {
  subscription: Subscription | null = null;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private analytics: AngularFireAnalytics
  ) {}

  ngOnInit() {
    this.subscription = this.authService.userId$.subscribe({
      next: (userId) => {
        if (userId === null) return;
        this.userAccountService.userFirstInsideTheApp(userId);
        this.analytics.logEvent("login");
        this.analytics.logEvent("login_custom");
        this.analytics.logEvent("sign_in");
        this.analytics.logEvent("login");
      },
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
