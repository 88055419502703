<button class="picture-overlay" [matMenuTriggerFor]="menu">
    <div class="image-wrapper">
        <img *ngIf="!pictureId" class="image" src="../../../../../assets/icons/icongroup.svg"
            alt="dp_img" />
        <img *ngIf="!!pictureId" class="image" [src]="pictureUri | clink | secure" />
    </div>
    <div class="banner-text">
        Edit photo
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="createEditDialog()">Choose Photo</button>
            <button mat-menu-item (click)="removePhoto()">Remove Photo</button>
          </mat-menu>
    </div>
    <input #fileInput type="file" accept=".png,.jpg,.jpeg,.jfif" hidden (change)="handleFileUpload()"/>
</button>