<div class="relative">
  <div
    *ngFor="
      let message of messages;
      let first = first;
      let last = last;
      let i = index;
      trackBy: trackByFn
    "
    class="message-row"
  >
    <div></div>
    <div
      *ngIf="message['firstOfTheDay'] && message.sentOnUtc"
      class="date-day staytop"
      [style.top.px]="isOnDND ? 50 : 10"
    >
      <div class="date-line">
        <div class="date-bubble capitalize">
          {{ message.sentOnUtc | dateFormat: "relativeDay" }}
        </div>
      </div>
    </div>
    <div>
      <app-messages-row
        [userAccount]="userAccount"
        [message]="message"
        [pMap]="pMap"
        [isFirstOfGroup]="
          !messages[i - 1] ||
          (i < messages.length &&
            messages[i].sentBy !== messages[i - 1].sentBy) ||
          (message['firstOfTheDay'] && message.sentOnUtc)
        "
        [searchQuery]="searchQuery"
        [conversationId]="conversationId"
        [conversationType]="conversationType"
        [patientId]="patientId"
        (selectMessage)="selectMessage.emit($event)"
        (quote_clicked)="quote_clicked.emit($event)"
        (forward)="forward.emit($event)"
        (copyMessage)="copyMessage.emit($event)"
        (delete)="delete.emit($event)"
        [fromSystem]="message.sentBy === 'SYSTEM'"
        [fromMe]="message.sentBy === userAccount.userId"
        [fromSender]="
          message.sentBy !== userAccount.userId && message.sentBy !== 'SYSTEM'
        "
        [isBlocked]="isBlocked"
        [isConversationAdmin]="isConversationAdmin"
        [isActiveParticipant]="isActiveParticipant"
      ></app-messages-row>
    </div>
  </div>
</div>
