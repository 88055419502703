import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@env";
import {
  ConService,
  ConversationService,
  ConversationsService,
  SharedService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import {
  ConversationModel,
  ConversationModelV2,
  ConversationParticipantModel,
  ConversationParticipantModelV2,
  ConversationProfilePhotoUpdate,
  FullUserProfileModel,
} from "@types";
import { Subscription } from "rxjs";

interface MutePayload {
  conversationId: string;
  muteInterval: number;
}

@Component({
  selector: "app-conversation-card",
  templateUrl: "./conversation-card.component.html",
  styleUrls: ["./conversation-card.component.scss"],
  animations: [
    trigger("slideInOut", [
      transition(":leave", [
        animate("400ms ease-in", style({ transform: "translateX(-100%)" })),
      ]),
    ]),
  ],
})
export class ConversationCardComponent implements OnDestroy, OnChanges, OnInit {
  @Input() convo: ConversationModel;
  @Input() userAccount: FullUserProfileModel = {};
  @Input() selected: boolean;
  @Input() refreshCount: number;
  @Input() hideBorder: boolean;
  @Input() inConversationList: boolean;
  @Input() activeOnly = false;
  @Input() hideUnreadCount: boolean;
  @Input() hideArchiveOption: boolean;
  @Input() hideLastMessage: boolean;
  @Input() showParticipants: boolean;
  @Input() public isRolesSidenav: boolean = false;
  @Input() public disableConversationActions: boolean = false;
  @Output() muteStatus = new EventEmitter<any>();

  participantToShow:
    | ConversationParticipantModel
    | ConversationParticipantModelV2;

  payload = [
    {
      display: "For 15 minutes",
      value: 15,
    },
    {
      display: "For 1 hour",
      value: 60,
    },
    {
      display: "For 8 hours",
      value: 480,
    },
    {
      display: "For 24 hours",
      value: 1440,
    },
    {
      display: "Until I turn it back on",
      value: -1,
    },
  ];

  isHidden = false;
  isMuted = false;
  isPinned: boolean;

  leftConversation: boolean;
  type: string;
  subMuteDialog: Subscription;
  name: string;
  pinSubscriber: Subscription;
  profilePhotoSubscriber: Subscription;
  pictureUri: string;

  constructor(
    private legacyConversationService: ConversationService,
    private sharedService: SharedService,
    private conService: ConService,
    private snackBar: MatSnackBar,
    private conversationsService: ConversationsService,
    private matDialog: MatDialog
  ) {}

  openMuteDialog(): void {
    const data = {
      payload: this.payload,
      default: 480,
    };

    this.subMuteDialog = this.sharedService
      .openMuteDialog(data)
      .subscribe((muteInterval) => {
        if (muteInterval) {
          this.muteConversation(muteInterval);
        }
      });
  }

  markReadDialogue(): void {
    AlertComponent.openDialog(this.matDialog, {
      title: "Confirm",
      message: "Are you sure you want to clear the unread messages badge?",
      acceptOnly: false,
      acceptButtonText: "CLEAR BADGE",
      closeButtonText: "CANCEL",
      reverseButtonOrder: true,
    })
      .afterClosed()
      .subscribe((isConfirm) => {
        if (!isConfirm) return;
        this.markAsRead();
      });
  }

  markAsRead() {
    this.legacyConversationService.markRead(this.convo.id, (resp: any[]) => {
      if (!resp[0]) {
        return;
      }
      const convo = resp[0];
      if (
        convo &&
        convo.id &&
        convo.unreadMessageIds &&
        convo.unreadMessageIds.length == 0
      ) {
        this.snackBar.open("Cleared successfully", null, { duration: 3000 });
        const updatedConvo = { ...this.convo };
        updatedConvo.unreadMessageIds = [];
        this.conService.updateConversationDataByContent(updatedConvo);
      }
    });
  }

  ngOnInit(): void {
    this.pinSubscriber =
      this.legacyConversationService.conversationPinUpdated$.subscribe({
        next: () => {
          this.isPinned = this.convo.participants.some(
            (participant) =>
              participant.userId == this.userAccount.userId &&
              participant.pinnedOnUtc != null
          );
        },
        error: () => {
          this.snackBar.open(
            "Conversation could not be pinned, please try again",
            null,
            { duration: 3000 }
          );
        },
      });

    this.profilePhotoSubscriber = this.legacyConversationService.conversationProfilePhotoUpdated$.subscribe({
      next: (data: ConversationProfilePhotoUpdate) => {
        if(this.convo.id === data.conversationId) {
          this.convo.photoId = data.photoId;
          this.pictureUri = this.conversationsService.getConversationProfileUri(this.convo.id, this.convo.photoId);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subMuteDialog) {
      this.subMuteDialog.unsubscribe();
    }
    if (this.pinSubscriber) {
      this.pinSubscriber.unsubscribe();
    }
  }

  ngOnChanges(): void {
    this.type = this.legacyConversationService.getConversationType(
      this.convo,
      "Group"
    );

    if (this.type === "Chat") {
      this.participantToShow =
        this.legacyConversationService.getChatParticipant(this.convo);
    } else if (this.type === "SelfChat") {
      this.participantToShow =
        this.legacyConversationService.getLoggedInParticipant(this.convo);
    } else if (this.type === "TeamChat") {
      this.participantToShow =
        this.legacyConversationService.getTeamChatParticipant(this.convo);
    } else if (this.type === "External") {
      this.participantToShow =
        this.legacyConversationService.getExternalChatParticipant(this.convo);
    }

    if (this.userAccount) {
      this.setMuteValue();
    }

    if (this.convo) {
      this.setLeftStatus(this.convo);
      this.setPinnedStatus(this.convo);
      this.pictureUri = this.conversationsService.getConversationProfileUri(this.convo.id, this.convo.photoId);
    }

    this.setName();
  }

  setPinnedStatus(conversation: ConversationModel): void {
    this.isPinned = conversation.participants.some(
      (participant) =>
        participant.userId == this.userAccount.userId &&
        participant.pinnedOnUtc != null
    );
  }

  setLeftStatus(conversation: ConversationModel): void {
    if (!this.userAccount) {
      return;
    }

    this.leftConversation = this.legacyConversationService.hasLeft(
      this.userAccount.userId,
      conversation
    );
  }

  setMuteValue(): void {
    const currentDate = new Date();
    for (const participant of this.convo.participants) {
      if (!this.userAccount || !participant) {
        return;
      }
      if (this.userAccount.userId == participant.userId) {
        this.isMuted = false;
        if (participant.mutedToUtc) {
          const mutedTo = new Date(participant.mutedToUtc);
          if (mutedTo > currentDate) {
            this.isMuted = true;
            this.legacyConversationService.saveMuteConversationIdToLocal(
              this.convo.id,
              participant.mutedToUtc.toString()
            );
          }
        }
      }
    }
  }

  setName(): void {
    this.name = this.legacyConversationService.getName(
      this.convo,
      this.type == "SelfChat"
    );
  }

  getParticipantNameById(id: string): string {
    for (const p of this.convo.participants) {
      if (id == p.userId) {
        let name = p.firstName + " " + p.lastName;
        name = name.replace("  ", " ").trim();
        const isMe = p.userId === this.userAccount.userId;
        if (isMe) {
          name = "You";
        }
        if (name) {
          return name;
        }
      }
    }

    return "User";
  }

  hideConvo(convo: ConversationModel | ConversationModelV2) {
    this.isHidden = true;
    const snackBarRef = this.snackBar.open(
      "Conversation archived. You can retrieve this by searching for it.",
      "Undo",
      {
        duration: 3000,
      }
    );
    let undoClicked = false;
    snackBarRef.onAction().subscribe(() => {
      undoClicked = true;
      this.isHidden = false;
    });
    snackBarRef.afterDismissed().subscribe(() => {
      if (!undoClicked) {
        const path =
          environment.celoApiEndpoint +
          "/api/Conversations/" +
          convo.id +
          "/Hide";
        this.sharedService.postObjectById(path).subscribe({
          next: () => {
            const conversationId = this.convo.id;
            this.legacyConversationService.removeConversationById(
              conversationId
            );
            this.sharedService.removeConversationById(conversationId);
            this.conversationsService.removeUnreadConversationId(
              conversationId
            );
          },
          error: () => {
            AlertComponent.openErrorDialog(
              this.matDialog,
              "Could not be archived, try again"
            );
            this.isHidden = false;
          },
        });
      }
    });
  }

  muteConversation(interval: number) {
    const to_mute: MutePayload[] = [
      {
        conversationId: this.convo.id,
        muteInterval: interval,
      },
    ];
    this.saveMuteStatus(to_mute);
  }

  unMuteConversation(): void {
    const to_mute: MutePayload[] = [
      {
        conversationId: this.convo.id,
        muteInterval: 0,
      },
    ];
    this.saveMuteStatus(to_mute);
  }

  saveMuteStatus(to_mute: MutePayload[]): void {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    const path = environment.celoApiEndpoint + "/api/conversations/mute";

    this.sharedService.postObjectById(path, {}, to_mute).subscribe({
      next: (data) => {
        if (
          data &&
          data[0] &&
          data[0].participants &&
          data[0].participants[0]
        ) {
          this.muteStatus.emit(data[0].participants[0]);
        }
      },
      error: (err) => {},
    });
  }

  pinConversation(conversation: ConversationModel): void {
    this.conversationsService.pinConversation(conversation.id).subscribe({
      error: (err) => {
        if (err.status === 400 && err.error.errors?.[0]?.code === "400017") {
          this.snackBar.open("You can only pin 5 conversations.", "Close", {
            duration: 5000,
          });
        }
      },
    });
  }

  unpinConversation(conversation: ConversationModel): void {
    this.conversationsService.unpinConversation(conversation.id).subscribe({
      error: () => {
        this.snackBar.open("Could not be unpinned, try again.", null, {
          duration: 5000,
        });
      },
    });
  }
}
