<ng-container *ngIf="isSelectionList; else nonSelectionListContent">
  <app-conversation-card
    *ngFor="let conversation of conversations"
    class="celo-cursor-pointer"
    [userAccount]="userAccount"
    (click)="onConversationSelected(conversation)"
    [selected]="conversation.id === selectedConversationId"
    [inConversationList]="false"
    [activeOnly]="true"
    [hideBorder]="true"
    [convo]="conversation"
  >
  </app-conversation-card>
</ng-container>

<ng-template #nonSelectionListContent>
  <a
    *ngFor="let conversation of conversations"
    class="appear_anim_2"
    routerLink="/conversations/{{ conversation.id }}/messages"
    #rla="routerLinkActive"
    routerLinkActive="active"
  >
    <app-conversation-card
      (muteStatus)="onMuteStatusChange($event, conversation)"
      [convo]="conversation"
      [userAccount]="userAccount"
      [selected]="rla.isActive"
      [refreshCount]="conversation.refreshCount"
      [hideBorder]="hideBorder"
      [inConversationList]="inConversationList"
      [activeOnly]="activeOnly"
      [hideUnreadCount]="hideUnreadCount"
      [hideArchiveOption]="hideArchiveOption"
      [hideLastMessage]="hideLastMessage"
      [showParticipants]="showParticipants"
    >
    </app-conversation-card>
  </a>
</ng-template>
