import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from "@angular/core";
import { ConversationModel, ConversationModelV2 } from "types";
import { ConversationService } from "../../core";
import { MuteStatusEvent } from "./../conversation-results-group/conversation-results-group.component";

@Component({
  selector: "app-conversation-results",
  templateUrl: "./conversation-results.component.html",
  styleUrls: ["./conversation-results.component.scss"],
})
export class ConversationResultsComponent implements OnChanges, OnDestroy {
  @Input() isSelectionList: boolean = false;
  @Input() selectedConversationId: string | null = null;
  @Input() conversations: ConversationModel[];
  @Input() userAccount: any;
  @Input() searchKeyword: string;
  @Input() canLoadMore: boolean;
  @Output() loadMore = new EventEmitter(); // todo: should emit when we scroll, not button click;
  @Output() public conversation = new EventEmitter<ConversationModelV2>();

  chats = [];
  groups = [];
  cases = [];
  archived = [];
  refreshCount: any;

  constructor(private conversationService: ConversationService) {}

  ngOnDestroy() {
    this.conversations = undefined;
  }

  ngOnChanges() {
    if (this.conversations && this.conversations.length) {
      this.search();
    }
  }

  public onConversationSelected(conversation: ConversationModelV2) {
    this.conversation.emit(conversation);
  }

  resetAll() {
    this.chats = [];
    this.groups = [];
    this.cases = [];
    this.archived = [];
  }

  search() {
    this.resetAll();
    this.conversations.forEach((conversation) => {
      const participants = conversation.participants;
      const archived = this.isArchived(this.userAccount.userId, participants);

      if (archived) {
        this.archived.push(conversation);
      } else if (
        this.conversationService.getConversationType(conversation) == "Chat" ||
        this.conversationService.getConversationType(conversation) ==
          "SelfChat" ||
        this.conversationService.getConversationType(conversation) == "TeamChat"
      ) {
        this.chats.push(conversation);
      } else if (
        this.conversationService.getConversationType(conversation) == "Group"
      ) {
        this.groups.push(conversation);
      } else if (
        this.conversationService.getConversationType(conversation) == "Case"
      ) {
        this.cases.push(conversation);
      } else {
        this.groups.push(conversation);
      }
    });
    this.conversationService.sortConversation(this.chats);
    this.conversationService.sortConversation(this.groups);
    this.conversationService.sortConversation(this.cases);
    this.conversationService.sortConversation(this.archived);
    // this.chats = this.chats.reverse();
    this.groups = this.groups.reverse();
    this.cases = this.cases.reverse();
    this.archived = this.archived.reverse();

    this.conversationService.sortByName(this.groups, this.searchKeyword);
    this.conversationService.sortByName(this.cases, this.searchKeyword);
    this.conversationService.sortByName(this.archived, this.searchKeyword);
    this.conversationService.sortByType(this.archived, "Group");
    this.conversationService.sortByType(this.archived, "Chat");

    for (const conversation of this.groups) {
      this.conversationService.sortParticipantsByName(
        conversation.participants,
        this.searchKeyword
      );
    }
    for (const conversation of this.cases) {
      this.conversationService.sortParticipantsByName(
        conversation.participants,
        this.searchKeyword
      );
    }
    for (const conversation of this.archived) {
      if (
        this.conversationService.getConversationType(conversation) != "Chat"
      ) {
        this.conversationService.sortParticipantsByName(
          conversation.participants,
          this.searchKeyword
        );
      }
    }
  }

  isArchived(userId, participants) {
    for (const participant of participants) {
      if (participant.userId == userId && participant["isHidden"]) {
        return true;
      }
    }
    return false;
  }

  muteStatusChange({ participant, conversation }: MuteStatusEvent) {
    // this.conversationService.muteStatusChange(participantData, conversation);
    this.conversationService.updateMuteStatusInConversation(
      participant.userId,
      conversation.id,
      participant.mutedToUtc,
      participant.muteInterval
    );
  }
}
