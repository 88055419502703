import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ConService, ConversationsService, UserService } from "@modules/core";
import { AlertComponent, AlertResult } from "@modules/core/components";
import { VoipService } from "@modules/core/services/voip.service";
import {
  VideoLayout,
  ZoomError,
  ZoomParticipantData,
  ZoomService,
} from "@modules/core/services/zoom.service";
import { UserSelectionList } from "@modules/shared/basic-user-selection-list/basic-user-selection-list.component";
import {
  Call,
  CallParticipant,
  ConversationModelV2,
  VideoCallStatus,
} from "@types";
import {
  clamp,
  concatNotNull,
  difference,
  isCeloErrorResponse,
  isHttpErrorResponse,
  isNotNullOrUndefined,
  localeCompareIfNotNull,
  partition,
  sorted,
  toMap,
} from "@utils";
import { Participant, VideoQuality } from "@zoom/videosdk";
import { PinscreenService } from "app/pinscreen/pinscreen.service";
import { DateTime } from "luxon";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  asyncScheduler,
  catchError,
  combineLatest,
  concat,
  finalize,
  first,
  forkJoin,
  fromEvent,
  map,
  of,
  retry,
  switchMap,
  throttleTime,
  throwError,
  timer,
} from "rxjs";
import { getErrorDescriptor } from "utils/error-utils";
import { VoipReminderSnackbarComponent } from "../voip-reminder-snackbar/voip-reminder-snackbar.component";
import {
  ConversationParticipantModelV2,
  ConversationType,
  IdentityVerificationStatus,
  ProfessionVerificationStatus,
  VerificationStatus,
} from "./../../../types/api-v2";

interface VoipAvatar {
  width: number;
  height: number;
  xPos: number;
  yPos: number;
  zoomUserId: number;
  zoomUserIdentity: string;
  userId?: string | null;
  name: string;
  isVideoOn: boolean;
  isMuted: boolean;
  isYou: boolean;
  isWorkspaceVerified: boolean;
  isIdentityVerified: boolean;
  isProfessionVerified: boolean;
}

enum VideoActionType {
  Init,
  RequestPermissions,
  StartOrJoinCall,
  JoinSession,
  StartAudio,
  StartVideo,
}

interface VideoAction {
  type: VideoActionType;
}

interface InitAction extends VideoAction {
  type: VideoActionType.Init;
  conversation: ConversationModelV2;
  isNewCall: boolean;
}

interface RequestPermissionsActions extends VideoAction {
  type: VideoActionType.RequestPermissions;
}

interface StartOrJoinCallAction extends VideoAction {
  type: VideoActionType.StartOrJoinCall;
  call: Call;
  participant: CallParticipant;
}

interface JoinSessionAction extends VideoAction {
  type: VideoActionType.JoinSession;
}

interface StartAudioAction extends VideoAction {
  type: VideoActionType.StartAudio;
  error?: ZoomError;
}

interface StartVideoAction extends VideoAction {
  type: VideoActionType.StartVideo;
  error?: ZoomError;
}

type VideoLoadingAction =
  | InitAction
  | RequestPermissionsActions
  | StartOrJoinCallAction
  | JoinSessionAction
  | StartAudioAction
  | StartVideoAction;

interface ParticipantData {
  zoomParticipantData: ZoomParticipantData;
  callParticipants: CallParticipant[];
  conversation: ConversationModelV2;
}

type ObservableProperties<T extends object> = {
  [P in keyof T]: Observable<T[P]>;
};

interface RectDimensions {
  width: number;
  height: number;
}

interface RenderedVideoPosition {
  cellWidth: number;
  cellHeight: number;
  xPos: number;
  yPos: number;
}

/** Do not set number above 6, because layout is based on max 6 participants */
const MAX_PARTICIPANTS_PER_PAGE = 6;

@Component({
  selector: "app-call",
  templateUrl: "./call.component.html",
  styleUrls: ["./call.component.scss"],
})
export class CallComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild("canvas")
  private canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild("currentUserVideoCanvasContainer")
  private currentUserVideoCanvasContainer: ElementRef<HTMLElement>;

  @ViewChild("currentUserVideoCanvas")
  private currentUserVideoCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild("videoContainer")
  private videoContainer: ElementRef<HTMLElement>;

  @ViewChild("callHeader")
  private callHeader: ElementRef<HTMLElement>;

  private participantsSubscription: Subscription;
  private queryParamsSubscription: Subscription;
  private lastActiveSpeakerSubscription: Subscription;
  private lastActiveSpeakerUserId: number | null = null;

  /** Ordered alphabetically. The current user is __not__ included in this array. */
  public participants: Participant[] = [];
  private currentUserParticipant: Participant | null = null;

  /** 0 indexed */
  private currentPage: number = 0;
  private renderedParticipants: Participant[] = []; // These are the ones rendered to the screen if paginated
  private conversationId: string | null = null;
  private callId: string | null = null;

  /** `userKey` is the same as Zoom's `userIdentity` */
  public userKeyToConversationParticipant = new Map<
    string,
    ConversationParticipantModelV2
  >();

  /** This does not include the current user's avatar */
  public avatars: VoipAvatar[] = [];
  public currentUserAvatar: VoipAvatar | null = null;

  private resizeSubscription: Subscription;
  private videoCallUpdateSubscription: Subscription;
  public conversationSubscription: Subscription;
  public conversation: ConversationModelV2 | null = null;
  public showLeftPaginationButton: boolean;
  public showRightPaginationButton: boolean;
  public paginationText: string;
  public isCallEnded: boolean = false;
  public showVoipOverlay: boolean = true;
  public isNewCall: boolean;
  public currentCallParticipantId: string = "";

  public hasOtherParticipantJoinedPreviously: boolean = false;

  public isAudioStarted: boolean = false;
  public isVideoStarted: boolean = false;

  public dimensions: RectDimensions = {
    width: 1920,
    height: 1080,
  };

  private call: Call | null = null;
  private reminderTimeOut: number | NodeJS.Timeout | null = null;
  private snackbarRef: MatSnackBarRef<VoipReminderSnackbarComponent>;

  public conversationParticipants: UserSelectionList | null = null;

  private isActiveInterval: number | NodeJS.Timeout | null = null;

  private isReadyToRenderSubject = new BehaviorSubject<boolean | null>(null);
  private isReadyToRender$ = this.isReadyToRenderSubject.asObservable();

  private startAudioAction: StartAudioAction | null = null;
  private startVideoAction: StartVideoAction | null = null;

  private callEndedTimer: number | NodeJS.Timeout | null = null;

  private requestId: string = crypto.randomUUID();

  private latestActionType: VideoActionType | null = null;

  // @HostListener("window:beforeunload", ["$event"])
  // public handleUnload(event: BeforeUnloadEvent) {
  //   event.preventDefault();

  //   // Included for legacy support, e.g. Chrome/Edge < 119
  //   event.returnValue = true;
  // }

  private renderedVideoPositions = new Map<number, RenderedVideoPosition>();

  private renderSubject = new Subject<boolean>();
  private render$ = this.renderSubject.pipe(
    throttleTime(500, asyncScheduler, {
      leading: true,
      trailing: true,
    })
  );

  private leaveCallDialogSubscription: Subscription | null = null;
  private leaveCallSubscription: Subscription | null = null;

  public currentUserVideoPositions: {
    startX: number;
    startY: number;
    right: number;
    bottom: number;
  } = {
      startX: 0,
      startY: 0,
      right: 24,
      bottom: 68
    }

  public conversationName: string | null = null;

  constructor(
    private zoom: ZoomService,
    private route: ActivatedRoute,
    private conversationService: ConversationsService,
    private voip: VoipService,
    private matDialog: MatDialog,
    private conService: ConService,
    private pinScreen: PinscreenService,
    private matSnackBar: MatSnackBar,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Load initial values immediately from snapshot to ensure they are available if endCall() is triggered before
    // the request to get the conversation is complete
    const queryParamMap = this.route.snapshot.queryParamMap;
    this.conversationId = queryParamMap.get("conversationId");
    this.callId = queryParamMap.get("callId");
    this.isNewCall = !this.callId;

    if (!this.voip.isCallWindow()) {
      this.isCallEnded = true;
      AlertComponent.openDialog(this.matDialog, {
        title: "Error",
        message: `It looks like you're trying to ${this.isNewCall ? 'start' : 'join'} a call in an unsupported way. Please use the call action in a conversation to start or join a call.`
      }).afterClosed().pipe(finalize(() => {
        this.router.navigate(['/'])
      })).subscribe();
      return;
    }

    this.videoCallUpdateSubscription =
      this.conService.videoCallUpdate$.subscribe((update) => {
        if (update.status !== VideoCallStatus.Ended) return;
        if (update.conversationId !== this.conversationId) return;
        this.endCall();
      });

    this.isActiveInterval = setInterval(() => {
      this.pinScreen.updateLastActiveTime();
    }, 1000);

    // We are deliberately __not__ cleaning up this subscription as we want to ensure it is not destroyed in ngOnInit
    // if the component is destroyed when a user closes the window.
    fromEvent(window, 'beforeunload').subscribe({
      next: () => {
        this.endCall(false, true);
      }
    })

    this.leaveCallDialogSubscription = this.voip.openLeaveCallDialog$.subscribe({
      next: () => {
        this.voip.openLeaveCallDialog().subscribe({
          next: (isConfirmed) => {
            if (!isConfirmed) return;
            this.endCall(true);
          }
        })
      }
    });

    this.leaveCallSubscription = this.voip.leaveCall$.subscribe({
      next: () => {
        window.close();
      }
    })

    this.queryParamsSubscription = this.route.queryParamMap
      .pipe(
        switchMap((params) => {
          const conversationId = params.get("conversationId");
          const callId = params.get("callId");
          const isNewCall = !callId;

          if (!conversationId) {
            return throwError(
              () => new Error("conversationId must not be null")
            );
          }

          return this.conversationService.getConversation(conversationId).pipe(
            first(),
            map((conversation) => ({
              conversation,
              conversationId,
              callId,
              isNewCall,
            }))
          );
        }),
        switchMap((data) => {
          const { conversation, conversationId, callId, isNewCall } = data;

          // TODO: Refactor the start/join call flow to make these requests before this component is mounted
          const startOrJoinCallObservable = isNewCall
            ? this.voip.startCall(this.requestId, conversationId)
            : this.voip.joinCall(this.requestId, callId);

          return concat<VideoLoadingAction[]>(
            of<InitAction>({
              type: VideoActionType.Init,
              conversation,
              isNewCall,
            }),
            // Not sure if we want to use this step. It would guarantee we have access to microphone and camera, but
            // it's all-or-thing. If a user denies access to only their camera it will fail.
            // this.zoom.requestPermissions().pipe(
            //   map<unknown, RequestPermissionsActions>(() => ({
            //     type: VideoActionType.RequestPermissions,
            //   }))
            // ),
            startOrJoinCallObservable.pipe(
              switchMap(({ call, participant, token, sessionName }) => {
                return concat(
                  of<StartOrJoinCallAction>({
                    type: VideoActionType.StartOrJoinCall,
                    call,
                    participant,
                  }),
                  this.voip.joinSession(token, sessionName).pipe(
                    retry({
                      count: 10,
                      delay: (error, retryCount) => {
                        // Exponential backoff with a maximum delay of 1 second
                        const delay = Math.min(2 ** retryCount * 200, 1000);
                        return timer(delay);
                      },
                    }),
                    map<unknown, JoinSessionAction>(() => ({
                      type: VideoActionType.JoinSession,
                    }))
                  )
                );
              })
            ),
            this.zoom.startAudio().pipe(
              map<unknown, StartAudioAction>(() => ({
                type: VideoActionType.StartAudio,
              }))
            ),
            // startVideo errors are caught to enable devices without a camera to still join calls with audio only
            this.zoom.startVideo().pipe(
              map<unknown, StartVideoAction>(() => ({
                type: VideoActionType.StartVideo,
              })),
              catchError((error) => {
                if (!(error instanceof ZoomError)) throw error;
                return of<StartVideoAction>({
                  type: VideoActionType.StartVideo,
                  error,
                });
              })
            )
          );
        })
      )
      .subscribe({
        next: (action) => {
          this.latestActionType = action.type;
          switch (action.type) {
            case VideoActionType.Init:
              this.handleInitAction(action);
              break;
            case VideoActionType.RequestPermissions:
              this.handleRequestPermissionsAction(action);
              break;
            case VideoActionType.StartOrJoinCall:
              this.handleStartOrJoinCallAction(action);
              break;
            case VideoActionType.JoinSession:
              this.handleJoinSession(action);
              break;
            case VideoActionType.StartAudio:
              this.handleStartMediaAction(action);
              break;
            case VideoActionType.StartVideo:
              this.handleStartMediaAction(action);
              break;
            default:
              throw new Error("Unhandled action type");
          }
        },
        error: (err) => {
          console.error(
            `Failed to initialize video call. Last action type = ${this.latestActionType}`,
            err
          );

          this.stopDialingAudio();
          clearInterval(this.isActiveInterval);

          let errorDialogRef: MatDialogRef<AlertComponent, AlertResult>;
          if (isHttpErrorResponse(err) && isCeloErrorResponse(err.error)) {
            const descriptor = getErrorDescriptor(err.error);
            errorDialogRef = AlertComponent.openErrorDialogFromErrorDescriptor(
              this.matDialog,
              descriptor
            );
          } else if (err instanceof ZoomError) {
            const zoomErrorDescriptor = err.getErrorDescriptor();
            if (zoomErrorDescriptor) {
              errorDialogRef =
                AlertComponent.openErrorDialogFromErrorDescriptor(
                  this.matDialog,
                  zoomErrorDescriptor
                );
            }
          }

          if (!errorDialogRef) {
            errorDialogRef = AlertComponent.openErrorDialog(this.matDialog);
          }

          errorDialogRef.afterClosed().subscribe({
            next: () => this.endCall(true),
          });
        },
      });
  }

  ngAfterViewInit() {
    this.render$.subscribe({
      next: (forceRerender) => this.handleRenderInternal(forceRerender),
    });

    this.isReadyToRender$.subscribe({
      next: (isReady) => {
        if (!isReady) return;

        if (this.lastActiveSpeakerSubscription)
          this.lastActiveSpeakerSubscription.unsubscribe();
        this.lastActiveSpeakerSubscription =
          this.zoom.lastActiveSpeaker$.subscribe({
            next: (speaker) => {
              // Only highlight speaker if there are more than 2 participants
              if (this.participants.length < 3) return;
              this.lastActiveSpeakerUserId = speaker?.userId ?? null;
            },
          });

        if (this.participantsSubscription)
          this.participantsSubscription.unsubscribe();

        const issuedGetCallParticipantRequestsFor = new Set<string>();

        this.participantsSubscription = combineLatest<
          ObservableProperties<ParticipantData>
        >({
          zoomParticipantData: this.zoom.allParticipants$,
          callParticipants: this.voip.getAllCallParticipants({
            callId: this.callId,
            count: 100,
          }),
          conversation: this.conversationService.getConversation(
            this.conversationId
          ),
        })
          .pipe(
            switchMap<ParticipantData, Observable<ParticipantData | null>>(
              (data) => {
                const { zoomParticipantData, callParticipants, conversation } =
                  data;

                // Find user keys we don't already have mapped
                const latestUserKeys = new Set(
                  zoomParticipantData.participants.map((p) => p.userIdentity)
                );
                const currentUserKeys = new Set(
                  this.userKeyToConversationParticipant.keys()
                );
                const newUserKeys = difference(latestUserKeys, currentUserKeys);

                // Find user keys we don't already have call participant data for
                const currentCallParticipantUserKeys = new Set(
                  callParticipants.map((p) => p.userKey)
                );
                const newCallParticipantUserKeys = difference(
                  newUserKeys,
                  currentCallParticipantUserKeys
                );

                if (!newCallParticipantUserKeys.size) return of(data);

                const unfetchedCallParticipantUserKeys = difference(
                  newCallParticipantUserKeys,
                  issuedGetCallParticipantRequestsFor
                );

                unfetchedCallParticipantUserKeys.forEach((value) =>
                  issuedGetCallParticipantRequestsFor.add(value)
                );

                const newCallParticipants$ = forkJoin(
                  Array.from(unfetchedCallParticipantUserKeys).map((userKey) =>
                    this.voip
                      .getCallParticipants({
                        latestOnly: true,
                        callId: this.callId,
                        userKey,
                      })
                      .pipe(
                        retry(3),
                        catchError(() => [])
                      )
                  )
                );

                // This will load the latest call participant data and update the underlying store which will trigger
                // the top-level observable from combineLatest to emit a new value
                return newCallParticipants$.pipe(map(() => null));
              }
            ),
            switchMap<ParticipantData, Observable<ParticipantData | null>>(
              (data) => {
                if (!data) return;

                const { zoomParticipantData, callParticipants, conversation } =
                  data;

                // Get latest conversation data if we're missing data on any call participants
                const conversationParticipantUserIds = new Set(
                  conversation.participants.map((p) => p.userId)
                );
                const callParticipantUserIds = new Set(
                  callParticipants.map((p) => p.userId)
                );

                const missingUserIds = difference(
                  callParticipantUserIds,
                  conversationParticipantUserIds
                );
                if (!missingUserIds.size) return of(data);

                // This will load the latest conversation data and update the underlying store which will trigger
                // the top-level observable from combineLatest to emit a new value
                return this.conversationService
                  .getConversation(this.conversationId, {
                    latestOnly: true,
                  })
                  .pipe(map(() => null));
              }
            )
          )
          .subscribe({
            next: (data) => {
              if (!data) return;

              const { zoomParticipantData, callParticipants, conversation } =
                data;

              const conversationParticipantMap = toMap(
                conversation.participants,
                (p) => p.userId,
                (p) => p
              );

              const zoomParticipantUserKeys = new Set(
                zoomParticipantData.participants.map((p) => p.userIdentity)
              );
              const activeCallParticipants = callParticipants.filter((p) =>
                zoomParticipantUserKeys.has(p.userKey)
              );
              this.userKeyToConversationParticipant = toMap(
                activeCallParticipants,
                (p) => p.userKey,
                (p) => conversationParticipantMap.get(p.userId),
                { ignoreNullOrUndefinedValues: true }
              );

              const currentUserKey =
                this.zoom.getCurrentUserInfo()?.userIdentity;
              this.conversationParticipants =
                this.mapConversationParticipantsToSelectionList(
                  this.userKeyToConversationParticipant,
                  currentUserKey
                );

              const hasOtherParticipants = zoomParticipantData.participants.length > 1;
              this.showVoipOverlay = !hasOtherParticipants;

              if (hasOtherParticipants) {
                this.hasOtherParticipantJoinedPreviously = true;
                this.stopDialingAudio();
              }

              const [currentUserParticipants, otherParticipants] = partition(zoomParticipantData.participants,
                (p) => p.userIdentity === currentUserKey
              )

              const sortedParticipants = sorted(
                otherParticipants,
                (zoomParticipantA, zoomParticipantB) => {
                  // Current user is always sorted to the start of the list
                  if (zoomParticipantA.userIdentity === currentUserKey)
                    return -1;

                  if (zoomParticipantB.userIdentity === currentUserKey)
                    return 1;

                  const conversationParticipantA =
                    this.userKeyToConversationParticipant.get(
                      zoomParticipantA.userIdentity
                    );
                  const conversationParticipantB =
                    this.userKeyToConversationParticipant.get(
                      zoomParticipantB.userIdentity
                    );

                  const displayNameA = this.getParticipantDisplayName(
                    zoomParticipantA,
                    conversationParticipantA
                  );
                  const displayNameB = this.getParticipantDisplayName(
                    zoomParticipantB,
                    conversationParticipantB
                  );

                  return localeCompareIfNotNull(displayNameA, displayNameB);
                }
              );

              this.currentUserParticipant = currentUserParticipants?.length ? currentUserParticipants[0] : null;
              this.participants = sortedParticipants;
              this.handleRender();
            },
          });

        if (this.resizeSubscription) this.resizeSubscription.unsubscribe();
        this.resizeSubscription = fromEvent(window, "resize").subscribe(() => {
          this.updateCurrentUserVideoPosition();
          this.handleRender(true);
        });
      },
    });
  }

  ngOnDestroy() {
    this.endCall(false, true);
    this.participantsSubscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
    this.queryParamsSubscription?.unsubscribe();
    this.lastActiveSpeakerSubscription?.unsubscribe();
    this.conversationSubscription?.unsubscribe();
    this.videoCallUpdateSubscription?.unsubscribe();
    this.leaveCallDialogSubscription?.unsubscribe();
    this.leaveCallSubscription?.unsubscribe();

    if (this.reminderTimeOut) clearTimeout(this.reminderTimeOut);
    if (this.isActiveInterval) clearInterval(this.isActiveInterval);
    if (this.callEndedTimer) clearTimeout(this.callEndedTimer);
  }

  private handleInitAction(action: InitAction) {
    const { conversation, isNewCall } = action;
    this.conversation = conversation;
    this.conversationId = conversation.id;
    this.isNewCall = isNewCall;

    this.conversationName = conversation.name;
    if (conversation.type === ConversationType.Chat) {
      const currentUserId = this.userService.getUserId(true);
      const otherParticipant = conversation.participants.find(p => p.userId !== currentUserId);
      this.conversationName = concatNotNull([otherParticipant.firstName, otherParticipant.lastName]);
    }

    this.isReadyToRenderSubject.next(false);
  }

  private handleRequestPermissionsAction(action: RequestPermissionsActions) { }

  private handleStartOrJoinCallAction(action: StartOrJoinCallAction) {
    const { call } = action;
    this.callId = call.id;
    this.call = call;
    this.currentCallParticipantId = action.participant.id;

    if (this.isNewCall) {
      this.voip.playDialingAudio();
    }

    this.setReminderTimeout(this.call.createdOn, this.call.maxCallLength);
  }

  private handleJoinSession(action: JoinSessionAction) {
    this.isReadyToRenderSubject.next(true);
  }

  private handleStartMediaAction(action: StartAudioAction | StartVideoAction) {
    if (action.type === VideoActionType.StartAudio) {
      this.startAudioAction = action;
      this.isAudioStarted = true;
      return;
    } else {
      this.startVideoAction = action;
      this.isVideoStarted = true;
    }

    if (!this.startAudioAction.error && !this.startVideoAction.error) return;
    const descriptor = this.startVideoAction.error.getErrorDescriptor();
    if (descriptor) {
      AlertComponent.openErrorDialogFromErrorDescriptor(
        this.matDialog,
        descriptor
      );
    } else {
      AlertComponent.openErrorDialog(this.matDialog, "Failed to start video.");
    }
  }

  private mapConversationParticipantsToSelectionList(
    userKeyToConversationParticipantMap: Map<
      string,
      ConversationParticipantModelV2
    >,
    currentUserKey: string
  ): UserSelectionList {
    const entries = Array.from(userKeyToConversationParticipantMap.entries());
    return entries
      .map(([userKey, p]) => {
        const name = concatNotNull([p.firstName, p.lastName]);
        return {
          id: p.userId,
          name,
          isDisabled: false,
          isSelected: false,
          suffix: userKey === currentUserKey ? " (You)" : null,
          fetchImage: p.profilePic != null,
          isIdentityVerified:
            p.identityVerificationStatus ===
            IdentityVerificationStatus.Verified,
          isProfessionVerified: p.professions?.some(
            (profession) =>
              profession.verificationStatus ===
              ProfessionVerificationStatus.Verified
          ),
          isWorkspaceVerified: p.workplaces?.some(
            (w) =>
              !w.leftOnUtc &&
              w.verificationStatus === VerificationStatus.Verified
          ),
          userKey,
        };
      })
      .sort((a, b) => {
        // Always sort current user to the start of the list and sort other participants alphabetically
        // It is possible for a user to join a call multiple times on different devices/tabs, so the current user key
        // must be used to identity the user on this device/tab
        if (a.userKey === currentUserKey) return -1;
        if (b.userKey === currentUserKey) return 1;
        return localeCompareIfNotNull(a.name, b.name);
      });
  }

  private stopDialingAudio() {
    this.voip.stopDialingAudio();
  }

  // Responsible for unrendering all the current participants
  private unrenderParticipants = async (newParticipants: Participant[]) => {
    const canvas = this.canvas.nativeElement;
    const newParticipantUserIds = new Set(newParticipants.map((p) => p.userId));

    const promises = this.renderedParticipants.map(async (p) => {
      const isStillRendered = newParticipantUserIds.has(p.userId);

      // Stop rendering any currently rendered participants that have turned off their video
      if (isStillRendered) return Promise.resolve();
      await this.zoom.getStream().stopRenderVideo(canvas, p.userId);
    });

    await Promise.allSettled(promises);
  };

  private renderAvatars = (otherParticipants: Participant[]) => {
    const layout = this.zoom.getAvatarLayout(
      this.dimensions.width,
      this.dimensions.height,
      this.getLayoutCellCount()
    );

    if (this.currentUserParticipant) {
      this.currentUserAvatar = this.createAvatar(this.currentUserParticipant, layout);
    }

    this.avatars = otherParticipants
      .map((zoomParticipant, index) => this.createAvatar(zoomParticipant, layout, index))
      .filter(isNotNullOrUndefined);
  };

  private createAvatar(participant: Participant, layout: VideoLayout, cellIndex: number | null = null): VoipAvatar {
    const {
      cellWidth: avatarWidth,
      cellHeight: avatarHeight,
      cells: avatarCells,
    } = layout;

    const conversationParticipant =
      this.userKeyToConversationParticipant.get(
        participant.userIdentity
      );
    if (!conversationParticipant) return null;

    const name = this.getParticipantDisplayName(
      participant,
      conversationParticipant
    );

    const isYou =
      participant.userIdentity ===
      this.zoom.getCurrentUserInfo()?.userIdentity;

    const isWorkspaceVerified = conversationParticipant.workplaces?.some(
      (w) =>
        !w.leftOnUtc && w.verificationStatus === VerificationStatus.Verified
    );

    const isIdentityVerified =
      conversationParticipant.identityVerificationStatus ===
      IdentityVerificationStatus.Verified;

    const isProfessionVerified = conversationParticipant.professions?.some(
      (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
    );

    const avatar: VoipAvatar = {
      width: isYou ? this.currentUserVideoCanvasContainer.nativeElement.clientWidth : avatarWidth,
      height: isYou ? this.currentUserVideoCanvasContainer.nativeElement.clientHeight : avatarHeight,
      xPos: isYou ? 0 : avatarCells[cellIndex].xPos,
      yPos: isYou ? 0 : avatarCells[cellIndex].yPos,
      zoomUserId: participant.userId,
      zoomUserIdentity: participant.userIdentity,
      userId: conversationParticipant?.userId ?? null,
      name,
      isVideoOn: participant.bVideoOn,
      isMuted: participant.muted ?? true,
      isYou,
      isWorkspaceVerified,
      isIdentityVerified,
      isProfessionVerified,
    };

    return avatar;
  }

  // Note: this is used to ensure the ordering of participants by name is consistent for the avatars and the rendered
  // videos as the ordering is determined by their display name
  private getParticipantDisplayName(
    zoomParticipant: Participant,
    conversationParticipant?: ConversationParticipantModelV2 | null
  ) {
    return conversationParticipant
      ? concatNotNull([
        conversationParticipant.firstName,
        conversationParticipant.lastName,
      ])
      : zoomParticipant.displayName;
  }

  private shouldAdjustVideoPosition(
    newPosition: RenderedVideoPosition,
    previousPosition?: RenderedVideoPosition | null
  ): Boolean {
    if (!previousPosition) return true;
    if (newPosition.cellHeight !== previousPosition.cellHeight) return true;
    if (newPosition.cellWidth !== previousPosition.cellWidth) return true;
    if (newPosition.xPos !== previousPosition.xPos) return true;
    if (newPosition.yPos !== previousPosition.yPos) return true;
    return false;
  }

  private renderParticipants = async (participants: Participant[]) => {
    const { cellWidth, cellHeight, cells } = this.zoom.getVideoLayout(
      this.dimensions.width,
      this.dimensions.height,
      this.getLayoutCellCount()
    );
    const stream = this.zoom.getStream();

    const participantsWithVideoEnabled = new Set(
      this.renderedParticipants.filter((p) => p.bVideoOn).map((p) => p.userId)
    );

    const promises: Promise<unknown>[] = participants.map(async (p, index) => {
      const isVideoAlreadyEnabled = participantsWithVideoEnabled.has(p.userId);

      const newPosition: RenderedVideoPosition = {
        cellWidth,
        cellHeight,
        xPos: cells[index].xPos,
        yPos: cells[index].yPos,
      };

      if (isVideoAlreadyEnabled) {
        const previousPosition = this.renderedVideoPositions.get(p.userId);
        if (this.shouldAdjustVideoPosition(newPosition, previousPosition)) {
          await stream.adjustRenderedVideoPosition(
            this.canvas.nativeElement,
            p.userId,
            newPosition.cellWidth,
            newPosition.cellHeight,
            newPosition.xPos,
            newPosition.yPos
          );
        }
      } else {
        await stream.renderVideo(
          this.canvas.nativeElement,
          p.userId,
          newPosition.cellWidth,
          newPosition.cellHeight,
          newPosition.xPos,
          newPosition.yPos,
          VideoQuality.Video_360P
        );
      }

      this.renderedVideoPositions.set(p.userId, newPosition);
    });

    this.renderedParticipants = [...participants];

    if (this.currentUserParticipant) {
      const isVideoAlreadyEnabled = participantsWithVideoEnabled.has(this.currentUserParticipant.userId);

      if (!isVideoAlreadyEnabled) {
        const renderSelfVideo = stream.renderVideo(
          this.currentUserVideoCanvas.nativeElement,
          this.currentUserParticipant.userId,
          this.currentUserVideoCanvas.nativeElement.width,
          this.currentUserVideoCanvas.nativeElement.height,
          0,
          0,
          VideoQuality.Video_360P
        );
        promises.push(renderSelfVideo);
      }

      this.renderedParticipants.push(this.currentUserParticipant);
    }

    await Promise.allSettled(promises);
  };

  private getLayoutCellCount() {
    return Math.min(this.participants.length, MAX_PARTICIPANTS_PER_PAGE);
  }

  private shouldRerender(newRenderedParticipants: Participant[]): boolean {
    const previousRenderedParticipants = this.renderedParticipants;

    // Rerender if number of participants to be rendered has changed
    if (newRenderedParticipants.length !== previousRenderedParticipants.length)
      return true;

    for (let i = 0; i < newRenderedParticipants.length; i++) {
      const previousParticipant = previousRenderedParticipants[i];
      const newParticipant = newRenderedParticipants[i];

      // Rerender if ordering has changed
      if (newParticipant.userId !== previousParticipant.userId) return true;

      // Rerender if video has been turned on or off
      if (newParticipant.bVideoOn !== previousParticipant.bVideoOn) return true;

      // Rerender if audio has been muted or unmuted
      if (newParticipant.muted !== previousParticipant.muted) return true;
    }

    return false;
  }

  private shouldUpdateCanvasDimensions(): boolean {
    const canvas = this.canvas.nativeElement;
    if (this.dimensions.width !== canvas.parentElement.clientWidth) return true;
    if (this.dimensions.height !== canvas.parentElement.clientHeight) return true;
    return false;
  }

  public handleRender(forceRerender: boolean = false) {
    this.renderSubject.next(forceRerender);
  }

  private async handleRenderInternal(forceRerender: boolean = false) {
    const newPaginatedParticipants = this.splitParticipants(this.participants);
    this.preventPageOverflow(newPaginatedParticipants);
    const newRenderedParticipants = newPaginatedParticipants[this.currentPage] ?? [];

    if (!forceRerender && !this.shouldRerender(newRenderedParticipants) && !this.shouldUpdateCanvasDimensions()) return;

    const canvas = this.canvas.nativeElement;
    const stream = this.zoom.getStream();

    this.dimensions.width = canvas.parentElement.clientWidth;
    this.dimensions.height = canvas.parentElement.clientHeight;

    try {
      canvas.width = this.dimensions.width;
      canvas.height = this.dimensions.height;
    } catch {
      await stream.updateVideoCanvasDimension(
        canvas,
        this.dimensions.width,
        this.dimensions.height
      );
    }

    await this.unrenderParticipants(newRenderedParticipants);
    await this.renderParticipants(newRenderedParticipants);
    this.renderAvatars(newRenderedParticipants);
    this.handlePaginationButtonState(newPaginatedParticipants.length);
  }

  private handlePaginationButtonState = (pageCount: number) => {
    this.showLeftPaginationButton = this.currentPage > 0;
    this.showRightPaginationButton = this.currentPage < pageCount - 1;

    if (pageCount < 2) {
      this.paginationText = "";
    } else {
      // convert 0-indexed to 1-indexed
      this.paginationText = `${this.currentPage + 1}/${pageCount}`;
    }
  };

  private splitParticipants = (participants: Participant[]) => {
    const result: Participant[][] = [];
    for (let i = 0; i < participants.length; i += MAX_PARTICIPANTS_PER_PAGE) {
      result.push(participants.slice(i, i + MAX_PARTICIPANTS_PER_PAGE));
    }
    return result;
  };

  public navigateLeft = () => {
    this.currentPage -= 1;
    this.handleRender(true);
  };

  public navigateRight = () => {
    this.currentPage += 1;
    this.handleRender(true);
  };

  private preventPageOverflow = (paginatedParticipants: Participant[][]) => {
    if (this.currentPage < paginatedParticipants.length) return;
    this.currentPage = Math.max(0, paginatedParticipants.length - 1)
  };

  public endCall(
    isUserActioned: boolean = false,
    disableNavigation: boolean = false
  ) {
    this.showVoipOverlay = true;

    if (this.isCallEnded) return;
    this.isCallEnded = true;

    this.stopDialingAudio();

    clearInterval(this.isActiveInterval);
    if (this.snackbarRef) this.snackbarRef.dismiss();

    this.voip.leaveCall(
      this.requestId,
      this.callId,
      this.currentCallParticipantId
    ).pipe(finalize(() => {
      if (disableNavigation) return;

      if (isUserActioned) {
        window.close();
      } else {
        setTimeout(
          () => window.close(),
          3000
        );
      }
    })).subscribe();
  }

  private calculateEndCallReminderTime = (
    createdOn: string,
    maxCallLength: number // In minutes
  ) => {
    const startTime = DateTime.fromISO(createdOn);
    const reminderTime = startTime.plus({ minutes: maxCallLength - 5 });
    const currentTime = DateTime.now();
    const diffSeconds = reminderTime
      .diff(currentTime, "seconds")
      .toObject().seconds;
    return diffSeconds;
  };

  private setReminderTimeout = (createdOn: string, maxCallLength: number) => {
    if (this.reminderTimeOut) {
      clearTimeout(this.reminderTimeOut);
    }
    const secondsToReminder = this.calculateEndCallReminderTime(
      createdOn,
      maxCallLength
    );
    this.reminderTimeOut = setTimeout(() => {
      if (!this.isCallEnded) {
        this.showSnackbar();
      }
    }, secondsToReminder * 1000);
  };

  private showSnackbar = () => {
    if (!this.participants.length) return;
    this.snackbarRef = VoipReminderSnackbarComponent.openSnackbar(
      this.matSnackBar,
      {
        call: this.call,
      }
    );
  };

  public handleCurrentUserVideoMouseDown(event: MouseEvent) {
    // Only trigger on primary mouse button (usually the left button)
    if (event.buttons !== 1) return;

    event.preventDefault();

    this.currentUserVideoPositions.startX = event.clientX;
    this.currentUserVideoPositions.startY = event.clientY;

    document.addEventListener('mouseup', this.handleCurrentUserVideoMouseUp)
    document.addEventListener('mousemove', this.handleCurrentUserVideoMouseMove)
  }

  private handleCurrentUserVideoMouseMove = (event: MouseEvent) => {
    event.preventDefault();

    const deltaX = this.currentUserVideoPositions.startX - event.clientX;
    const deltaY = this.currentUserVideoPositions.startY - event.clientY;

    this.currentUserVideoPositions.startX = event.clientX;
    this.currentUserVideoPositions.startY = event.clientY;

    this.updateCurrentUserVideoPosition(deltaX, deltaY);
  }

  private updateCurrentUserVideoPosition(xOffset: number = 0, yOffset: number = 0) {
    const widgetWidth = this.currentUserVideoCanvasContainer.nativeElement.clientWidth;
    const widgetHeight = this.currentUserVideoCanvasContainer.nativeElement.clientHeight;
    const callHeaderHeight = this.callHeader.nativeElement.clientHeight;

    const clampedRight = clamp(this.currentUserVideoPositions.right + xOffset, 0, this.videoContainer.nativeElement.clientWidth - widgetWidth);
    const clampedBottom = clamp(this.currentUserVideoPositions.bottom + yOffset, 0, this.videoContainer.nativeElement.clientHeight - widgetHeight - callHeaderHeight);

    this.currentUserVideoPositions.right = clampedRight;
    this.currentUserVideoPositions.bottom = clampedBottom;
  }

  private handleCurrentUserVideoMouseUp = (event: MouseEvent) => {
    document.removeEventListener('mouseup', this.handleCurrentUserVideoMouseUp);
    document.removeEventListener('mousemove', this.handleCurrentUserVideoMouseMove);
  }
}
