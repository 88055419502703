import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { map, mapTo, share, switchMap } from "rxjs/operators";
import {
  ActionInvitationRequestOptions,
  InvitationModel,
  InvitationType,
} from "types";
import {
  InvitationAlreadyUsedData,
  InvitationAlreadyUsedDialogComponent,
  InvitationAlreadyUsedResult,
} from "../components/invitation-already-used-dialog/invitation-already-used-dialog.component";
import {
  ActionInvitationResponseModel,
  InvitationConfirmationType,
} from "./../../../../types/api-v2";
import {
  ActionUserInvitationDialogComponent,
  ActionUserInvitationDialogData,
  ActionUserInvitationDialogResult,
} from "./../components/action-user-invitation-dialog/action-user-invitation-dialog.component";
import {
  InvitationInactiveDialogComponent,
  InvitationInactiveDialogData,
  InvitationInactiveDialogResult,
} from "./../components/invitation-inactive-dialog/invitation-inactive-dialog.component";
import {
  InvitationInvalidDialogComponent,
  InvitationInvalidDialogData,
  InvitationInvalidDialogResult,
} from "./../components/invitation-invalid-dialog/invitation-invalid-dialog.component";
import { ApiService } from "./api.service";

const VALID_INVITATION_TYPES = [
  InvitationType.Case,
  InvitationType.ConnectionRequests,
  InvitationType.Group,
  InvitationType.User,
  InvitationType.UserSpecificWorkspaceJoin,
  InvitationType.WorkspaceCreate,
  InvitationType.WorkspaceJoin,
];

export interface ActionedInvitationModel extends InvitationModel {
  actionResponse?: ActionInvitationResponseModel;
}

@Injectable({
  providedIn: "root",
})
export class InvitationsService {
  public constructor(
    private apiService: ApiService,
    private matDialogService: MatDialog
  ) {}

  public getInvitation(invitationId: string): Observable<InvitationModel> {
    return this.apiService.get({
      path: `/api/v2/invitations/${invitationId}`,
    });
  }

  public actionInvitation({
    invitationId,
    ...body
  }: ActionInvitationRequestOptions): Observable<ActionInvitationResponseModel> {
    return this.apiService.post({
      path: `/api/v2/invitations/${invitationId}/act`,
      body,
    });
  }

  public openActionInvitationDialog(
    invitation: InvitationModel
  ): Observable<ActionedInvitationModel>;

  public openActionInvitationDialog(
    invitationCode: string
  ): Observable<ActionedInvitationModel>;

  public openActionInvitationDialog(
    invitationOrInvitationCode: string | InvitationModel
  ): Observable<ActionedInvitationModel> {
    const getInvitationObservable =
      typeof invitationOrInvitationCode === "string"
        ? this.getInvitation(invitationOrInvitationCode)
        : of(invitationOrInvitationCode);

    const observable = getInvitationObservable
      .pipe(
        switchMap((invitation) => {
          if (!this.isInvitationSupported(invitation)) {
            return this.openInvitationInvalidDialog()
              .afterClosed()
              .pipe(mapTo(invitation));
          }

          if (invitation.isActioned) {
            return this.openInvitationAlreadyUsedDialog({ invitation })
              .afterClosed()
              .pipe(mapTo(invitation));
          }

          if (!invitation.isActive) {
            return this.openInvitationInactiveDialog()
              .afterClosed()
              .pipe(mapTo(invitation));
          }

          return this.openActionInvitationDialogInternal({
            invitation,
          })
            .afterClosed()
            .pipe(
              switchMap((result) => {
                if (!invitation.id) throw new Error("Invalid invitation id");
                if (!result?.isConfirmed) return of(invitation);
                return this.actionInvitation({
                  invitationId: invitation.id,
                  isConfirmed: true,
                }).pipe(
                  switchMap((actionResponse) =>
                    of({
                      ...invitation,
                      actionResponse,
                    })
                  )
                );
              })
            );
        })
      )
      .pipe(share());

    observable.subscribe();

    return observable;
  }

  public isInvitationSupported(invitation: InvitationModel) {
    if (!invitation.type || !VALID_INVITATION_TYPES.includes(invitation.type))
      return false;

    if (
      invitation.type === InvitationType.UserSpecificWorkspaceJoin &&
      (!invitation.isConfirmationRequired ||
        invitation.confirmationType !== InvitationConfirmationType.Enterprise)
    ) {
      return false;
    }

    return true;
  }

  public openInvitationInvalidDialog() {
    const config: MatDialogConfig<InvitationInvalidDialogData> = {
      role: "dialog",
      restoreFocus: true,
    };
    return this.matDialogService.open<
      InvitationInvalidDialogComponent,
      InvitationInvalidDialogData,
      InvitationInvalidDialogResult
    >(InvitationInvalidDialogComponent, config);
  }

  public openInvitationAlreadyUsedDialog(data: InvitationAlreadyUsedData) {
    const config: MatDialogConfig<InvitationAlreadyUsedData> = {
      role: "dialog",
      restoreFocus: true,
      data,
    };
    return this.matDialogService.open<
      InvitationAlreadyUsedDialogComponent,
      InvitationAlreadyUsedData,
      InvitationAlreadyUsedResult
    >(InvitationAlreadyUsedDialogComponent, config);
  }

  public openInvitationInactiveDialog() {
    const config: MatDialogConfig<InvitationInactiveDialogData> = {
      role: "dialog",
      restoreFocus: true,
    };
    return this.matDialogService.open<
      InvitationInactiveDialogComponent,
      InvitationInactiveDialogData,
      InvitationInactiveDialogResult
    >(InvitationInactiveDialogComponent, config);
  }

  private openActionInvitationDialogInternal(
    data: ActionUserInvitationDialogData
  ) {
    const config: MatDialogConfig<ActionUserInvitationDialogData> = {
      role: "dialog",
      restoreFocus: true,
      maxWidth: "550px",
      data,
    };
    return this.matDialogService.open<
      ActionUserInvitationDialogComponent,
      ActionUserInvitationDialogData,
      ActionUserInvitationDialogResult
    >(ActionUserInvitationDialogComponent, config);
  }
}
