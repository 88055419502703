import { ConversationParticipantModelV2 } from "@types";

/**
 * Returns a string from concatenating `values` (non-string values are converted to a string using `toString`).
 * Values that are null, undefined, or have a length of zero are filtered out. The default seperator is a single space.
 */
export const concatNotNull = (
  values: (string | { toString: () => string } | undefined | null)[],
  seperator: string = " "
): string => {
  return values
    .filter((value) => value != null)
    .map((value) => (typeof value === "string" ? value : value.toString()))
    .filter((value) => value.length > 0)
    .join(seperator);
};

/**
 * Null-safe version of `string.localeCompare`. Items that are null are sorted to the end of the list.
 */
export const localeCompareIfNotNull = (
  a?: string | null,
  b?: string | null,
  localeCompareArgs?: {
    locales?: string | string[];
    options?: Intl.CollatorOptions;
  }
): number => {
  if (a && b)
    return a.localeCompare(
      b,
      localeCompareArgs?.locales,
      localeCompareArgs?.options
    );
  if (a && !b) return -1;
  if (b && !a) return 1;
  return 0;
};
