import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as XRegExp from "xregexp";
import { ExportConfirmationDialogComponent } from "../export-confirmation-dialog/export-confirmation-dialog.component";
import { IntegrationItem } from "../integrations-picker/integrations-picker.component";

export interface CustomDialogPayloadItem {
  type: "integrations" | "text" | "patientData" | string;
}

@Component({
  selector: "app-custom-dialog",
  templateUrl: "./custom-dialog.component.html",
  styleUrls: ["./custom-dialog.component.scss", "../../../../celo-input.scss"],
})
export class CustomDialogComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() payload: string | object | CustomDialogPayloadItem[];
  @Input() confirmOnly = false;
  @Input() alignment = "horizontal";
  hideCloseButton;
  disabled = false;
  confirmButtonText = "Confirm";
  cancelButtonText = "Cancel";
  tertiaryButtonText = "Cancel";
  result_data: any;
  result_type: string;
  myForm: UntypedFormGroup;
  nameRegex = XRegExp("^[\\p{L} ,.'-]*$");

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CustomDialogComponent>,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    let group = {};
    group = {
      // title: {value: '', disabled: false}
    };
    this.myForm = this.formBuilder.group(group);
  }

  editFieldChange(data) {
    this.result_data = this.result_data ? this.result_data : {};
    this.result_data[data.key] = data.value;
  }

  print(event) {}

  registerWorkspace(email) {
    this.dialogRef.close({ type: "register_workspace", email });
  }

  verifyLater(profession) {
    this.dialogRef.close({ type: "verify_later", payload: profession });
  }

  close() {
    this.dialogRef.close();
  }

  verifyProfession(profession) {
    this.dialogRef.close({ type: "verify_now", payload: profession });
  }

  removeProfession(profession) {
    this.dialogRef.close({ type: "remove_profession", payload: profession });
  }

  public submit() {
    const isIntegrationsDialog =
      Array.isArray(this.payload) &&
      this.payload.some((s) => s.type === "integrations");

    if (!isIntegrationsDialog) {
      this.closeWithData();
    }

    const { serviceCode, services } = this.result_data as IntegrationItem;
    const service = services?.find((s) => s.code === serviceCode);
    if (!service) {
      this.closeWithData();
      return;
    }

    const isConfirmationRequired =
      service.confirmation !== null && service.confirmation !== undefined;
    if (!isConfirmationRequired) {
      this.closeWithData();
      return;
    }

    const confirmation = service.confirmation;
    ExportConfirmationDialogComponent.openDialog(this.matDialog, {
      ...confirmation,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result?.isConfirmed) return;
          this.closeWithData();
        },
      });
  }

  private closeWithData() {
    this.dialogRef.close({
      type: this.result_type,
      payload: this.result_data,
    });
  }
}
