import { Component, OnInit, OnChanges, OnDestroy, Input } from "@angular/core";

@Component({
  selector: "app-celo-user-status",
  templateUrl: "./celo-user-status.component.html",
  styleUrls: ["./celo-user-status.component.scss"],
})
export class CeloUserStatusComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  userStatus: any;

  secondsBetween: number;
  minutesBetween: number;
  online: boolean;
  inMinutes: boolean;
  inDays: boolean;
  sub: number;

  ngOnInit() {
    const instance = this;
    this.sub = window.setInterval(function () {
      instance.setData();
    }, 5000);
  }

  ngOnChanges() {
    this.setData();
  }

  ngOnDestroy() {
    if (this.sub) {
      window.clearInterval(this.sub);
    }
  }

  setData() {
    if (!this.userStatus || !this.userStatus["updatedOnUtc"]) {
      return;
    }
    this.minutesBetween = 0;
    this.secondsBetween = 0;

    const date = new Date(this.userStatus["updatedOnUtc"]);
    const currantTime = new Date();
    const dif = currantTime.getTime() - date.getTime();
    const seconds = dif / 1000;
    this.secondsBetween = Math.abs(seconds);
    this.minutesBetween = Math.round(this.secondsBetween / 60);
    this.online = false;
    this.inMinutes = false;
    this.inDays = false;
    if (this.secondsBetween > 0 && this.secondsBetween <= 60) {
      this.online = true;
    } else if (this.secondsBetween > 60 && this.minutesBetween < 60) {
      this.inMinutes = true;
    } else if (this.minutesBetween >= 60) {
      this.inDays = true;
    }
  }
}
