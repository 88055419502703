import { Component, Host, HostBinding, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { SharedService } from "@modules/core";
import { filter } from "rxjs/operators";
import { LibraryService } from "./library.service";

@Component({
  selector: "app-library",
  templateUrl: "./library.component.html",
  styleUrls: ["./library.component.scss"],
})
export class LibraryComponent implements OnInit {
  @HostBinding("class.celo-grid") celoGrid: Host = true;
  isPhotosPage = false;
  isConsentsPage = false;
  isDocumentsPage = false;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private libraryService: LibraryService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((p: NavigationStart) => {
        this.isPhotosPage = false;
        this.isConsentsPage = false;
        this.isDocumentsPage = false;
        if (p.url === "/secure/photos" || p.url === "/secure") {
          this.isPhotosPage = true;
        } else if (p.url === "/secure/consents") {
          this.isConsentsPage = true;
        } else if (p.url === "/secure/documents") {
          this.isDocumentsPage = true;
        }
      });
  }

  ngOnInit() {
    this.sharedService.setTitle("Secure Library | Celo");
  }

  // upload() {
  //   if (this.isPhotosPage) {
  //     this.uploadService.openPhotoUpload().subscribe(result => {
  //       if (result.success) {
  //         this.libraryService.emitReloadPhoto(true)
  //       }
  //     })
  //   }
  //   else if (this.isDocumentsPage) {
  //     this.uploadService.openDocumentUpload().subscribe(result => {
  //       if (result.success) {
  //         this.libraryService.emitReloadDocument(true)
  //       }
  //     })
  //   }
  // }
}
