<div
  class="message-row highlight-row"
  [ngClass]="{
    highlight: message['highlight'],
    'first-of-group': isFirstOfGroup
  }"
>
  <div class="messages-margin" (dblclick)="handleDoubleClick()">
    <div
      class="message-set"
      [ngClass]="{
        'from-me': fromMe,
        'from-sender': fromSender,
        'from-system': fromSystem
      }"
    >
      <div *ngIf="fromSender && pMap[message.sentBy]">
        <div class="avatar">
          <app-avatar
            *ngIf="isFirstOfGroup"
            class="avatar-container"
            [title]="pMap[message.sentBy].title"
            [name]="
              pMap[message.sentBy].firstName +
              ' ' +
              pMap[message.sentBy].lastName
            "
            [name_identity_verified_as]="
              pMap[message.sentBy].legalFirstName +
              ' ' +
              pMap[message.sentBy].lastName
            "
            [userId]="pMap[message.sentBy].userId"
            [width]="40"
            [height]="40"
            [hasImage]="!!pMap[message.sentBy].profilePicture"
            [picUrl]="pMap[message.sentBy].profilePicture"
            [showBadge]="true"
            [workspaces]="pMap[message.sentBy].workplaces"
            [identityVerificationStatus]="
              pMap[message.sentBy].identityVerificationStatus
            "
            [marginRight]="5"
            [clickable]="!pMap[message.sentBy]?.isExternal"
            [user]="pMap[message.sentBy]"
            [isExternal]="pMap[message.sentBy]?.isExternal ?? false"
          >
          </app-avatar>
        </div>
      </div>
      <div class="my-card">
        <div
          class="sent-by"
          *ngIf="fromSender && isFirstOfGroup && pMap && pMap[message.sentBy]"
        >
          <div class="name">
            <div class="name-text">
              {{ pMap[message.sentBy].firstName }}
              {{ pMap[message.sentBy].lastName }}
            </div>
          </div>
        </div>

        <div
          class="flex items-center"
          [ngClass]="{ 'flex-row-reverse': fromMe }"
        >
          <app-message-card
            [userAccount]="userAccount"
            [message]="message"
            [pMap]="pMap"
            [isFirstOfGroup]="isFirstOfGroup"
            [searchQuery]="searchQuery"
            [conversationId]="conversationId"
            [patientId]="patientId"
            [fromMe]="fromMe"
            [fromSender]="fromSender"
            [fromSystem]="fromSystem"
            [conversationType]="conversationType"
            (mousedown)="mouseDown()"
            (mousemove)="mouseMove()"
            (click)="mouseUp()"
          >
          </app-message-card>

          <div *ngIf="!fromSystem && !message.deletedOnUtc" class="button-set">
            <button
              *ngIf="!isBlocked && isActiveParticipant"
              class="reply-button"
              mat-icon-button
              (click)="onReply()"
              matTooltip="Reply"
            >
              <i class="material-icons">reply</i>
            </button>

            <button
              *ngIf="message.type !== 'VideoCall'"
              class="reply-button"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              [matMenuTriggerRestoreFocus]="false"
              aria-label="More"
              matTooltip="More"
            >
              <mat-icon aria-hidden="false" aria-label="More"
                >more_horiz</mat-icon
              >
            </button>
            <mat-menu #menu="matMenu" yPosition="above" class="more-menu">
              <ng-template matMenuContent>
                <button
                  *ngIf="message.content"
                  mat-menu-item
                  class="primary"
                  (click)="onCopy()"
                >
                  <mat-icon color="primary">content_copy</mat-icon>
                  <span>Copy</span>
                </button>
                <button
                  *ngIf="isActiveParticipant"
                  mat-menu-item
                  class="primary"
                  (click)="onReply()"
                >
                  <mat-icon color="primary">reply</mat-icon>
                  <span>Reply</span>
                </button>
                <button mat-menu-item class="primary" (click)="onForward()">
                  <mat-icon color="primary" class="mirror-horizontal"
                    >reply</mat-icon
                  >
                  <span>Forward</span>
                </button>
                <button
                  *ngIf="
                    isActiveParticipant &&
                    !message.type &&
                    userAccount.userId === message.sentBy
                  "
                  mat-menu-item
                  class="warn"
                  (click)="onDelete()"
                >
                  <mat-icon color="warn">delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
          </div>
        </div>

        <div *ngIf="message.sentBy === userAccount.userId" class="status">
          <app-message-status-preview-bar
            class="celo-cursor-pointer"
            [message]="message"
            [userAccount]="userAccount"
            [pMap]="pMap"
            [refreshCount]="message.refreshCount"
            (click)="selectMessage.emit(message)"
          >
          </app-message-status-preview-bar>
        </div>
      </div>
    </div>
  </div>
</div>
