/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateCallResponse {
  call?: Call;
  participant?: CallParticipant;
  token?: string;
  sessionName?: string;
}

export interface Call {
  id?: string;
  conversationId?: string;
  createdBy?: string;
  /** @format date-time */
  createdOn?: string;
  status?: VideoCallStatus;
  /**
   * Max call length in minutes
   * @format int32
   */
  maxCallLength?: number;
  /**
   * Max ring length in seconds
   * @format int32
   */
  maxRingLength?: number;
  /** @format date-time */
  startedOn?: string | null;
  /** @format date-time */
  endedOn?: string | null;
  /** `true` if this call is open in a call window, otherwise `false`. */
  isOpen?: boolean | null;
}

export enum VideoCallStatus {
  InProgress = "InProgress",
  Ended = "Ended",
}

export interface CallParticipant {
  id?: string;
  /** Identity of this participant in Zoom's system, aka `user_identity` or `userIdentity` */
  userKey?: string;
  userId?: string;
  callId?: string;
  /** @format date-time */
  joinedOn?: string | null;
  /** @format date-time */
  leftOn?: string | null;
}

export interface ErrorResponse {
  errors?: ErrorDetail[];
}

export interface ErrorDetail {
  code?: string;
  message?: string;
}

export interface TokenPagedResultOfCall {
  token?: string;
  data?: Call[];
  /** @format date-time */
  pageProcessedOn?: string;
}

export interface JoinCallResponse {
  call?: Call;
  participant?: CallParticipant;
  token?: string;
  sessionName?: string;
}

export interface EndCallRequest {
  /** @format date-time */
  endOn?: string;
}

export interface TokenPagedResultOfCallParticipant {
  token?: string;
  data?: CallParticipant[];
  /** @format date-time */
  pageProcessedOn?: string;
}

export type LeaveCallResponse = CallParticipant & object;
