import { Injectable } from "@angular/core";
import { config } from "configurations/config";
import { environment } from "environments/environment";
import {
  FileItem,
  FileLikeObject,
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders,
} from "ng2-file-upload";
import { AlertService } from "../old/alert.service";
import { AuthService } from "../services/auth.service";
import { SharedService } from "../old/shared.service";

interface PhotoFile {
  Description?: string;
  IsImported?: boolean;
  DateTime?: string;
  FileName?: string;
}
interface DocumentFile {
  fileDescription?: string;
  IsImported?: boolean;
  DateTime?: string;
  fileName?: string;
}

/**
 * Actual model for `FileItems` in `FileUploader`.
 *
 * These fields are added by `FileUploadService` in `onAfterAddingFile`.
 *
 * The `description` field is currently set by `FileUploadPreviewComponent`.
 *
 * #TODO refactor file upload to make things clearer
 */
export interface UploadServiceFileItem extends FileItem {
  /** @deprecated */
  description?: string;
  /** @deprecated */
  filename: string;
}

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  errored = false;
  type = "Photos";
  targetConversationId = "";
  allowPatient: false;
  limit: number;
  patient: any = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  uploadedFiles: Array<any> = [];
  payload: any;
  public uploader: FileUploader;
  uploaderQueueItems: { fileItem: FileItem; description: string }[] = [];
  supportedImageFormats: any = ["image/jpg", "image/jpeg", "image/png"];

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private alertService: AlertService
  ) {}

  initialize(configData, callback) {
    this.uploadedFiles = [];
    this.errored = false;
    const data = configData;
    this.type = data.type;
    this.targetConversationId = data.conversationId;
    this.allowPatient = data.allowPatient ? data.allowPatient : false;
    this.limit = data.queueLimit ? data.queueLimit : 1;
    this.payload = data.payload;
    const method = data.method ? data.method : "post";
    this.uploader = new FileUploader({
      url: data.url,
      queueLimit: this.limit,
      method,
    });

    const options: FileUploaderOptions = {
      url: data.url,
      headers: [],
    };
    options.headers.push({
      name: "CeloAuthorization",
      value: environment.celoAuth,
    });
    options.headers.push({
      name: "celo-region",
      value: this.authService.getDataRegion(),
    });
    options.headers.push({ name: "AppVersion", value: environment.appVersion });
    this.uploader.setOptions(options);
    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onCompleteAll = this.onCompleteUpload.bind(
      this,
      callback,
      data.conversationId
    );
    // this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    // this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);

    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      if (fileItem.file.type.startsWith("application/pdf")) {
        const data = {
          fileNameAlias: fileItem.filename,
          fileDescription: fileItem.description,
          isImported: true,
          patientData: this.patient,
        };
        form.append("data", JSON.stringify(data));
      }
    };
    this.uploader.onBeforeUploadItem = (fileItem: FileItem): any => {
      let additionalParameters =
        fileItem["options"]["additionalParameter"]["data"];
      const additionalParametersJosn = JSON.parse(additionalParameters);
      // additionalParametersJosn['description'] = fileItem['description'].trim();
      additionalParametersJosn["description"] = this.sharedService.isValidInput(
        fileItem["description"]
      )
        ? fileItem["description"]
        : "";
      additionalParametersJosn["filename"] = fileItem["filename"];
      additionalParameters = JSON.stringify(additionalParametersJosn);
      fileItem["options"]["additionalParameter"]["data"] = additionalParameters;
      if (fileItem.file.type.startsWith("application/pdf")) {
        fileItem.url = `${environment.celoApiEndpoint}/api/patientfiles/`;
      } else if (fileItem.file.type.startsWith("image/")) {
        fileItem.url = `${environment.celoApiEndpoint}/api/v2/media`;
      }

      return { fileItem };
    };
  }

  isPhoto(fileItem: FileItem) {
    if (
      fileItem.file &&
      fileItem.file.type &&
      fileItem.file.type.startsWith("image/")
    ) {
      return true;
    }
    return false;
  }

  isDocument(fileItem: FileItem) {
    if (
      fileItem.file &&
      fileItem.file.type &&
      fileItem.file.type.startsWith("application/pdf")
    ) {
      return true;
    }
    return false;
  }

  public onCompleteUpload(callback, targetConversationId?) {
    if (this.uploader.queue.filter((f) => f.isError).length == 0) {
      this.uploader.clearQueue();
      callback({
        success: true,
        uploadedFiles: this.uploadedFiles,
        conversationId: targetConversationId,
      });
    }
  }

  checkValidFormat(file: FileLikeObject, validTypes: string[]) {
    let hit = false;
    validTypes.forEach((type) => {
      if (file.type.indexOf(type) != -1) {
        hit = true;
        return true;
      }
    });
    return hit;
    // return (file.type.indexOf(validTypes)==-1)?false:true;
  }

  public onAfterAddingFile(item: FileItem) {
    // for some reason, pics which are saved by browser(right click -> save image)
    // wont upload. Renaming it works
    if (this.uploader.queue.length > config.max_file_upload_limit) {
      this.alertService.showSnackBar(
        "Import limit reached. You can only import 20 files at a time.",
        4
      );
      // remove the exceeding file
      this.uploader.removeFromQueue(item);
    }
    const newname = "" + item.file.name;
    item.file.name = "celo_" + newname;
    item["filename"] = newname.substr(0, newname.lastIndexOf("."));
    this.fileSelected();
    let validTypes = [];
    let fileType = "";
    fileType = item.file.type;

    if (this.type === "Photos") {
      validTypes = validTypes.concat(this.supportedImageFormats);
    } else if (this.type === "Documents") {
      validTypes.push("application/pdf");
    } else if (this.type === "Multi") {
      validTypes.push("application/pdf");
      // validTypes.push("image/")
      validTypes = validTypes.concat(this.supportedImageFormats);
    }
    const valid = this.checkValidFormat(item.file, validTypes);
    if (!valid) {
      this.uploader.removeFromQueue(item);
      let errorMessage =
        "At this time we do not support direct upload of some of the files in your selection.";
      if (this.checkValidFormat(item.file, ["application/pdf"])) {
        errorMessage +=
          " Please upload your PDF documents in Secure Library → Documents.";
      }
      if (
        this.checkValidFormat(item.file, ["image/jpeg"]) ||
        this.checkValidFormat(item.file, ["image/png"]) ||
        this.checkValidFormat(item.file, ["image/jpg"])
      ) {
        errorMessage +=
          " Please upload your photos in Secure Library → Photos.";
      }
      this.alertService.alert("Unsupported File", errorMessage);
      return;
    }
    let maxSize = 1920;
    if (this.sharedService.getImageQuality()) {
      maxSize = 4096;
    }

    if (fileType.startsWith("image/")) {
      const options: IResizeImageOptions = {
        file: item._file,
        maxSize,
        minSize: 1,
      };

      resizeImage(options).then(
        (file1920: File) => {
          item._file = file1920;
        },
        (err) => {
          this.uploader.removeFromQueue(item);
          let errorMessage = `All photos must be at least ${options.minSize}x${options.minSize} pixels.`;
          // let errorMessage="At this time we do not support direct upload of photos smaller than 600x600 pixels";
          errorMessage +=
            this.uploader.queue.length <= 0
              ? ""
              : " Unsupported photos are removed from the selection.";
          this.alertService.alert("Unsupported photos", errorMessage);
          return;
        }
      );
    }
  }

  private onSuccessItem(
    fileItem: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ) {
    let responseObj = JSON.parse(response);
    if (!responseObj[0]) {
      responseObj = [responseObj];
    }
    for (const item of responseObj) {
      let type = "";
      if (fileItem.file.type.startsWith("application/pdf")) {
        type = "Document";
      } else if (fileItem.file.type.startsWith("image/")) {
        type = "Photo";
      }
      if (!this.uploadedFiles.map((file) => file.id).includes(item.id)) {
        this.uploadedFiles.push({
          id: item.id,
          type,
          fileName: item.fileName,
          fileDescription:
            type == "Document" ? item.fileDescription : item.description,
        });
      }
    }
  }

  removeDuplicates() {
    const foundFiles = {};
    const duplicates: FileItem[] = [];
    for (const fileItem of this.uploader.queue) {
      const name = fileItem.file.name;
      const size = fileItem.file.size;
      if (foundFiles[name] && foundFiles[name] === size) {
        duplicates.push(fileItem);
      }
      foundFiles[name] = size;
    }
    for (const fileItem of duplicates) {
      fileItem.remove();
    }
  }

  fileSelected() {
    this.removeDuplicates();
    this.uploaderQueueItems = this.uploader.queue.map((fileItem) => ({
      fileItem,
      description: "",
    }));
  }

  private onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ) {
    const resp = JSON.parse(response);
    let message = "Error uploading file";
    message = resp.uid && resp.uid[0] ? resp.uid[0] : message;
    if (!this.errored) {
      this.errored = true;
      setTimeout(() => {
        this.alertService.alert("Error", message, true);
        this.errored = false;
      }, 2000);
    }
  }

  public upload() {
    this.uploadedFiles = [];

    if (this.uploader.isUploading) {
      return;
    }
    if (this.uploader.queue.length == 0) {
      return;
    }
    let data = null;
    if (
      this.type === "Photos" ||
      this.type === "Documents" ||
      this.type === "Multi"
    ) {
      data = {
        patientData: this.patient,
        isImported: true,
        consentId: undefined,
      };
    } else if (this.type === "Profile Picture") {
      data = this.payload;
    }

    if (this.type === "Photos" || this.type === "Multi") {
      const files: PhotoFile[] = this.uploader.queue.map((fi: FileItem) => {
        let desc = "";
        const fileName = "";
        for (const item of this.uploader.queue) {
          if (item === fi) {
            desc = item["description"];
          }
        }
        // if(fi.file.type.startsWith('image/')){
        //   return {
        //     IsImported: true,
        //     FileName: fi.file.name,
        //     Description: desc
        //   }
        // }else
        if (fi.file.type.startsWith("application/pdf")) {
          return {
            IsImported: true,
            fileName: "fileName",
            fileNameAlias: "fileNameAlias",
            fileDescription: "fileDescription",
          };
        }
      });
      data.files = files;
    }
    const ap = {
      data: JSON.stringify(data),
    };
    const options: FileUploaderOptions = {
      additionalParameter: ap,
      url: ""
    };
    this.uploader.setOptions(options);
    this.uploader.authToken = `Bearer ${this.authService.getAccessToken()}`;
    const failedItems = this.uploader.queue.filter((i) => i.isError);
    if (failedItems.length > 0) {
      for (const item of failedItems) {
        this.uploader.uploadItem(item);
      }
    } else {
      this.uploader.uploadAll();
    }
  }
}

interface IResizeImageOptions {
  maxSize: number;
  file: File;
  minSize: number;
}

const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");
  const dataURItoBlob = (dataURI: string) => {
    const bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : (<any>window).unescape(dataURI.split(",")[1]);
    const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ia], { type: mime });
  };
  const resize = () => {
    let width = image.width;
    let height = image.height;
    if (width < settings.minSize || height < settings.minSize) {
      return;
    }

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL("image/jpeg");
    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => {
        const x = resize();
        if (x) {
          ok(x);
        } else {
          no("Image too small");
        }
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};
