import { CommonModule, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { environment } from "@env";
import { VoipService } from "@modules/core/services/voip.service";
import { LibraryModule } from "@modules/library/library.module";
import { ProfileModule } from "@modules/profile/profile.module";
import { RolesModule } from "@modules/roles/roles.module";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { FileUploadModule } from "ng2-file-upload";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { AvatarModule } from "ngx-avatars";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BroadcastDashboardComponent } from "./broadcast/broadcast-dashboard/broadcast-dashboard.component";
import { BroadcastComponent } from "./broadcast/broadcast/broadcast.component";
import { CarouselItemDirective } from "./carousel/carousel-item/carousel-item.directive";
import { CarouselComponent } from "./carousel/carousel.component";
import { CompanyErrorComponent } from "./company-error/company-error.component";
import { DepartmentListComponent } from "./directory/department-list/department-list.component";
import { ConnectionsListComponent } from "./directory/network/connections-list/connections-list.component";
import { ContactComponent } from "./directory/network/contact/contact.component";
import { NetworkComponent } from "./directory/network/network/network.component";
import { WorkspaceCreateRoleButtonComponent } from "./directory/network/workspace-create-role-button/workspace-create-role-button.component";
import { WorkspaceEmptyStateComponent } from "./directory/network/workspace-empty-state/workspace-empty-state.component";
import { WorkspaceListTitleComponent } from "./directory/network/workspace-list-title/workspace-list-title.component";
import { WorkspaceListComponent } from "./directory/network/workspace-list/workspace-list.component";
import { WorkspaceComponent } from "./directory/network/workspace/workspace.component";
import { SearchNetworkComponent } from "./directory/search-network/search-network.component";
import { HomeComponent } from "./home/home.component";
import { LoggedInOidcComponent } from "./logged-in-oidc/logged-in-oidc.component";
import { SetPasswordComponent } from "./login-screen/set-password/set-password.component";
import { LoginComponent } from "./login/login.component";
import { LogoutOidcComponent } from "./logout-oidc/logout-oidc.component";
import { AdminSettingsComponent } from "./messages/admin-settings/admin-settings.component";
import { MessageForwardedLabelComponent } from "./messages/components/message-forwarded-label/message-forwarded-label.component";
import { MessagesListComponent } from "./messages/components/messages-list/messages-list.component";
import { MessagesRowComponent } from "./messages/components/messages-row/messages-row.component";
import { MessageCardComponent } from "./messages/message-card/message-card.component";
import { MessageComposeV2Component } from "./messages/message-compose-v2/message-compose-v2.component";
import { MessageDetailComponent } from "./messages/message-detail/message-detail.component";
import { MessageInputComponent } from "./messages/message-input/message-input.component";
import { MessageStatusPreviewBarComponent } from "./messages/message-status-preview-bar/message-status-preview-bar.component";
import { MessageStatusComponent } from "./messages/message-status/message-status.component";
import { MessagesBottomTextBarComponent } from "./messages/messages-bottom-text-bar/messages-bottom-text-bar.component";
import { MessagesCallBarComponent } from "./messages/messages-call-bar/messages-call-bar.component";
import { MessagesComposeComponent } from "./messages/messages-compose/messages-compose.component";
import { MessagesSubjectLineComponent } from "./messages/messages-subject-line/messages-subject-line.component";
import { MessagesComponent } from "./messages/messages.component";
import { SearchComponent } from "./messages/search/search.component";
import { ConversationsModule } from "./modules/conversations/conversations.module";
import { CoreModule } from "./modules/core/core.module";
import { NotificationsModule } from "./modules/notifications/notifications.module";
import { UrlHelperService } from "./modules/shared/auth.img.service";
import { SharedModule } from "./modules/shared/shared.module";
import { UserPickerModule } from "./modules/user-picker/user-picker.module";
import { NavTopComponent } from "./nav-top/nav-top.component";
import { CountryFailComponent } from "./onboard/country-fail/country-fail.component";
import { CountryComponent } from "./onboard/country/country.component";
import { EmailConfirmComponent } from "./onboard/email-confirm/email-confirm.component";
import { EmailComponent } from "./onboard/email/email.component";
import { OnboardComponent } from "./onboard/onboard/onboard.component";
import { PasswordSetComponent } from "./onboard/password-set/password-set.component";
import { ProfileCreateComponent } from "./onboard/profile-create/profile-create.component";
import { WelcomeComponent } from "./onboard/welcome/welcome.component";
import { WorkspaceAddComponent } from "./onboard/workspace-add/workspace-add.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PinscreenComponent } from "./pinscreen/pinscreen.component";
import { PinscreenService } from "./pinscreen/pinscreen.service";
import { SearchHighlightPipe } from "./pipes/search-highlight.pipe";
import { EditProfessionComponent } from "./profession/edit-profession/edit-profession.component";
import { VerifyProfessionSuccessComponent } from "./profession/verify-profession-success/verify-profession-success.component";
import { VerifyProfessionComponent } from "./profession/verify-profession/verify-profession.component";
import { EditComponent } from "./profile/edit/edit.component";
import { AdminDashboardComponent } from "./roles/admin-dashboard/admin-dashboard.component";
import { DepartmentMembersListComponent } from "./roles/department-members-list/department-members-list.component";
import { RolesPanelComponent } from "./roles/roles-panel/roles-panel.component";
import { SettingsComponent } from "./settings/settings.component";
import { NetworkNoResultsComponent } from "./svg/network-no-results/network-no-results.component";
import { CallComponent } from "./voip/call/call.component";
import { VoipAvatarComponent } from "./voip/voip-avatar/voip-avatar.component";
import { VoipButtonsComponent } from "./voip/voip-buttons/voip-buttons.component";
import { VoipIncomingCallDialogComponent } from "./voip/voip-incoming-call-dialog/voip-incoming-call-dialog.component";
import { VoipOverlayComponent } from "./voip/voip-overlay/voip-overlay.component";
import { VoipReminderSnackbarComponent } from "./voip/voip-reminder-snackbar/voip-reminder-snackbar.component";
import { AddEmailConfirmComponent } from "./workspace/add/add-email-confirm/add-email-confirm.component";
import { AddWorkspaceDetailsComponent } from "./workspace/add/add-workspace-details/add-workspace-details.component";
import { AddComponent } from "./workspace/add/add.component";
import { CreateWorkspaceComponent } from "./workspace/create-workspace/create-workspace.component";
import { WorkspaceDetailsComponent } from "./workspace/workspace-details/workspace-details.component";
import { WorkspaceMembersComponent } from "./workspace/workspace-members/workspace-members.component";
import { VoipConversationAvatarComponent } from "./voip/voip-conversation-avatar/voip-conversation-avatar.component";
import { SearchMessageCardComponent } from "./messages/search-message-card/search-message-card.component";
import { BroadcastStatusComponent } from './broadcast/broadcast-status/broadcast-status.component';
import { ScrollingModule } from "@angular/cdk/scrolling";

@NgModule({
  declarations: [
    PinscreenComponent,
    AppComponent,
    NavTopComponent,
    PageNotFoundComponent,
    MessagesComponent,
    MessageDetailComponent,
    DepartmentListComponent,
    SettingsComponent,
    MessagesSubjectLineComponent,
    MessagesBottomTextBarComponent,
    MessageStatusComponent,
    MessageCardComponent,
    MessageStatusPreviewBarComponent,
    AdminDashboardComponent,
    RolesPanelComponent,
    DepartmentMembersListComponent,
    SearchComponent,
    SearchMessageCardComponent,
    SearchHighlightPipe,
    AdminSettingsComponent,
    BroadcastDashboardComponent,
    BroadcastComponent,
    SetPasswordComponent,
    AddComponent,
    AddEmailConfirmComponent,
    AddWorkspaceDetailsComponent,
    MessagesListComponent,
    MessagesRowComponent,
    LogoutOidcComponent,
    LoggedInOidcComponent,
    HomeComponent,
    OnboardComponent,
    CountryComponent,
    EmailComponent,
    EmailConfirmComponent,
    WorkspaceAddComponent,
    ProfileCreateComponent,
    WelcomeComponent,
    CountryFailComponent,
    PasswordSetComponent,
    LoginComponent,
    SearchNetworkComponent,
    EditProfessionComponent,
    VerifyProfessionComponent,
    VerifyProfessionSuccessComponent,
    WorkspaceDetailsComponent,
    CreateWorkspaceComponent,
    WorkspaceMembersComponent,
    CarouselComponent,
    CarouselItemDirective,
    NetworkComponent,
    WorkspaceListComponent,
    WorkspaceComponent,
    ContactComponent,
    WorkspaceEmptyStateComponent,
    LogoutOidcComponent,
    LoggedInOidcComponent,
    HomeComponent,
    OnboardComponent,
    CountryComponent,
    EmailComponent,
    EmailConfirmComponent,
    WorkspaceAddComponent,
    ProfileCreateComponent,
    WelcomeComponent,
    CountryFailComponent,
    PasswordSetComponent,
    LoginComponent,
    SearchNetworkComponent,
    EditProfessionComponent,
    VerifyProfessionComponent,
    VerifyProfessionSuccessComponent,
    WorkspaceDetailsComponent,
    CreateWorkspaceComponent,
    WorkspaceMembersComponent,
    CarouselComponent,
    CarouselItemDirective,
    NetworkNoResultsComponent,
    MessagesComposeComponent,
    EditComponent,
    WorkspaceListTitleComponent,
    WorkspaceCreateRoleButtonComponent,
    ConnectionsListComponent,
    MessageForwardedLabelComponent,
    VoipAvatarComponent,
    CallComponent,
    MessagesCallBarComponent,
    VoipButtonsComponent,
    VoipOverlayComponent,
    VoipIncomingCallDialogComponent,
    VoipReminderSnackbarComponent,
    CompanyErrorComponent,
    MessageInputComponent,
    MessageComposeV2Component,
    VoipConversationAvatarComponent,
    BroadcastStatusComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AvatarModule,
    FileUploadModule,
    InfiniteScrollModule,
    OAuthModule.forRoot(),
    PickerModule,
    OAuthModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    SharedModule,
    NotificationsModule,
    ProfileModule,
    CoreModule,
    UserPickerModule, // Should be lazy loaded, but this currently contains shared components/services
    RolesModule, // Should be lazy loaded, but this currently contains shared components/services
    LibraryModule, // Should be lazy loaded, but this currently contains shared components/services,
    ConversationsModule, // Should be lazy loaded, but this currently contains shared components/services,
    ScrollingModule
  ],
  providers: [
    UrlHelperService,
    PinscreenService,
    DatePipe,
    {
      provide: OAuthStorage,
      useFactory: () => localStorage,
    },
    VoipService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { }
}
