import {
  ConversationModelV2,
  ConversationParticipantModelV2,
  ConversationType,
} from "@types";
import { concatNotNull } from "./string-utils";

export const getConversationName = (
  conversation: ConversationModelV2,
  currentUserId: string
): string => {
  if (conversation.type !== ConversationType.Chat) return conversation.name;

  const otherParticipant = conversation.participants.find(
    (p) => p.userId !== currentUserId
  );

  if (!otherParticipant) throw new Error("Missing other participant");

  const conversationName = concatNotNull([
    otherParticipant.firstName,
    otherParticipant.lastName,
  ]);

  return conversationName;
};

export const getParticipantFullName = (
  participant: ConversationParticipantModelV2
): string => {
  return `${participant.firstName} ${participant.lastName}`;
};
