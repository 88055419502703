import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertComponent } from "@modules/core/components";
import { NetworkUserPickerMode } from "@modules/user-picker/network-user-picker.service";
import { UserPickerService } from "@modules/user-picker/user-picker.service";
import { config } from "configurations/config";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  ConversationModelV2,
  FullUserProfileModel,
  Team,
  TeamMember,
} from "types";
import { dateCompareIfNotNull, sorted } from "utils";
import { RoleDetailsDialogComponent } from "../role-details-dialog/role-details-dialog.component";
import { RolesClockOutComponent } from "../roles-clock-out/roles-clock-out.component";
import {
  AccountService,
  ConversationModelV2WithMetadata,
  ConversationsService,
  SnackbarService,
  TeamsService,
} from "./../../core";

@Component({
  selector: "app-roles-home-sidenav",
  templateUrl: "./roles-home-sidenav.component.html",
  styleUrls: ["./roles-home-sidenav.component.scss"],
})
export class RolesHomeSidenavComponent implements OnInit, OnChanges {
  @Input() public team: Team | null = null;
  @Input() public conversationsPageSize: number = 20;

  private conversationsPageNumber: number = 0;
  public conversationsHasNext: boolean = true;
  public conversations$: Observable<
    ConversationModelV2WithMetadata[] | null
  > | null = null;

  public userAccount$: Observable<FullUserProfileModel | null> | null = null;

  public isSearching: boolean = false;
  public query: string | null = null;
  private searchPageNumber: number = 0;
  public searchHasNext: boolean = true;
  public searchResults$: Observable<
    ConversationModelV2WithMetadata[] | null
  > | null = null;

  public isInitialConversationsLoading: boolean = true;
  public isConversationsLoading: boolean = true;
  public isUserAccountLoading: boolean = true;
  public isInitialSearchLoading: boolean = false;
  public isSearchLoading: boolean = false;

  public memberOnCall: TeamMember | null = null;
  public isCurrentUserOnCall: boolean = false;

  private previousTeamId: string | null = null;

  public constructor(
    private conversationsService: ConversationsService,
    private accountService: AccountService,
    private teamsService: TeamsService,
    private router: Router,
    private snackbarService: SnackbarService,
    private userPickerService: UserPickerService,
    private matDialog: MatDialog
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const team: Team | null = changes?.team.currentValue ?? null;
    if (!team) return;

    if (!this.teamsService.isCurrentUserMember(team)) {
      this.router.navigate(["conversations"]);
      return;
    }

    this.updateMemberOnCall();

    if (team?.id && team?.id !== this.previousTeamId) {
      this.previousTeamId = team.id;
      this.loadInitialConversations();
    }
  }

  public ngOnInit(): void {
    this.conversations$ = this.conversationsService.conversations$.pipe(
      tap((value) => (this.isInitialConversationsLoading = value === null)),
      map((value) => {
        if (!value || !this.team?.id) return [];
        const currentUserTeamChats =
          this.conversationsService.filterCurrentUserTeamChatsById(
            value,
            this.team.id
          );

        const conversationsWithMessages = currentUserTeamChats.filter(
          (c) => c.lastMessage != null
        );

        const sortedTeamChats = sorted(conversationsWithMessages, (a, b) => {
          const dateA = this.getConversationSortDate(a);
          const dateB = this.getConversationSortDate(b);
          return dateCompareIfNotNull(dateA, dateB);
        });

        return sortedTeamChats;
      })
    );
    this.searchResults$ = this.conversationsService.conversations$.pipe(
      tap((value) => (this.isInitialSearchLoading = value === null)),
      map((value) => {
        if (!this.isSearching || !this.query) return [];
        if (!value || !this.team?.id) return [];
        return (
          this.conversationsService
            .filterCurrentUserTeamChatsById(value, this.team.id)
            .filter(
              (c) => c.conversationServiceMetadata?.query === this.query
            ) ?? []
        );
      })
    );
    this.userAccount$ = this.accountService.userAccount$.pipe(
      tap((value) => {
        this.isUserAccountLoading = value === null;
        this.updateMemberOnCall();
      })
    );
  }

  private getConversationSortDate(conversation: ConversationModelV2) {
    return (
      conversation.lastMessage?.sentOnUtc ??
      conversation.lastMessage?.createdOnUtc ??
      conversation.lastModifiedOnUtc ??
      conversation.createdOnUtc ??
      null
    );
  }

  private updateMemberOnCall() {
    if (!this.team) {
      this.memberOnCall = null;
      this.isCurrentUserOnCall = false;
      return;
    }
    this.memberOnCall = this.teamsService.findFirstMemberOnCall(this.team);
    this.isCurrentUserOnCall = this.teamsService.isCurrentUserOnCall(this.team);
  }

  private loadInitialConversations() {
    this.isInitialConversationsLoading = true;
    this.conversationsPageNumber = 0;
    this.conversationsHasNext = true;
    this.loadConversationsPage();
  }

  private loadConversationsPage() {
    if (!this.team?.id) throw new Error("Invalid team id");

    if (this.isSearching) {
      this.isSearchLoading = true;
    } else {
      this.isConversationsLoading = true;
    }

    this.conversationsService
      .getTeamConversations({
        page: this.isSearching
          ? this.searchPageNumber
          : this.conversationsPageNumber,
        pageSize: this.conversationsPageSize,
        teamId: this.team.id,
        search: this.isSearching ? this.query ?? undefined : undefined,
      })
      .subscribe({
        next: (p) => {
          if (this.isSearching) {
            this.isSearchLoading = false;
            this.searchPageNumber = p.page ?? this.searchPageNumber;
            this.searchHasNext = p.hasNext ?? false;
          } else {
            this.isConversationsLoading = false;
            this.conversationsPageNumber =
              p.page ?? this.conversationsPageNumber;
            this.conversationsHasNext = p.hasNext ?? false;
          }
        },
      });
  }

  public handleSearch(query: string) {
    if (query.length >= config.minSearchLength) {
      this.isSearching = true;
      this.query = query;
      this.isInitialSearchLoading = true;
      this.searchPageNumber = 0;
      this.searchHasNext = true;
      this.loadConversationsPage();
    } else {
      // Only trigger if user has input anything
      if (query.length) {
        this.snackbarService.show(
          `Please enter at least ${config.minSearchLength} characters to begin your search.`
        );
      }
      this.isSearching = false;
      this.query = null;
    }
  }

  public handleRoleDetailsClicked() {
    if (!this.team || !this.team.workspace?.id) return;
    RoleDetailsDialogComponent.openDialog(this.matDialog, {
      team: this.team,
      workspaceId: this.team.workspace.id,
      hidePrimaryAction: true,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result?.isLeft) return;
          this.router.navigate(["conversations"]);
        },
      });
  }

  public handleClockInOrOut() {
    if (!this.team?.id) return;

    const otherMembersCount = this.teamsService.filterOutLeftTeamMembers(
      this.team.members ?? [],
      true
    ).length;

    if (this.isCurrentUserOnCall) {
      if (otherMembersCount > 0) {
        // If there are any other members, ask if user wants to clock in someone to replace them
        RolesClockOutComponent.openDialog(this.matDialog, {
          team: this.team,
        });
      } else {
        // If there are no other members immediately clock this user out without any prompts
        this.teamsService.clockOut(this.team.id).subscribe();
      }
    } else if (this.memberOnCall) {
      // Confirm user wants to take over for the currently clocked in user
      const name = this.memberOnCall.user?.firstName;
      AlertComponent.openDialog(this.matDialog, {
        title: "Clock in for this role",
        message: `${
          name ?? "Someone"
        } is currently on call for this role. Would you like to take over from ${
          name ?? "them"
        }?`,
        acceptButtonText: "Clock in",
        acceptOnly: false,
        reverseButtonOrder: true,
      })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (!this.team) return;
            if (!isConfirmed || !this.team.id) return;
            this.teamsService.clockIn(this.team.id).subscribe();
          },
        });
    } else {
      // No one is clocked in so clock this user in without any prompts
      this.teamsService.clockIn(this.team.id).subscribe();
    }
  }

  public handleLoadMoreConversations() {
    if (this.isSearching) {
      this.searchPageNumber += 1;
    } else {
      this.conversationsPageNumber += 1;
    }
    this.loadConversationsPage();
  }

  public handleCreateChat() {
    const workspaceId = this.team?.workspace?.id;
    if (!workspaceId) throw new Error("Team has no workspace id");

    this.userPickerService.openNetworkUserPicker({
      header: "New Chat",
      selectedHeader: "Participants",
      selectedQuantityLabels: {
        one: "participant",
        plural: "participants",
        zero: "participants",
      },
      searchPlaceholder: "Search",
      mode: NetworkUserPickerMode.CREATE,
      excludeTeams: true,
      excludeConnections: true,
      excludeSuggestions: true,
      workspaceId: workspaceId,
      excludeSelf: true,
      initiallyExpandedWorkspaceIds: [workspaceId],
      asTeamId: this.team?.id,
    });
  }
}
