import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { environment } from "../environments/environment";
import {
  ConversationService,
  SharedService,
  UserAccountService,
} from "./modules/core";

@Injectable()
export class ConversationResolver  {
  // userId: string;
  constructor(
    private router: Router,
    private conversationService: ConversationService,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private http: HttpClient
  ) {
    // this.init();
  }
  // init = () => {
  //   this.userAccountService.accountSubject.subscribe(data=>{
  //     this.userId = data.userId;
  //   })
  // }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // let conversation;
    // if(this.conversationService.conversations.length>0){
    //   for (let index = 0; index < this.conversationService.conversations.length; index++) {
    //     const convo = this.conversationService.conversations[index];
    //     if(convo.id==route.params.id){
    //           conversation = convo;
    //           break;
    //     }
    //   }
    // }
    // if(conversation&&conversation.id==route.params.id){
    //   return conversation;
    // }

    const path = `${environment.celoApiEndpoint}/api/v2/conversations/${route.params.id}`;
    return this.sharedService.getObjectById(path);
    // return this.http.get<any>(path)
    // .pipe(
    //   retry(3),
    //   catchError((error) => {
    //     let chatId = route.params.id;
    //     let participants = chatId.split('_');
    //     if(!participants||!participants[0])
    //     {
    //       return EMPTY;
    //     }
    //     return i.conversationService.createChat(participants, route.params.id);
    //   }),
    //   finalize(() => {
    //   })
    // );
  }
}
