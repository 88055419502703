import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SharedService, UserAccountService } from "../../modules/core";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditComponent implements OnInit {
  page_type = "profile";
  profile: any;
  selectedContactId = "";

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private userAccountService: UserAccountService
  ) {}

  ngOnInit() {
    const instance = this;
    this.userAccountService.getUserAccount(true, function (data) {
      instance.profile = data;
    });
    this.route.params.subscribe((params) => {
      this.page_type = params ? params.page : "profile";
    });
  }

  closed() {
    this.selectedContactId = "";
    this.sharedService.onBlocklistSubject.next();
  }

  addColleagues() {
    this.sharedService.invite("user");
  }
}
