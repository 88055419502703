<app-conversations-header
  [heading]="team.name"
  searchPlaceholder="Search by Name, Patient ID"
  [isCreateChatEnabled]="false"
  (query)="handleSearch($event)"
>
  <button
    end
    mat-icon-button
    aria-label="Role details"
    (click)="handleRoleDetailsClicked()"
  >
    <mat-icon class="material-icons-outlined celo-blue-color">info</mat-icon>
  </button>
</app-conversations-header>

<!-- Clock in status -->
<div class="clock-in-container">
  <div class="clocked-in-card">
    <ng-container *ngIf="memberOnCall; else clockInEmptyState">
      <app-basic-user-avatar-card
        [userId]="memberOnCall.user.userId"
        [name]="memberOnCall.user.fullName"
        [description]="memberOnCall.user.profession"
        [isIdentityVerified]="memberOnCall.user.identityVerified"
        [isWorkspaceVerified]="memberOnCall.user.workspaceVerified"
        [isProfessionVerified]="memberOnCall.user.professionVerified"
        [fetchImage]="memberOnCall.user.profilePic"
        [suffix]="isCurrentUserOnCall ? ' (You)' : null"
      >
        <app-basic-user-statuses
          end
          [isOnCall]="true"
          alignItems="flex-start"
          [onCallColor]="isCurrentUserOnCall ? null : 'rgba(0, 0, 0, 0.6)'"
        ></app-basic-user-statuses> </app-basic-user-avatar-card
    ></ng-container>
    <ng-template #clockInEmptyState>
      <app-basic-user-avatar-card
        name="No one is clocked in"
        description="Incoming messages are not being forwarded."
        [isAvatarEnabled]="false"
        contentWidth="100%"
        descriptionMaxWidth="100%"
      ></app-basic-user-avatar-card>
    </ng-template>
  </div>

  <div class="mat-caption" *ngIf="!memberOnCall">
    Once you clock in you will be notified of incoming requests for this role
  </div>

  <button mat-button class="celo-link" (click)="handleClockInOrOut()">
    {{
      isCurrentUserOnCall ? "Clock Out from this role" : "Clock In to this role"
    }}
  </button>
</div>

<!-- Conversations/requests -->
<div class="requests-container">
  <div class="header mat-body-strong">Roles Inbox</div>

  <mat-divider></mat-divider>

  <app-create-new-conversation-button
    [buttonText]="'New Chat'"
    (click)="handleCreateChat()"
  ></app-create-new-conversation-button>

  <mat-divider></mat-divider>

  <ng-container
    *ngIf="{
      conversations: conversations$ | async,
      searchResults: searchResults$ | async,
      userAccount: userAccount$ | async
    } as conversationsListData"
  >
    <ng-container
      *ngIf="
        isInitialConversationsLoading ||
          isUserAccountLoading ||
          isInitialSearchLoading;
        else conversationsList
      "
    >
      <div class="loading-container">
        <app-loading-logo></app-loading-logo>
      </div>
    </ng-container>

    <ng-template #conversationsList>
      <div class="conversations-list-container">
        <ng-container
          *ngIf="
            (isSearching
              ? conversationsListData.searchResults
              : conversationsListData.conversations
            )?.length > 0;
            else conversationsEmptyState
          "
        >
        </ng-container>
        <app-conversation-list
          [conversations]="
            isSearching
              ? conversationsListData.searchResults
              : conversationsListData.conversations
          "
          [userAccount]="conversationsListData.userAccount"
          [canLoadMore]="isSearching ? searchHasNext : conversationsHasNext"
          [loading]="isSearching ? isSearchLoading : isConversationsLoading"
          [routePrefixes]="['conversations']"
          (loadMore)="handleLoadMoreConversations()"
          [isRolesSidenav]="true"
        >
        </app-conversation-list>

        <ng-template #conversationsEmptyState>
          <ng-container *ngIf="!isSearching; else searchResultsEmptyState">
            <div class="mat-caption">There are no messages for this role.</div>
          </ng-container>
          <ng-template #searchResultsEmptyState>
            <div class="no-results-container">
              <img
                src="../../../../assets/search-conversation.svg"
                alt="Search conversations"
              />
              <h3 class="appear_anim_2 mat-h3 description">No results found</h3>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>
  </ng-container>
</div>
