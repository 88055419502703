import { Clipboard as CdkClipboard } from "@angular/cdk/clipboard";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "@env";
import { ConversationPickerDialogComponent } from "@modules/conversations/conversation-picker-dialog/conversation-picker-dialog.component";
import {
  AccountService,
  AlertService,
  AnalyticsService,
  ConService,
  ConnectionStatus,
  ConversationService,
  ConversationsService,
  FileUploadService,
  MemoryService,
  MessageService,
  SharedService,
  TeamsService,
  UploadModel,
  UploadServiceFileItem,
  UserService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { VoipService } from "@modules/core/services/voip.service";
import { DropBoxComponent } from "@modules/library/drop-box/drop-box.component";
import { LibrarySelectImgsComponent } from "@modules/library/library-select-imgs/library-select-imgs.component";
import { SoundPlayService } from "@modules/shared/sound-play.service";
import { UserPickerSubmitCallback } from "@modules/user-picker/user-picker.service";
import {
  Call,
  ConversationModelV2,
  ConversationParticipantModel,
  ConversationParticipantModelV2,
  ConversationType,
  CreateMessageModel,
  FullUserProfileModel,
  MessageModel,
  MessageStatusModel,
  MessageStatuses,
  MessageType,
  ParticipantRole,
  Team,
  TeamMember,
  VideoCallStatus,
  VideoCallUpdate,
} from "@types";
import {
  dateCompareIfNotNull,
  getComputedLocalTime,
  isNotNullOrUndefined,
  isPastDate,
} from "@utils";
import { config } from "configurations/config";
import { FileUploader } from "ng2-file-upload";
import { Observable, Subscription, combineLatest, throwError } from "rxjs";
import {
  filter,
  first,
  map,
  mapTo,
  shareReplay,
  switchMap,
  throttleTime,
} from "rxjs/operators";
import { EditPatientDetailsUpdateFn } from "./../modules/shared/edit-patient-details-dialog/edit-patient-details-dialog.component";
import { PatientDetails } from "./../modules/shared/patient-details-form/patient-details-form.component";
import { MessageComposeModel } from "./message-input/message-input.component";
import { SearchSelect } from "./search/search.component";

interface LoadDataParams {
  conversationId: string;
  /** This should be a message's legacy integer ID to support legacy code. */
  messageId?: number | null;
  forceReload?: boolean | null;
}

enum Order {
  Ascending,
  Descending,
}

interface AddMessageOptions {
  disableScroll?: boolean;
  enableAppending?: boolean;
}

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
})
export class MessagesComponent
  implements OnInit, AfterViewChecked, OnDestroy, OnChanges
{
  @ViewChild("scrollMe", { static: true })
  private myScrollContainer: ElementRef<HTMLDivElement>;

  @ViewChild("top") private topAnchor: ElementRef;
  @ViewChild("bottom") private bottomAnchor: ElementRef;
  @ViewChild("sidenav", { static: true }) private sidenav: MatSidenav;
  @ViewChild("top") block: ElementRef;

  /**
   * Ordered from oldest to newest by `createdOnUtc` (if the current user sent the message), and then by `sentOnUtc`
   */
  messages: MessageModel[];
  quotedMessage: MessageModel;
  newMessage = "";
  id = "";
  conversation: ConversationModelV2 | null = null;
  enableScrollDown = false;
  heightBeforeLoadingOlderMessages = null;
  alreadyLoadingOlderMessages = false;
  initialLoading = true;
  pMap: { [x: string]: ConversationParticipantModelV2 };
  // messageStreamSub = null;
  leftChat: boolean;
  isAdmin = false;
  focused = true;
  userAccount: FullUserProfileModel | null = null;
  showConversationInfo = false;
  showMessageStatus = false;
  selectedMessage: any;
  hasUnsentMessageCount = 0;
  dropZoneActive = "";
  public uploader: FileUploader;
  scrollFetchMessageCount = 50;
  loadingMore: boolean;
  findChatSub: any;
  notScrolled = true;
  initialFetchMessageCount = 50;
  previousLastMessageId: number;
  searching = false;
  menu: string;
  searchQuery: string;
  loaded: boolean;
  refreshCount = 0;
  type: ConversationType | "" = "";
  chatParticipantId: string;
  chatParticipant: any;
  patientId: string;
  activeOtherParticipants: any[];
  connectionSubscription: any;
  typing: any[];
  typingFilter: number;
  isOnDND: boolean;

  allowedToShowLastOnline: boolean;
  allowedToShowTyping: boolean;

  // savedMessage: string;
  // saved = {};

  subscription: any;
  uploadRefreshCount = 0;
  messageRetryGap = 4000;
  messageId: any;
  bottomReached: boolean;
  loadingMoreBottom: boolean;
  messageFetchingSub: any;
  gettingMessagesByMarker: boolean;
  gettingMessagesByMarkerSub: any;
  sendMessageSub: any;
  retryMessageTimer: NodeJS.Timeout;

  newMessageIds = [];
  BlockChangeSub: any;
  myCompaniesAndPartnerCompanies: any = [];
  hasCommanWorkspace: boolean;
  conversationSub: any;
  focusSub: any;
  subUserAction: any;
  subMessageView: any;
  subMentionClick: any;
  updatedConversationEmittedSub: any;
  loadMessagesSub: any;
  name: string;
  participants: ConversationParticipantModel[];
  activeAdminList: any[];
  profileInFocus: string;
  messageToHighlight: any;
  conversationProfileUri: string;

  public currentUserOnCallName: string | null = null;
  public team: Team | null = null;
  public isTeamInactive: boolean = false;
  public isCurrentUserTeamMember: boolean = false;
  public teamChatParticipant: ConversationParticipantModelV2 | null = null;
  private teamSubscription: Subscription | null = null;
  public isRolesTab: boolean = false;
  public isTeamChat: boolean = false;

  public externalChatParticipant: ConversationParticipantModelV2 | null = null;

  public inProgressCall$: Observable<Call | null> | null = null;

  private uploadDraft: UploadModel = {
    items: [],
    patient: {
      uid: "",
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      gender: null,
    },
    selectedItemIndex: 0,
  };

  public isConversationAdmin: boolean = false;
  public isActiveParticipant: boolean = false;

  private isNearBottomDistance: number = 300;
  private isNearTopDistance: number = 600;

  private routeSubscription: Subscription | null;

  public get isBlocked() {
    return (
      this.chatParticipant?.blockedByMe?.isBlocked ||
      this.chatParticipant?.blockedMe?.isBlocked
    );
  }

  constructor(
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private connectionService: ConService,
    private soundPlayService: SoundPlayService,
    public changeDetectorRef: ChangeDetectorRef,
    private fileUploadService: FileUploadService,
    private conversationService: ConversationService,
    private sharedService: SharedService,
    private messageService: MessageService,
    private accountService: AccountService,
    public alertService: AlertService,
    public analyticsService: AnalyticsService,
    private memoryService: MemoryService,
    private teamsService: TeamsService,
    private router: Router,
    private conversationsService: ConversationsService,
    private userService: UserService,
    private matDialog: MatDialog,
    private clipboard: CdkClipboard,
    private voipService: VoipService
  ) {}

  handleUserAction(action) {
    action["actionTime"] = new Date();
    action["firstName"] = this.conversationService.getParticipantNameById(
      this.conversation,
      action["userId"]
    );
    // this.typing = []
    let hitIndex = -1;
    for (let i = 0; i < this.typing.length; i++) {
      const item = this.typing[i];
      if (item["userId"] == action["userId"]) {
        hitIndex = i;
        break;
      }
    }
    if (hitIndex == -1) {
      if (action.isActioning) {
        this.typing.unshift(action);
      }
    } else if (action.isActioning) {
      this.typing[hitIndex] = action;
    } else {
      this.typing.splice(hitIndex, 1);
    }
  }

  insertNewMessage(id) {
    this.newMessageIds.push(id);
  }

  clearNewMessages() {
    if (this.focused) {
      this.newMessageIds = [];
    }
  }

  clearNewMessage(id) {
    this.newMessageIds = this.newMessageIds.filter((i) => i !== id);
  }

  public updatePatientDetails: EditPatientDetailsUpdateFn = (
    patientDetails
  ) => {
    if (!this.conversation?.id)
      return throwError(new Error("Invalid conversation id"));
    return this.conversationsService
      .updateConversationPatientData(this.conversation.id, patientDetails)
      .pipe(mapTo(null));
  };

  public onNewPatientDetails(patientDetails: PatientDetails) {
    this.conversation.patientData = {
      ...this.conversation.patientData,
      ...patientDetails,
    };
    this.onConversationDataUpdated();
  }

  onNewConversationInfo(conversation) {
    if (!conversation) {
      return;
    }
    this.conversation = conversation;
    this.onConversationDataUpdated();
  }

  filterTyping(delay) {
    const instance = this;
    if (!this.typingFilter) {
      this.typingFilter = window.setInterval(function () {
        if (instance.typing && instance.typing.length > 0) {
          instance.removeExpiredOnes();
        }
      }, delay * 1000);
    }
  }

  removeExpiredOnes() {
    const duration = 3;
    let actionTime;
    const time = new Date();
    this.typing = this.typing.filter((element) => {
      actionTime = element.actionTime;
      const dif = time.getTime() - actionTime.getTime();
      const seconds = dif / 1000;
      if (seconds < duration) {
        return true;
      }
      return false;
    });
  }

  initializeFileUploader(targetConversation: string) {
    // let type='Photos';
    const type = "Multi";
    const url = `${environment.celoApiEndpoint}/api/photos/`;
    const data = {
      type,
      queueLimit: config.max_file_upload_limit + 1,
      allowPatient: true,
      url,
      conversationId: targetConversation,
    };
    const instance = this;
    this.fileUploadService.initialize(data, function (result) {
      const conversationId = result.conversationId;

      if (
        result.success &&
        result.uploadedFiles &&
        result.uploadedFiles.length > 0
      ) {
        const photos = [];
        const documents = [];
        result.uploadedFiles.forEach((uploadedFile) => {
          if (uploadedFile.type == "Photo") {
            photos.push(uploadedFile);
          } else if (uploadedFile.type == "Document") {
            documents.push(uploadedFile);
          }
        });
        instance.sendUploadedPics(photos, conversationId);
        instance.sendUploadedDocuments(documents, conversationId);
      }
    });
    this.uploader = this.fileUploadService.uploader;
  }

  fileDropped() {}
  dropZoneState(state: boolean) {
    this.dropZoneActive = state ? "valid" : "";
  }

  handleDrop(files: FileList) {}
  handleFilesSelection(files: FileList) {
    this.uploadRefreshCount++;
  }

  private loadUploadDraft() {
    const draft = this.memoryService.memory.uploadDrafts.get(
      this.conversation.id
    );

    if (!draft) return;

    const { items } = draft;
    // rawFile is incorrectly typed. See: https://github.com/valor-software/ng2-file-upload/issues/1049
    const files = items.map((item) => item.file.rawFile as unknown as File);

    this.uploadDraft = draft;
    this.uploader.addToQueue(files);

    for (const item of items) {
      const uploadServiceFileItem = this.uploader.queue.find(
        (f) => f.file.rawFile === item.file.rawFile
      ) as UploadServiceFileItem;

      if (!uploadServiceFileItem) continue;

      uploadServiceFileItem.filename = item.filename;
      uploadServiceFileItem.description = item.description;
    }
  }

  public handleItemsChange(items: UploadServiceFileItem[]) {
    const selectedItemIndex = Math.min(
      items.length - 1,
      this.uploadDraft.selectedItemIndex
    );
    this.uploadDraft = {
      ...this.uploadDraft,
      items,
      selectedItemIndex,
    };
    this.memoryService.memory.uploadDrafts.set(
      this.conversation.id,
      this.uploadDraft
    );
  }

  public handleSelectedItemsIndexChange(selectedItemIndex: number) {
    this.uploadDraft = {
      ...this.uploadDraft,
      selectedItemIndex,
    };
    this.memoryService.memory.uploadDrafts.set(
      this.conversation.id,
      this.uploadDraft
    );
  }

  private removeUploadDraft() {
    this.memoryService.memory.uploadDrafts.delete(this.conversation.id);
  }

  public handleUploadSubmitted() {
    this.removeUploadDraft();
  }

  public handleUploadClose() {
    this.removeUploadDraft();
    this.closeUpload();
  }

  closeUpload() {
    this.uploadDraft = {
      items: [],
      patient: {
        uid: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        gender: null,
      },
      selectedItemIndex: 0,
    };
    this.fileUploadService.uploader.clearQueue();
  }

  ngOnChanges() {
    this.setName();
    this.setConversationProfileUri();
  }

  loadConversationData(conversationId) {
    this.conversationSub = this.conversationsService
      .getConversation(conversationId, { latestOnly: true })
      .subscribe((conversation) => {
        this.conversation = conversation;
        this.loadUploadDraft();
        this.onConversationDataUpdated();
        if (this.type === ConversationType.Chat) {
          this.setParticipantId();
          this.addSubscriptionToUserStatus(this.chatParticipantId);
          this.listenToUserDND(this.chatParticipantId);
          this.getChatParticipant(this.chatParticipantId);
          this.setDND();
        } else if (this.type === ConversationType.SelfChat) {
          this.chatParticipantId = this.userAccount.userId;
          this.getChatParticipant(this.chatParticipantId);
        } else if (this.type === ConversationType.External) {
          this.externalChatParticipant =
            this.conversationService.getExternalChatParticipant(conversation);
        }

        this.isTeamChat = this.type === ConversationType.TeamChat;

        if (this.isTeamChat && this.conversation?.id) {
          const teamId = this.conversation.participants.find(
            (p) => p.teamId
          )?.teamId;

          if (teamId) {
            this.teamSubscription?.unsubscribe();
            this.teamSubscription = this.teamsService
              .getTeam({ teamId })
              .subscribe((team) => {
                this.team = team;
                this.currentUserOnCallName =
                  this.teamsService.findFirstMemberOnCall(team)?.user
                    ?.fullName ?? null;
                this.isTeamInactive = !team.isActive;
                this.isCurrentUserTeamMember =
                  this.teamsService.isCurrentUserMember(team);
                this.teamChatParticipant =
                  this.conversationService.getTeamChatParticipant(
                    this.conversation
                  );
              });
          } else {
            // Assume team is inactive as if we're unable to find any team participants
            this.isTeamInactive = true;
            this.isCurrentUserTeamMember = false;
            this.teamChatParticipant = null;

            // Fallback to using a placeholder team
            this.team = {
              name: this.conversation.name,
              isActive: false,
            };
          }
        } else {
          this.isTeamInactive = false;
          this.isCurrentUserTeamMember = false;
          this.teamChatParticipant = null;
        }

        this.unsubscribeConversationAction(conversationId);
        this.subscribeConversationAction(conversationId);
        this.allowedToShowLastOnline = false;
        this.allowedToShowTyping = false;
        this.setAccessControlValues();
        this.initialLoading = true;
      });
  }

  setAccessControlValues() {
    this.allowedToShowLastOnline = false;
    this.allowedToShowTyping = false;
    if (this.canSendMessagesToTheGroup()) {
      this.allowedToShowLastOnline = true;
      this.allowedToShowTyping = true;
    }
  }

  ngOnInit() {
    this.initializeFileUploader("");
    this.subUserAction = this.connectionService.userAction$.subscribe(
      (userAction) => {
        if (
          userAction["conversationId"] === this.id &&
          userAction["userId"] !== this.userAccount.userId
        ) {
          this.handleUserAction(userAction);
        }
      }
    );

    this.connectionSubscription = this.connectionService.connectionStatus$
      .pipe(
        throttleTime(5000, null, {
          leading: true,
          trailing: true,
        })
      )
      .subscribe((status) => {
        if (status !== ConnectionStatus.Connected) return;

        if (this.id) {
          this.subscribeConversationAction(this.id);
        }

        // Reload messages and conversation data when SignalR reconnects
        if (this.id && this.loaded) {
          this.loadData({
            conversationId: this.id,
            forceReload: true,
          });
        }
      });

    this.filterTyping(3);
    this.focused = document.hasFocus();

    this.subMessageView = this.messageService.onMessageView.subscribe(
      (message) => {
        if (!this.focused) {
          return;
        }
        this.seeMessages([message]);
        this.clearNewMessage(message.id);
      }
    );

    this.subMentionClick = this.messageService.onMentionClicked.subscribe(
      (mention: any) => {
        if (this.userService.getUserId() === mention.id) return;
        this.openProfileInfo(mention.id);
      }
    );

    this.sharedService.setTitle("Messages | Celo");
    this.connectionService.Message$.subscribe((messages) => {
      for (const message of messages) {
        if (message.conversationId !== this.id) continue;
        this.addOrUpdateMessage(message);
      }
    });

    this.connectionService.videoCallUpdate$.subscribe((videoCallUpdate) => {
      this.handleVideoCallUpdate(videoCallUpdate);
    });

    this.detectHasLeft(this.userAccount);

    this.routeSubscription = combineLatest([
      this.activeRoute.params,
      this.activeRoute.queryParams,
      this.activeRoute.data,
      this.accountService.userAccount$
        .pipe(filter(isNotNullOrUndefined))
        .pipe(first()),
    ])
      .pipe(
        map((results) => ({
          conversationId: results[0].id,
          messageId: results[1].message_id,
          isRolesTab: results[2].isRolesTab,
          userAccount: results[3],
        }))
      )
      .subscribe((results) => {
        this.isRolesTab = results.isRolesTab || false;
        this.messageId = null;
        this.userAccount = results.userAccount;
        this.loadData({
          conversationId: results.conversationId,
          messageId: results.messageId,
        });
      });

    this.focusSub = this.sharedService.onFocusChange.subscribe((foucsed) => {
      if (foucsed) {
        this.focused = true;
      } else {
        this.focused = false;
      }
    });

    if (!this.findChatSub) {
      this.findChatSub = this.conversationService.findChatEmitted$.subscribe(
        (message) => {
          if (this.messageId != message["id"]) {
            this.findChat(message);
          }
        }
      );
    }

    this.updatedConversationEmittedSub =
      this.conversationService.updatedConversation$.subscribe(
        (updatedConversation: ConversationModelV2) => {
          if (this.conversation.id != updatedConversation.id) return;
          this.refreshCount++;
          this.conversation = updatedConversation;
          this.onConversationDataUpdated();
        }
      );

    this.BlockChangeSub = this.connectionService.BlockChange.subscribe(
      (block) => {
        if (
          block &&
          (block.blockerId == this.chatParticipantId ||
            block.userId == this.chatParticipantId)
        ) {
          this.getChatParticipantApi();
          this.refreshConversationInfo();
        }
      }
    );
    this.getCompanies();

    this.setConversationProfileUri();
  }

  private loadData({ conversationId, messageId, forceReload }: LoadDataParams) {
    if (
      !forceReload &&
      conversationId == this.id &&
      this.messageId == messageId
    )
      return;
    if (
      !forceReload &&
      conversationId == this.id &&
      this.messageId != messageId
    ) {
      const m = this.messages.find((message) => message.id == messageId);
      if (m) {
        this.checkIfMessageAnchorExists(m, (anchor) => {
          if (anchor) this.messageToHighlight = m;
          else {
            this.findChat(m);
          }
        });
        return;
      }
    }
    this.reset(conversationId);
    this.loadConversationData(conversationId);
    this.messageId = messageId;
    this.getMessages(() => {
      if (!forceReload && messageId && conversationId) {
        this.enterSearchView(messageId, conversationId);
        this.getMessageById(this.messageId, this.id, (message) => {
          setTimeout(() => {
            this.checkIfMessageAnchorExists(message, (anchor) => {
              if (anchor) this.messageToHighlight = message;
              else {
                this.findChat(message);
              }
            });
          }, 600);
          this.searching = false;
        });
      }
      this.loaded = true;

      this.scrollToBottomNow();
    });

    this.inProgressCall$ = this.voipService
      .getInProgressCall(conversationId)
      .pipe(shareReplay());

    this.bottomReached = false;
    this.loadingMoreBottom = false;
    this.searching = false;
    this.initializeFileUploader(conversationId);
  }

  initializeParticipants() {
    const loggedInUserId = this.userService.getUserId();

    this.activeAdminList =
      this.conversationService.getActiveAdminParticipantIds(
        this.conversation,
        loggedInUserId
      );
    this.participants = this.conversationService.getActiveParticipants(
      this.conversation,
      loggedInUserId
    );

    this.sharedService.sortParticipantsByFullname(this.participants);
    this.sharedService.sortAdmins(this.participants, this.activeAdminList);
    this.sharedService.pullToTop(this.participants, "userId", loggedInUserId);
  }

  getMessages(callback?) {
    if (this.loadMessagesSub) {
      this.loadMessagesSub.unsubscribe();
    }
    this.loadMessagesSub = this.conversationService
      .getMessages(this.id, this.initialFetchMessageCount, undefined, undefined)
      .subscribe((messages) => {
        this.messages = messages;
        this.conversationService.messages = this.messages;
        this.sortMessages();
        if (callback) {
          callback(messages);
        }
      });
  }

  sortMessages() {
    //TODO: Work out a secondary sorting key for messages, this will cause issues if the timestamps are the same
    this.messages.sort((a, b) => {
      const dateA = this.getMessageTimestamp(a);
      const dateB = this.getMessageTimestamp(b);
      return dateA < dateB ? -1 : 1;
    });
  }

  refreshConversationInfo() {
    this.conversationService
      .getConversationByIdApi(this.conversation.id)
      .subscribe((convo) => {
        if (!convo) {
          return;
        }
        this.conversation = convo;
        this.conversationService.addOrReplaceConversation(convo);
        this.setAccessControlValues();
      });
  }

  generateLinkIfNoLink() {
    if (!this.conversation.invitation || !this.conversation.invitation.uri) {
      const path =
        environment.celoApiEndpoint +
        "/api/v2/conversations/" +
        this.id +
        "/invitation";
      this.sharedService.postObjectById(path).subscribe(
        (invitation) => {
          if (!invitation) {
            return;
          }
          this.conversation.invitation = invitation;
        },
        (err) => {}
      );
    }
  }

  subscribeConversationAction(conversationId: string) {
    if (!this.canSendMessagesToTheGroup()) {
      return;
    }
    this.connectionService.subscribeConversationActions([conversationId]);
  }

  unsubscribeConversationAction(conversationId) {
    this.connectionService.unsubscribeConversationActions([conversationId]);
  }

  setPatientId(conversation: any) {
    if (conversation.patientData && conversation.patientData.uid) {
      this.patientId = conversation.patientData.uid;
    }
  }

  setConversationType(conversation: ConversationModelV2 | null) {
    this.type = "";
    if (conversation["type"]) {
      this.type = conversation["type"];
    } else if (conversation.patientData) {
      this.type = ConversationType.Case;
    } else if (!conversation.patientData) {
      this.type = ConversationType.Group;
    }
  }

  canSendMessagesToTheGroup() {
    if (this.leftChat) {
      return false;
    }
    if (this.type == "Chat") {
      if (
        (this.chatParticipant["blockedByMe"] &&
          this.chatParticipant["blockedByMe"].isBlocked) ||
        (this.chatParticipant["blockedMe"] &&
          this.chatParticipant["blockedMe"].isBlocked)
      ) {
        return false;
      }
      if (
        !this.hasCommanWorkspace &&
        (!this.chatParticipant["connection"] ||
          (this.chatParticipant["connection"].state != "Accepted" &&
            this.conversation.isReal == false))
      ) {
        return false;
      }
    }
    return true;
  }

  setParticipantId() {
    if (!this.pMap) {
      return;
    }

    const loggedInUserId = this.userService.getUserId();

    for (const id in this.pMap) {
      if (id !== loggedInUserId) {
        this.chatParticipantId = id;
        this.chatParticipant = this.pMap[id];
        break;
      }
    }
  }

  setDND() {
    this.isOnDND = false;
    if (!this.chatParticipant["doNotDisturbToUtc"]) {
      return;
    }
    this.isOnDND = isPastDate(this.chatParticipant["doNotDisturbToUtc"]);
  }

  setParticipantList() {
    if (this.conversation && this.conversation.participants) {
      this.sharedService.sortArrayByField(
        this.conversation.participants,
        "firstName"
      );
    }

    this.activeOtherParticipants = [];
    this.pMap = this.pMap ? this.pMap : {};

    for (const p of this.conversation.participants) {
      this.pMap[p.userId] = p;
      if (!p.leftOnUtc && p.userId !== this.userAccount.userId) {
        this.activeOtherParticipants.push(p);
      }
    }
  }

  addSubscriptionToUserStatus(userId) {
    this.connectionService.subscribeUserStatus([userId]);
  }

  unsubscribeUserStatus(userId) {
    this.connectionService.unsubscribeUserStatus([userId]);
  }

  scrollOnNewMessage() {
    setTimeout(() => {
      this.scrollToBottomIfAtBottomAlready();
    }, 100);
  }

  ngAfterViewChecked() {
    this.scrollToBottomNow();
    if (this.messageToHighlight) {
      this.checkIfMessageAnchorExists(
        this.messageToHighlight,
        (anchor: any) => {
          const message = this.messageToHighlight;
          if (anchor) this.messageToHighlight = null;
          setTimeout(() => {
            if (anchor) {
              this.scrollAndHighlight(anchor, message);
            }
          }, 600);
        }
      );
    }
  }

  ngOnDestroy() {
    this.teamSubscription?.unsubscribe();

    if (this.chatParticipantId) {
      this.unsubscribeUserStatus(this.chatParticipantId);
    }
    // this.messageStreamSub.unsubscribe();
    this.searching = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.BlockChangeSub) {
      this.BlockChangeSub.unsubscribe();
    }
    if (this.focusSub) {
      this.focusSub.unsubscribe();
    }
    if (this.connectionSubscription) {
      this.connectionSubscription.unsubscribe();
    }
    if (this.subUserAction) {
      this.subUserAction.unsubscribe();
    }
    if (this.subMessageView) {
      this.subMessageView.unsubscribe();
    }
    if (this.subMentionClick) {
      this.subMentionClick.unsubscribe();
    }
    if (this.updatedConversationEmittedSub) {
      this.updatedConversationEmittedSub.unsubscribe();
    }

    this.routeSubscription?.unsubscribe();
  }

  scrollBlock() {
    setTimeout(() => {
      // this.topAnchor.nativeElement.scrollIntoView()
      this.myScrollContainer.nativeElement.scrollTop = 0;
    }, 3000);
  }

  getMessageById(id, conversationId, callback) {
    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/" +
      conversationId +
      "/GetMessage/" +
      id;
    this.sharedService.getObjectById(path).subscribe(
      (data) => {
        callback(data);
      },
      (err) => {
        callback(null);
      }
    );
  }

  public findChat(message: any) {
    const anchor = document.getElementById(
      "messageAnchor" + message.id
    ) as HTMLElement;
    if (!anchor) {
      if (!this.sharedService.isOnline()) {
        this.sharedService.noInternetSnackbar();
        return;
      }
      if (this.messageFetchingSub) {
        this.messageFetchingSub.unsubscribe();
      }
      this.searching = true;
      this.enterSearchView(message["id"], this.id);
      this.fetchAndHighlight(message);
    } else {
      this.scrollAndHighlight(anchor, message);
    }
  }

  checkIfMessageAnchorExists(message: any, callback?) {
    const anchor = document.getElementById(
      "messageAnchor" + message.id
    ) as HTMLElement;
    if (!anchor) {
      callback(false);
      return;
    }
    callback(anchor);
  }

  searchSelect(searchResult: SearchSelect) {
    let message: MessageModel;
    message = searchResult.message;
    this.searchQuery = searchResult.searchQuery;

    if (
      message.type === MessageType.VideoCall &&
      message.metadata.resourceStatus === "InProgress"
    ) {
      this.voipService.navigateToJoinCall(
        message.conversationId,
        message.metadata.resourceId
      );
      return;
    }

    if (message.id != this.messageId) {
      this.enterSearchView(message.id, this.id);
    }
    this.findChat(message);
  }

  fetchAndHighlight(message: any) {
    const count = 30;
    const i = this;
    this.loadingMore = true;
    this.getMessagesByMarker(
      this.id,
      message.marker,
      count,
      function (messages) {
        i.loadingMore = false;
        if (messages && messages.length) {
          i.messages = [];
          i.insertIncomingSet(messages);
          i.messageToHighlight = message;
          // setTimeout(() => {
          //   i.checkAgain(message);
          // }, 500);
        }
      }
    );
  }

  getMessagesByMarker(conversationId, marker, count, callback) {
    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/" +
      conversationId +
      "/Messages";
    const params = {
      marker,
      count,
    };

    const i = this;

    this.gettingMessagesByMarkerSub = this.sharedService
      .getObjectById(path, params)
      .subscribe(
        (data) => {
          callback(data);
        },
        (err) => {
          callback([]);
        }
      );
  }

  scrollToBottom(bottomAnchor) {
    this.clearNewMessages();
    this.scrollTo(bottomAnchor);
  }

  scrollAndHighlight(anchor, message: any) {
    this.searching = false;
    this.scrollTo(anchor);

    const i = this;
    setTimeout(() => {
      i.conversationService.emitFoundChat(message, i.searchQuery);
    }, 500);
  }

  reset = (id) => {
    this.hasUnsentMessageCount = 0;
    this.type = "";
    this.isAdmin = false;
    this.typing = [];
    this.patientId = null;
    this.chatParticipant = undefined;
    this.chatParticipantId = "";
    this.hasCommanWorkspace = true;
    this.messageToHighlight = null;

    this.loaded = false;
    this.messages = [];
    this.conversationService.messages = this.messages;
    this.id = id;
    this.enableScrollDown = false;
    this.heightBeforeLoadingOlderMessages = null;
    this.alreadyLoadingOlderMessages = false;
    this.initialLoading = false;
    this.conversation = this.conversationService.getConversationById(id);
    this.leftChat = false;
    this.pMap = undefined;
    this.resetQuote();
    this.closeConversationInfo();
    this.closeMessageStatus();
    this.closeUpload();
    this.clearNewMessages();
    this.sidenav.close();

    this.loadingMore = false;
    this.loadingMoreBottom = false;

    this.notScrolled = true;
    this.myScrollContainer.nativeElement.scrollTop = 0;
    this.isOnDND = false;
    this.clearSendMessageRetryJobs();
    if (this.conversationSub) {
      this.conversationSub.unsubscribe();
    }
  };

  resetQuote() {
    this.quotedMessage = undefined;
  }

  goToLatest() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.exitSearchView();
  }

  sidenavClosed() {
    this.menu = "";
  }

  exitSearchView() {
    this.bottomReached = false;
    this.loadingMoreBottom = false;
    if (this.messageId) {
      this.unsubscribeAll();
      const url = this.router.url.split("?", 1)[0];
      this.sharedService.redirectTo(url);
    }
  }

  unsubscribeAll() {
    if (this.findChatSub) {
      this.findChatSub.unsubscribe();
    }
    if (this.messageFetchingSub) {
      this.messageFetchingSub.unsubscribe();
    }
  }

  enterSearchView(messageId, convoId) {
    this.bottomReached = false;
    this.loadingMoreBottom = false;
    if (!messageId) {
      return;
    }
    this.messageId = messageId;

    const ignoredUrls = ["roles", "external"];
    if (
      ignoredUrls.some((url) =>
        this.router.isActive(url, {
          paths: "subset",
          queryParams: "subset",
          fragment: "ignored",
          matrixParams: "ignored",
        })
      )
    ) {
      return;
    }

    this.sharedService.navigate(["/", "conversations", convoId, "messages"], {
      queryParams: { message_id: messageId },
    });
  }

  getSeenBy(message: any) {
    const peeps = [];
    for (const status of message.statuses) {
      if (status.status == MessageStatuses.Read) {
        const p = this.getParticipant(status.createdBy);
        if (p != null) {
          peeps.push({
            name: p.title + " " + p.firstName + " " + p.lastName,
            profilePicture: p.profilePicture,
            datetime: status.createdOnUtc,
            firstName: p.firstName,
          });
        }
      }
    }
    return peeps;
  }

  hasAlreadyReadByMe(id, statuses: MessageStatusModel[]): boolean {
    for (const status of statuses) {
      if (status.createdBy == id) {
        if (status.status == MessageStatuses.Read) {
          return true;
        }
      }
    }
    return false;
  }

  seeMessages(messages: Array<any>) {
    const conversationId = messages[0].conversationId;
    const statusesToSave: Array<any> = [];
    for (const message of messages) {
      if (
        message.sentBy != "SYSTEM" &&
        message.sentBy != this.userAccount.userId &&
        !this.hasAlreadyReadByMe(this.userAccount.userId, message.statuses)
      ) {
        // add new status changes
        statusesToSave.push({
          MessageId: message.id,
          Status: "Delivered",
        });
        statusesToSave.push({
          MessageId: message.id,
          Status: "Read",
        });
      }
      // update local conversations list
      this.conversationService.updateUnreadMessageIds(message);
    }
    this.connectionService.setUnreadCount();
    if (statusesToSave.length) {
      const path =
        environment.celoApiEndpoint +
        "/api/Conversations/" +
        conversationId +
        "/UpdateMessageStatuses";
      this.sharedService
        .postObjectById<MessageModel[]>(path, {}, statusesToSave)
        .subscribe(
          (respMessage) => {
            this.updateMessages(respMessage);
          },
          (err) => {}
        );
    } else {
    }
  }

  updateMessages(updatedMessages: MessageModel[]) {
    if (!updatedMessages || updatedMessages.length == 0) {
      return;
    }
    for (const message of updatedMessages) {
      this.updateMessage(message);
    }
  }

  updateMessage(updatedMessage: MessageModel, noRefresh?: boolean) {
    this.addOrUpdateMessage(updatedMessage, noRefresh);
  }

  public getParticipant(userId): any {
    if (!this.pMap) {
      return null;
    }
    return this.pMap[userId] || null;
  }

  public detectHasLeft(userAccount: any) {
    if (!userAccount || !userAccount.userId) {
      return;
    }
    const p = this.getParticipant(userAccount.userId);
    if (p) {
      this.leftChat = !!p.leftOnUtc;
    }
  }

  private updateMessageInPlace(
    existingMessage: MessageModel,
    updatedMessage: MessageModel
  ) {
    for (const key in updatedMessage) {
      if (!updatedMessage.hasOwnProperty(key)) continue;
      if (key === "content" || key === "statuses") continue;
      existingMessage[key] = updatedMessage[key];
    }

    if (
      updatedMessage.deletedOnUtc ||
      updatedMessage.type === MessageType.VideoCall
    ) {
      existingMessage.content = updatedMessage.content;
      existingMessage.metadata.resourceCallDurationInSeconds =
        updatedMessage.metadata.resourceCallDurationInSeconds;
    } else {
      // Don't replace statuses with ones from deleted messages as they don't include statuses
      existingMessage.statuses = updatedMessage.statuses;
    }
  }

  addOrUpdateMessages = (messages: MessageModel[]) => {
    for (const message of messages) {
      this.addOrUpdateMessage(message);
    }
  };

  addOrUpdateMessage = (message: MessageModel, noRefresh?: boolean) => {
    this.setEnableScrollDown();

    message["sb"] = this.getSeenBy(message);
    if (
      message.statuses.length == 0 &&
      message.sentBy == this.userAccount.userId
    ) {
      message.statuses.push({
        status: MessageStatuses.Sent,
      });
    }

    // Update replies to this message
    const replies = this.messages.filter(
      (m) => m.replyToMessage?.marker === message.marker
    );
    if (replies.length) {
      for (const reply of replies) {
        this.updateMessageInPlace(reply.replyToMessage, message);
      }
    }

    // Remove quoted message (the message being replied to) if it's deleted
    if (this.quotedMessage?.marker === message.marker && message.deletedOnUtc) {
      this.resetQuote();
    }

    // Update message if it already exists on the client
    const existingMessage = this.messages.find(
      (m) => m.marker === message.marker
    );

    if (existingMessage) {
      this.updateMessageInPlace(existingMessage, message);

      existingMessage["refreshCount"] = existingMessage["refreshCount"] ?? 0;
      existingMessage["refreshCount"]++;

      if (!noRefresh && this.menu == "message_status") {
        this.refreshCount++;
      }
      return;
    }

    // Add new message
    this.addMessage(message);
    this.conversationService.messages = this.messages;
  };

  setLastMessage(message) {
    this.connectionService.setLastMessage(this.conversation, message);
    const conversation = this.conversationService.getConversationById(
      this.conversation.id
    );
    if (conversation) {
      this.connectionService.setLastMessage(conversation, message);
    }
    this.conversationService.sortConversations();
    this.connectionService.setUnreadCount();
  }

  private getMessageTimestamp(
    message: MessageModel,
    defaultValue: Date | null = null
  ): Date | null {
    /**
     * For ordering rules see:
     * https://celohealth.atlassian.net/wiki/spaces/CH/pages/98324/Chat+message+display+order+and+timestamps
     */

    if (message.type === MessageType.ConversationStarted)
      return new Date(message.createdOnUtc);

    if (!message.sentOnUtc && !message.createdOnUtc) return defaultValue;

    if (message.sentBy === this.userAccount.userId)
      return new Date(message.createdOnUtc ?? message.sentOnUtc);

    return new Date(message.sentOnUtc ?? message.createdOnUtc);
  }

  addMessage(message: MessageModel, options?: AddMessageOptions) {
    const newMessageTime = this.getMessageTimestamp(message, new Date());

    if (!message.sentOnUtc && !message.createdOnUtc) {
      message.sentOnUtc = newMessageTime.toISOString();
    }

    // If this is the first message in the conversation, add it and do nothing else
    if (!this.messages || this.messages.length === 0) {
      this.messages = [];
      this.appendMessageToConversation(message);
      return;
    }

    const oldestMessageDate = this.getMessageTimestamp(this.messages[0]);
    const latestMessageDate = this.getMessageTimestamp(
      this.messages[this.messages.length - 1]
    );

    if (!oldestMessageDate || !latestMessageDate) {
      // Something's gone wrong. Every message should always have a sentOnUtc value
      // or a createdOnUtc value. If we couldn't find either of these fallback
      // to appending the message to the bottom of the conversation.
      this.appendMessageToConversation(message);
      console.error("Failed to find timestamp for message");
      return;
    }

    if (newMessageTime < oldestMessageDate) {
      // If appending is disabled and this message is older than the oldest message we currently have
      // then do nothing, the client will load it when they scroll up.
      // WARNING: Appending the message to the end list of messages can break the existing
      // pagination system. See: https://celohealth.atlassian.net/browse/CELO-11021
      if (!options?.enableAppending) return;
      this.appendMessageToConversationTop(message);
      return;
    }

    // If this message is older than the latest message we currently have
    // insert it before the latest message it is older than
    if (newMessageTime < latestMessageDate) {
      const insertionPosition =
        1 +
        this.messages.findLastIndex(
          (m) =>
            dateCompareIfNotNull(
              m.sentOnUtc ?? m.createdOnUtc,
              message.sentOnUtc ?? message.createdOnUtc
            ) > 0
        );
      this.appendMessageToConversationAt(message, insertionPosition);
      return;
    }

    // Appending all other messages to the bottom
    if (this.messages.length > 0) {
      this.appendMessageToConversation(message);
      if (
        (!options?.disableScroll &&
          message.sentBy === this.userAccount.userId) ||
        this.isNearBottom()
      ) {
        setTimeout(() => {
          this.executeBottomScroll();
        }, 600);
      }
      return;
    }
  }

  appendMessageToConversation(message: MessageModel) {
    if (this.id === message.conversationId) {
      this.messages.push(message);
    }
  }

  appendMessageToConversationTop(message: MessageModel) {
    if (this.id === message.conversationId) {
      this.messages.unshift(message);
    }
  }

  appendMessageToConversationAt(message: MessageModel, index: number) {
    if (this.id === message.conversationId) {
      this.messages.splice(index, 0, message);
    }
  }

  listenToUserDND(userId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.connectionService.userDND$.subscribe((userDND) => {
      if (userId == userDND.userId) {
        this.chatParticipant["doNotDisturbToUtc"] =
          userDND["doNotDisturbToUtc"];
        this.setDND();
      }
      if (this.changeDetectorRef) {
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getChatParticipantApi() {
    this.getChatParticipant(this.chatParticipantId);
  }

  getChatParticipant(id) {
    const instance = this;
    this.sharedService.getContact(id, function (contact: any) {
      if (contact) {
        if (instance.chatParticipantId == contact.userId) {
          instance.chatParticipant = contact;
          instance.chatParticipant["doNotDisturbToUtc"] =
            contact["doNotDisturbToUtc"];
          instance.setDND();
          if (instance.myCompaniesAndPartnerCompanies) {
            instance.hasCommanWorkspace =
              instance.sharedService.checkCommonWorkspace(
                instance.chatParticipant["workplaces"],
                instance.myCompaniesAndPartnerCompanies
              );
          }
        }
      }
    });
  }

  getCompanies() {
    const instance = this;
    this.sharedService.getPartneredCompanies(function (companies) {
      instance.myCompaniesAndPartnerCompanies = companies.data;
      if (instance.chatParticipant) {
        instance.hasCommanWorkspace =
          instance.sharedService.checkCommonWorkspace(
            instance.chatParticipant["workplaces"],
            instance.myCompaniesAndPartnerCompanies
          );
      }
    });
  }

  handleSubjectLineClick() {
    this.openConversationInfo();
  }

  private getFirstMessageBySentOnUtc(order: Order) {
    if (!this.messages?.length) return null;
    return this.messages.reduce((prev, curr) => {
      if (!curr.sentOnUtc) return prev;
      if (!prev) return curr;

      if (prev.sentOnUtc === curr.sentOnUtc) return prev;

      if (order === Order.Descending) {
        return prev.sentOnUtc > curr.sentOnUtc ? prev : curr;
      }
      return prev.sentOnUtc < curr.sentOnUtc ? prev : curr;
    }, null);
  }

  loadMorePrevious() {
    this.loadingMore = true;
    const oldestMessage = this.getFirstMessageBySentOnUtc(Order.Ascending);

    this.getOlderMessages(
      undefined,
      oldestMessage?.sentOnUtc,
      undefined,
      undefined,
      undefined,
      (messages) => {
        this.insertIncomingSet(messages);
        this.restoreHeight();
      }
    );
  }

  loadMoreBottom() {
    this.loadingMoreBottom = true;
    const count = 50;
    const latestMessage = this.getFirstMessageBySentOnUtc(Order.Descending);
    const sortBy = "asc(SentOnUtc)";

    this.getOlderMessages(
      latestMessage?.sentOnUtc,
      undefined,
      false,
      count,
      sortBy,
      (messages) => {
        this.insertIncomingSet(messages);
        this.restoreHeightBottom();

        this.loadingMoreBottom = false;
        if (messages.length < count) {
          this.bottomReached = true;
        }
      }
    );
  }

  private getOlderMessages(
    afterTime?,
    beforeTime?,
    noResetHeight?,
    count?,
    sort?,
    callback?: (messages?: MessageModel[]) => void
  ) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      this.loadingMore = false;
      this.loadingMoreBottom = false;
      if (callback) {
        return callback();
      }
      return;
    }
    if (this.alreadyLoadingOlderMessages) {
      if (callback) {
        return callback();
      }
      return;
    }
    if (this.messages.length == 0) {
      if (callback) {
        return callback();
      }
      return;
    }
    this.alreadyLoadingOlderMessages = true;

    const beforeDate = beforeTime ? new Date(beforeTime) : undefined;
    if (beforeDate) {
      beforeDate.setMilliseconds(beforeDate.getMilliseconds() - 10);
    }
    const before = beforeDate ? beforeDate.toISOString() : undefined;

    const afterDate = afterTime ? new Date(afterTime) : undefined;
    if (afterDate) {
      afterDate.setMilliseconds(afterDate.getMilliseconds() + 10);
    }
    const after = afterDate ? afterDate.toISOString() : undefined;

    const fetchCount = count ? count : this.scrollFetchMessageCount;
    this.messageFetchingSub = this.conversationService
      .getMessages(this.id, fetchCount, before, after, sort)
      .subscribe({
        next: (messages) => {
          this.loadingMore = false;
          this.alreadyLoadingOlderMessages = false;
          this.initialLoading = false;
          if (callback) {
            callback(messages);
          }
        },
        error: (err) => {
          this.alreadyLoadingOlderMessages = false;
          this.initialLoading = false;
        },
      });
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  public submitMessageMultiple(obj) {
    const newMessage: MessageComposeModel = obj.message;
    const count = obj.count;
    const delay = obj.delay;
    if (!delay || delay == 0) {
      for (let index = 0; index < count; index++) {
        this.submitMessage({
          ...newMessage,
          content: newMessage.content + " " + (index + 1),
        });
      }
    } else if (delay > 0) {
      this.send(newMessage, count, delay, 0);
    }
  }

  send(message: MessageComposeModel, count, delay, index) {
    if (index < count) {
      this.submitMessage({
        ...message,
        content: message.content + " " + (index + 1),
      });
    } else {
      return;
    }
    setTimeout(() => this.send(message, count, delay, ++index), delay);
  }

  private getLatestMessage(): MessageModel | null {
    const hasMessages = !!this.messages?.length;
    if (!hasMessages && !this.conversation) return null;
    if (!hasMessages) return this.conversation.lastMessage ?? null;
    return this.messages.at(-1);
  }

  private getCreatedOnUtc(): Date {
    const latestMessage = this.getLatestMessage();

    const latestMessageDate = latestMessage
      ? new Date(latestMessage.sentOnUtc ?? latestMessage.createdOnUtc)
      : null;

    const latestGlobalDate =
      this.conversationService.getLatestConversationModifiedTimeOrMessageSentOnUtc();

    // This is done because the message the latest message in the current conversation may be very
    // old compared to the latest message across all conversations we have loaded
    const maxDate =
      latestMessageDate > latestGlobalDate
        ? latestMessageDate
        : latestGlobalDate;

    return getComputedLocalTime(maxDate);
  }

  public submitMessage(newMessage: MessageComposeModel) {
    this.messageService.onMessageSubmitSubject.next();
    this.newMessage = newMessage.content;
    this.focused = true;

    if (!this.newMessage.trim()) {
      return;
    }

    const createdOnUtc = this.getCreatedOnUtc().toISOString();

    const message: MessageModel = {
      content: this.newMessage,
      mentions: newMessage.mentions,
      marker: this.uuidv4(),
      createdOnUtc,
      sentBy: this.userAccount.userId,
      conversationId: this.id,
      statuses: [],
    };
    if (this.quotedMessage) {
      message.replyToMessage = this.quotedMessage;
      message.replyTo = this.quotedMessage.marker;
    }

    const sendingStatus: MessageStatusModel = {
      status: MessageStatuses.Sending,
      createdOnUtc: message.createdOnUtc,
      createdBy: this.userAccount.userId,
    };

    message.statuses.push(sendingStatus);
    this.addOrUpdateMessage(message);

    const createMessage: CreateMessageModel = {
      content: message.content,
      mentions: message.mentions,
      marker: message.marker,
      createdOnUtc: message.createdOnUtc,
    };
    if (this.quotedMessage) {
      createMessage.replyTo = this.quotedMessage.marker;
    }

    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
    }
    this.doSend(message, createMessage, this.id);
    this.resetQuote();

    this.setLastMessage(message);
  }

  doSend(message, createMessage, conversationId) {
    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/{conversationId}/SendMessage".replace(
        "{" + "conversationId" + "}",
        String(conversationId)
      );

    this.sharedService.postObjectById(path, {}, createMessage).subscribe(
      (resp) => {
        const sentSatus: MessageStatusModel = {
          status: MessageStatuses.Sent,
          createdOnUtc: this.getCreatedOnUtc().toISOString(),
          createdBy: this.userAccount.userId,
        };
        message.statuses.push(sentSatus);
        message.id = resp.id;
        this.addOrUpdateMessage(message);
        this.initialLoading = false;
        this.onMessageSentSuccessfully();
        this.scrollOnNewMessage();
      },
      (err) => {
        const e = this.myScrollContainer.nativeElement;
        e.scrollTop = e.scrollHeight;
        if (err === "SyntaxError: Unexpected end of JSON input") {
        } else {
          this.initialLoading = false;
          this.hasUnsentMessageCount += 1;
          message["NOT_SENT"] = true;
          message["NOT_SENT_RETRY_FUNC"] = () => {
            delete message["NOT_SENT"];
            this.hasUnsentMessageCount -= 1;
            this.doSend(message, createMessage, conversationId);
          };

          this.retryMessageTimer = setTimeout(() => {
            if (message["NOT_SENT"]) {
              delete message["NOT_SENT"];
              this.hasUnsentMessageCount -= 1;
              this.doSend(message, createMessage, conversationId);
            }
          }, this.messageRetryGap);
        }
      }
    );
  }

  retry() {}
  clearSendMessageRetryJobs() {
    if (this.sendMessageSub) {
      this.sendMessageSub.unsubscribe();
    }
    if (this.retryMessageTimer) {
      clearTimeout(this.retryMessageTimer);
    }
    this.messages.forEach((message) => {
      if (message["NOT_SENT"]) {
        delete message["NOT_SENT"];
      }
    });
  }

  restoreHeight() {
    const x = (this.heightBeforeLoadingOlderMessages =
      this.myScrollContainer.nativeElement.scrollHeight);
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight - x;
    }, 5);
  }

  restoreHeightBottom() {
    const x = this.myScrollContainer.nativeElement.scrollHeight;
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTop = x - 500;
    }, 5);
  }

  private isNearTop() {
    const element = this.myScrollContainer?.nativeElement;
    if (!element) return false;
    return element.scrollTop < this.isNearTopDistance;
  }

  public onScroll() {
    const hasConversationStartedMessage =
      this.messages?.some((s) => s.type === MessageType.ConversationStarted) ??
      false;
    if (hasConversationStartedMessage) return;

    const oldestMessage = this.getFirstMessageBySentOnUtc(Order.Ascending);

    if (!this.messageId && this.isNearTop()) {
      this.loadingMore = true;
      this.getOlderMessages(
        undefined,
        oldestMessage?.sentOnUtc,
        undefined,
        undefined,
        undefined,
        (messages) => {
          this.insertIncomingSet(messages);
          this.restoreHeight();
          this.loadingMore = false;
        }
      );
    }
    this.setEnableScrollDown();
  }

  setEnableScrollDown() {
    const element = this.myScrollContainer.nativeElement;
    const atBottom =
      element.scrollHeight - element.scrollTop <=
      element.clientHeight + this.isNearBottomDistance;
    if (atBottom) {
      this.enableScrollDown = false;
    } else {
      this.enableScrollDown = true;
    }
  }

  insertIncomingSet(messages: MessageModel[]) {
    if (!messages?.length) return;

    for (const message of messages) {
      this.addMessage(message, {
        disableScroll: true,
        enableAppending: true,
      });
    }

    this.conversationService.messages = this.messages;
  }

  private scrollToBottomNow(): void {
    if (this.notScrolled && this.messages && this.messages.length > 0) {
      const e = this.myScrollContainer.nativeElement;
      if (e.scrollHeight > e.offsetHeight) {
        this.doScrollToBottom();
      }
    }
  }

  private doScrollToBottom(): void {
    const e = this.myScrollContainer.nativeElement;
    try {
      e.scrollTop = e.scrollHeight;
    } catch (err) {}
    if (e.scrollTop != 0) {
      this.notScrolled = false;
    }
  }

  private scrollToBottomIfAtBottomAlready(): void {
    if (this.heightBeforeLoadingOlderMessages) {
      this.heightBeforeLoadingOlderMessages = null;
      return;
    }
    if (!this.isNearBottom()) {
      return;
    }
    this.executeBottomScroll();
  }

  isNearBottom() {
    if (this.enableScrollDown) {
      return false;
    }
    return true;
  }

  executeBottomScroll() {
    if (this.notScrolled) {
      return;
    }
    const e = this.myScrollContainer.nativeElement;
    try {
      const bottom = this.bottomAnchor.nativeElement;
      this.clearNewMessages();
      this.scrollToBottom(bottom);
    } catch (err) {
      e.scrollTop = e.scrollHeight;
    }
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  scrollToNow(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView();
    }, 100);
  }

  openConversationInfo() {
    if (this.type === "SelfChat") {
      this.sharedService.navigateToProfile();
      return;
    }
    this.menu = "";
    setTimeout(() => {
      if (this.conversation.type === ConversationType.TeamChat) {
        if (this.isRolesTab) {
          const chatParticipant =
            this.conversationService.getTeamChatParticipant(this.conversation);
          this.chatParticipant = chatParticipant;
          this.chatParticipantId = chatParticipant.userId;
          this.menu = "message_details";
        } else {
          this.menu = "role_details";
        }
      } else if (this.conversation.type === ConversationType.External) {
        this.menu = "patient_details";
      } else {
        this.menu = "message_details";
      }
    }, 10);
  }

  openProfileInfo(id: string) {
    this.profileInFocus = id;
    this.menu = "";
    setTimeout(() => {
      this.menu = "profile_details";
      this.sidenav.open();
    }, 10);
  }

  closeConversationInfo() {
    this.menu = "";
  }

  closeInfoBox() {
    this.menu = "";
  }

  wave() {
    this.submitMessage({ content: "👋" });
    this.analyticsService.buttonClickEvent("wave");
  }

  openMessageStatus(message: any) {
    if (!message.id) {
      this.sidenav.close();
      return;
    }
    this.sidenav.open();
    this.showConversationInfo = false;
    this.selectedMessage = message;
    this.showMessageStatus = true;
    this.refreshCount++;
    this.menu = "message_status";
  }

  closeMessageStatus() {
    this.showMessageStatus = false;
    this.selectedMessage = null;
    this.menu = "";
  }

  quoteMessage(message: MessageModel) {
    this.messageService.messageQuoted.next();
    this.quotedMessage = message;
  }

  public copyMessage(message: MessageModel) {
    // Based on https://material.angular.io/cdk/clipboard/overview#programmatically-copy-a-string
    const pending = this.clipboard.beginCopy(message.content);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
      }
    };
    attempt();
  }

  public deleteMessage(message: MessageModel) {
    this.conversationsService
      .deleteMessage(message.conversationId, message.marker)
      .subscribe({
        next: (deletedMessage) => {
          this.addOrUpdateMessage(deletedMessage);

          // #TODO update legacy conversation service state
        },
        error: () => {
          AlertComponent.openErrorDialog(this.matDialog);
        },
      });
  }

  public forwardMessage(message: MessageModel) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    const createMessageModel: CreateMessageModel = {
      marker: this.uuidv4(),
      createdOnUtc: this.getCreatedOnUtc().toISOString(),
      forwardFromMessageId: message.marker,
      forwardFromConversationId: this.conversation.id,
    };

    ConversationPickerDialogComponent.openDialog(this.matDialog, {
      message: createMessageModel,
      header: "Forward",
      createNewConversationButtonText: "Forward to a new chat",
      sendButtonText: "Forward Message",
    })
      .afterClosed()
      .pipe(
        filter((result) => result != null),
        switchMap(({ conversationId }) => {
          return this.conversationsService
            .sendMessage(conversationId, createMessageModel)
            .pipe(mapTo(conversationId));
        })
      )
      .subscribe({
        next: (conversationId) => {
          this.conversationsService.navigateToConversation(conversationId);
        },
      });
  }

  addPhotos() {
    LibrarySelectImgsComponent.openDialog(this.matDialog, {
      conversation: this.conversation,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result) return;
          if (result.photos?.length) {
            this.sendPic(this.conversation.id, result.photos);
          }
        },
      });
  }

  attachFiles() {
    const config = new MatDialogConfig();
    config.data = this.uploader;

    this.dialog
      .open(DropBoxComponent, config)
      .afterClosed()
      .subscribe((result: any) => {
        if (result && result.length) {
        }
      });
  }

  sendUploadedDocuments(documents: any[], conversationId: string) {
    if (!documents || !documents.length) {
      return;
    }

    const formattedDocuments: any[] = documents.map((document) => ({
      fileId: document.id,
      marker: this.uuidv4(),
      fileName: document.fileName,
      // fileDescription: !document.fileDescription?'':(this.sharedService.isSpaceOnly(document.fileDescription)?'':document.fileDescription)
      fileDescription: document.fileDescription,
    }));

    this.sendDocument(conversationId, formattedDocuments);
  }

  private sendDocument(conversationId, documents) {
    documents.forEach((document) => {
      const createdOnUtc = this.getCreatedOnUtc();
      const message: any = {
        content: document.content,
        type: "PatientFile",
        marker: document.marker,
        conversationId,
        metadata: {
          fileId: document.fileId,
          fileName: document.fileName,
          fileDescription: document.fileDescription,
        },
        createdOnUtc,
        sentBy: this.userAccount.userId,
        statuses: [],
      };
      const sendingStatus: MessageStatusModel = {
        status: MessageStatuses.Sending,
        createdOnUtc: createdOnUtc.toISOString(),
        createdBy: this.userAccount.userId,
      };
      message.statuses.push(sendingStatus);
      this.addOrUpdateMessage(message);
      this.scrollOnNewMessage();

      this.setLastMessage(message);
    });
    const i = this;

    // We only want to post a select few of the documents properties
    const payload = documents.map((document) => ({
      fileId: document.fileId,
      marker: document.marker,
    }));

    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/" +
      conversationId +
      "/SendPatientFile";
    this.sharedService.postObjectById(path, {}, payload).subscribe(
      (data) => {
        i.onMessageSentSuccessfully();
      },
      (err) => {}
    );
  }

  sendUploadedPics(files: any[], conversationId: string) {
    if (!files || !files.length) {
      return;
    }
    const photos: Array<any> = [];
    for (const file of files) {
      photos.push({
        photoId: file["id"],
        marker: this.uuidv4(),
        // content:!file['fileDescription']?'':(this.sharedService.isSpaceOnly(file['fileDescription'])?'':file['fileDescription'])
        content: file["fileDescription"],
      });
    }
    this.sendPic(conversationId, photos);
  }

  private sendPic(conversationId, photos) {
    photos.forEach((media) => {
      const createdOnUtc = this.getCreatedOnUtc();
      const message: any = {
        content: media.content,
        type: "Photo",
        mediaType: media.mediaType,
        marker: media.marker,
        conversationId,
        metadata: {
          photoId: media.photoId,
        },
        createdOnUtc,
        sentBy: this.userAccount.userId,
        statuses: [],
      };
      const sendingStatus: MessageStatusModel = {
        status: MessageStatuses.Sending,
        createdOnUtc: createdOnUtc.toISOString(),
        createdBy: this.userAccount.userId,
      };
      message.statuses.push(sendingStatus);

      this.addOrUpdateMessage(message);
      this.scrollOnNewMessage();

      this.setLastMessage(message);
    });
    const i = this;
    const path =
      environment.celoApiEndpoint +
      "/api/v2/Conversations/{conversationId}/media/send".replace(
        "{" + "conversationId" + "}",
        String(conversationId)
      );
    this.sharedService
      .postObjectById(path, {}, photos)
      .subscribe((resp: any[]) => {
        i.onMessageSentSuccessfully();
        i.initialLoading = false;
        if (!resp || resp.length == 0) {
          return;
        }
        resp.forEach((message) => {
          i.addOrUpdateMessage(message);
        });
      });
  }

  onMessageSentSuccessfully() {
    this.soundPlayService.playSentSound();
    this.messageService.onMessageSentSubject.next();
    // this.alertService.showSnackBar('Message Sent',4);
    if (this.messageId) {
      setTimeout(() => {
        this.exitSearchView();
      }, 1000);
    }
  }

  private hasImportantChanges() {
    return this.hasUnsentMessageCount > 0;
  }

  public canDeactivate(ask) {
    // called by route candeactivate guard
    if (this.hasImportantChanges()) {
      return ask(); // ask first
    }
    return true;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    if (this.hasImportantChanges()) {
      $event.returnValue = "Are you sure?";
    }
  }

  userUnavailableDialog() {
    this.sharedService.userUnavailableDialog();
  }

  onConversationDataUpdated() {
    this.setPatientId(this.conversation);
    this.setParticipantList();
    this.initializeParticipants();
    this.setConversationType(this.conversation);
    this.connectionService.setUnreadCount();
    this.detectHasLeft(this.userAccount);
    this.setName();
    this.setConversationProfileUri();
    this.updateCurrentUserFlags();

    if (this.leftChat) {
      this.unsubscribeConversationAction(this.conversation.id);
    }
  }

  private updateCurrentUserFlags() {
    if (!this.userAccount?.userId || !this.pMap) {
      this.isConversationAdmin = false;
      return;
    }

    const participant = this.pMap[this.userAccount.userId];

    if (!participant) {
      this.isConversationAdmin = false;
      this.isActiveParticipant = false;
      return;
    }

    this.isConversationAdmin =
      participant.role === ParticipantRole.Administrator;
    this.isActiveParticipant = !participant.leftOnUtc;
  }

  copy(content) {
    this.sharedService.copyConversationLink(
      this.conversation,
      this.type,
      content
    );
  }

  copyEvent(source) {
    this.analyticsService.raiseLinkEvents(this.type.toLowerCase(), source);
  }

  shareViaEmail(link) {
    this.sharedService.invite(
      this.type.toLowerCase(),
      link,
      this.id,
      this.conversation
    );
  }

  linkEvent(source) {
    this.analyticsService.raiseLinkEvents(this.type.toLowerCase(), source);
  }

  resetLink(invitationUri) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.alertService
      .customDialog(
        "Are you sure you want to reset the invite link for " +
          this.conversation.name +
          "?",
        "A new link will be generated and the old link will no longer work to join this " +
          this.type.toLocaleLowerCase() +
          ".",
        "RESET LINK",
        "CANCEL",
        false,
        "",
        ""
      )
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        const code = this.sharedService.getUrlParameter(invitationUri, "code");
        if (!code) {
          return;
        }
        const url =
          environment.celoApiEndpoint +
          "/api/v2/invitations/" +
          code +
          "/reset";
        this.sharedService.postObjectById(url).subscribe(
          (res) => {
            this.loadConversationData(this.id);
            this.alertService.customDialog(
              "Link has been reset",
              "The previous invite link has been reset and a new invite link has been created.",
              "OK",
              "",
              true,
              "",
              ""
            );
            this.analyticsService.raiseLinkResetEvents(
              this.type.toLowerCase(),
              true
            );
          },
          (err) => {
            const errorMessage = "Something went wrong, please try again later";
            this.alertService.confirm("", errorMessage, "Done", "", true);
            this.analyticsService.raiseLinkResetEvents(
              this.type.toLowerCase(),
              false
            );
          }
        );
      });
  }

  toggle() {
    if (this.conversation.invitation?.allowAll) {
      this.alertService
        .customDialog(
          "Are you sure you want to restrict invite access to admins only?",
          "A new link will be generated and the old link will no longer work to join this " +
            this.type.toLowerCase() +
            ".",
          "CONTINUE",
          "CANCEL",
          false,
          "",
          ""
        )
        .afterClosed()
        .subscribe((result) => {
          if (!result) {
            return;
          }
          this.toggleLinkResetByAdminOnly();
        });
    } else {
      this.toggleLinkResetByAdminOnly();
    }
  }

  toggleLinkResetByAdminOnly() {
    const url =
      environment.celoApiEndpoint +
      "/api/v2/conversations/" +
      this.id +
      "/invitation";
    const body = {
      allowAll: !this.conversation.invitation?.allowAll,
    };
    this.conversation.invitation.allowAll =
      !this.conversation.invitation?.allowAll;
    this.sharedService.putObjectById(url, "", body).subscribe(
      (res) => {
        this.loadConversationData(this.id);
        const onlyAdmin = res?.allowAll;
        if (!onlyAdmin) {
          this.alertService.customDialog(
            "Link has been reset",
            "The previous invite link has been reset and a new invite link has been created. Only admins will have access to this new link.",
            "OK",
            "",
            true,
            "",
            ""
          );
          this.analyticsService.raiseEvent("link_toggle_sharing", {
            link_type: this.type.toLowerCase(),
            is_link_reset: true,
            success: true,
          });
          return;
        }
        this.analyticsService.raiseEvent("link_toggle_sharing", {
          link_type: this.type.toLowerCase(),
          is_link_reset: false,
          success: true,
        });
      },
      (err) => {
        this.alertService.confirm(
          "",
          this.sharedService.STANDARD_ERROR_MESSAGE,
          "Done",
          "",
          true
        );
        this.analyticsService.raiseEvent("link_toggle_sharing", {
          link_type: this.type.toLowerCase(),
          success: false,
        });
      }
    );
  }

  setName() {
    this.name = this.conversationService.getName(
      this.conversation,
      this.type == "SelfChat"
    );
  }

  setConversationProfileUri() {
    if (!this.conversation) return;
    this.conversationProfileUri =
      this.conversationsService.getConversationProfileUri(
        this.conversation.id,
        this.conversation.photoId
      );
  }

  public handleShowTeamMemberProfile(member: TeamMember) {
    if (!member.user?.userId) return;
    this.openProfileInfo(member.user?.userId);
  }

  public handleLeaveTeam(team: Team) {
    if (!team.id) throw new Error("Invalid team id");
    this.teamsService.leaveTeam(team.id);
  }

  public addParticipants: UserPickerSubmitCallback = (users) => {
    const participantUserIds = users
      .filter((u) => !u.isDisabled)
      .map((u) => u.id);

    return this.conversationsService
      .addParticipants(this.conversation.id, participantUserIds)
      .pipe(
        map((conversation) => {
          this.onNewConversationInfo(conversation);
        })
      );
  };

  public handleJoinCall(call: Call) {
    this.voipService.navigateToJoinCall(call.conversationId, call.id);
  }

  private handleVideoCallUpdate(update: VideoCallUpdate) {
    if (update.status !== VideoCallStatus.Ended) return;

    const message = this.messages.find(
      (m) =>
        m.type === MessageType.VideoCall &&
        m.metadata.resourceType === "VideoCall" &&
        m.metadata.resourceId === update.id &&
        m.conversationId === update.conversationId
    );
    if (!message) return;

    let resourceCallDurationInSeconds: number | null = null;
    if (update.status === VideoCallStatus.Ended) {
      resourceCallDurationInSeconds =
        (new Date(update.endedOn).getTime() -
          new Date(update.createdOn).getTime()) /
        1000;
    }

    const clone = structuredClone(message);
    clone.content = "Video call ended";
    clone.metadata.resourceStatus = update.status;
    clone.metadata.resourceCallDurationInSeconds = `${resourceCallDurationInSeconds}`;

    this.addOrUpdateMessage(clone);
  }
}
