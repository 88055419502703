<div *ngIf="userStatus && userStatus.updatedOnUtc">
  <span *ngIf="online" class="online">
    <i class="material-icons online_bubble">fiber_manual_record</i>
    <span class="online_text">Online</span>
  </span>
  <span *ngIf="inMinutes">
    Active {{ minutesBetween }} minute<span>{{
      minutesBetween > 1 ? "s" : ""
    }}</span>
    ago
  </span>
  <span *ngIf="inDays">
    Last active {{ userStatus.updatedOnUtc | dateFormat: "relativeDate" }} at
    {{ userStatus.updatedOnUtc | dateFormat: "shortTime" }}
  </span>
</div>
