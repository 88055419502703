import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { VoipService } from "@modules/core/services/voip.service";
import { LibraryLightboxMessageContent } from "@modules/library/library-lightbox/library-lightbox.component";
import * as linkify from "linkifyjs";
import { Subscription } from "rxjs";
import {
  MentionModel,
  MessageModel,
  MessageStatuses,
  MessageType,
} from "types";
import {
  ConversationService,
  LinkService,
  MessageService,
  SharedService,
} from "../../modules/core";
import { LibraryService } from "../../modules/library/library.service";

interface WordBlockModel {
  content: string;
  type: WordBlockType;
  mention?: MentionModel;
}
enum WordBlockType {
  text = "text",
  mention = "mention",
  link = "link",
}

@Component({
  selector: "app-message-card",
  templateUrl: "./message-card.component.html",
  styleUrls: ["./message-card.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MessageCardComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() userAccount: any;
  @Input() message: MessageModel;
  private messageLogicalBlocks: WordBlockModel[] = [];
  @Input() refreshCount: number;
  @Input() messageOriginal: any;
  @Input() id: string;
  @Input() isFirstOfGroup = false;
  @Input() searchQuery: string;
  @Input() pMap: { [key: string]: any };
  @Input() conversationId: string;
  @Input() patientId: string;
  @Input() pic_size: string;
  @Input() conversationType: string;

  @Input() fromMe: Boolean;
  @Input() fromSystem: Boolean;
  @Input() fromSender: Boolean;

  public isForwarded: boolean = false;

  is_image_loaded = false;

  // private clicks = new Subject();
  private subscription: Subscription;
  isWelcomeMessage = false;
  isWelcomeMessage2 = false;
  isSystemMessage = false;
  isPatientFileMessage = false;
  isImageMessage = false;
  isVideoMessage = false;
  isStandardMessage = false;
  isVideoCallMessage = false;
  isVideoCallInProgress = false;

  // hasSender: boolean;
  // fromSystem: boolean;
  // fromMe: boolean;

  sendingStatus = MessageStatuses.Sending;
  emoji =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  text = /(?:[a-zA-Z0-9_])/g;
  messageMetaSub: any;
  subFocus: Subscription;
  callDurationMessage: string | null;

  streetInfoSinage(name) {
    alert(name);
  }

  constructor(
    public dialog: MatDialog,
    public conversationService: ConversationService,
    public sharedService: SharedService,
    public messageService: MessageService,
    private libraryService: LibraryService,
    private linkService: LinkService,
    private voipService: VoipService,
    private router: Router
  ) { }

  ngAfterViewInit() {
    this.setAnchor();
  }

  ngOnChanges() {
    this.messageOriginal = this.message;

    this.setContent();
    this.setEmoji();
    this.setPatientId();
    this.messageLogicalBlocks = this.formatMessageBlocksOfTextAndMentions(
      this.message.content,
      this.message.mentions
    );
    this.messageLogicalBlocks = this.linkifyLinks(this.messageLogicalBlocks);

    this.isForwarded = this.message.metadata?.forwardFromMessageId != null;
    this.updateCallDurationMessage();
  }

  linkifyLinks(blocks: WordBlockModel[]): WordBlockModel[] {
    const linkifiedBlocks = blocks.map((word) => {
      let content = word.content;
      if (word.type != WordBlockType.text) return word;
      if (content.length <= 1) return word;

      const items = linkify.find(content, {
        defaultProtocol: "https",
      });
      if (items.length === 0) return word;

      const linkedWord = {
        content: content,
        link: items[0].href,
        type: WordBlockType.link,
      };

      return linkedWord;
    });
    return linkifiedBlocks;
  }

  formatMessageBlocksOfTextAndMentions(
    message: string,
    mentions: MentionModel[] = []
  ): WordBlockModel[] {
    let blocks: WordBlockModel[] = [];
    if (!message) return blocks;
    let indexProcessed = false;
    let blockIndex = 0;
    for (let index = 0; index < message.length; index++) {
      indexProcessed = false;
      blocks[blockIndex] = blocks[blockIndex]
        ? blocks[blockIndex]
        : { content: "", type: WordBlockType.text };
      mentions?.forEach((mention) => {
        if (mention.index == index) {
          blocks.push({
            content: mention.name,
            type: WordBlockType.mention,
            mention: mention,
          });
          index += mention.name.length;
          index--;
          indexProcessed = true;
          // blockIndex++;
          blockIndex += 2;
          return;
        }
      });
      if (indexProcessed) continue;
      if (message[index] == " ") {
        blocks.push({
          content: " ",
          type: WordBlockType.text,
        });
        blockIndex += 2;
        continue;
      }
      if (message[index] == "\n") {
        blocks.push({
          content: "\n",
          type: WordBlockType.text,
        });
        blockIndex += 2;
        continue;
      } else {
        blocks[blockIndex].content += message[index];
      }
    }
    return blocks;
  }

  handleLinkClick(event: any, link: string) {
    if (this.linkService.isPotentialInvitationLink(link)) {
      event.preventDefault();
      this.linkService.handleLink(link);
    }
  }

  ngOnInit() {
    this.setPatientId();
    this.subFocus = this.sharedService.onFocusChange.subscribe((foucsed) => {
      if (foucsed) {
        const anchor = document.getElementById(
          "messageAnchor" + this.message.id
        ) as HTMLElement;
        if (anchor && this.isScrolledIntoView(anchor)) {
          this.emitMessage();
        }
      }
    });
  }

  isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:

    const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  emitMessage() {
    if (this.messageService && this.messageService.onMessageViewSubject) {
      this.messageService.onMessageViewSubject.next(this.message);
    }
  }

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) { }

  setAnchor() {
    const anchor = document.getElementById(
      "messageAnchor" + this.message.id
    ) as HTMLElement;
    const i = this;
    const observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          i.emitMessage();
        }
      },
      { threshold: [0] }
    );

    observer.observe(anchor);
  }

  setPatientId() {
    if (this.messageMetaSub) {
      this.messageMetaSub.unsubscribe();
    }
    if (
      this.message &&
      (this.message.type === MessageType.Photo ||
        this.message.type === MessageType.PatientFile) &&
      this.userAccount.userId === this.message.sentBy
    ) {
      this.messageMetaSub =
        this.libraryService.messageMetaUpdatedEmitted$.subscribe(
          (meta: any) => {
            if (this.message.metadata.photoId == meta.id) {
              this.message.metadata.uid = meta.patientData.uid;
            }
          }
        );
    }
  }

  setEmoji() {
    if (
      !this.message ||
      !this.message.content ||
      this.message.content.length > 4
    ) {
      return;
    }

    this.message["emoji"] =
      this.isEmoji(this.message.content) && !this.isText(this.message.content);
  }

  isEmoji(text: string) {
    return this.emoji.test(text);
  }

  isText(text: string) {
    return this.text.test(text);
  }

  setContent() {
    // this.is_image_loaded = false;
    this.isWelcomeMessage = false;
    this.isWelcomeMessage2 = false;
    this.isSystemMessage = false;
    this.isPatientFileMessage = false;
    this.isImageMessage = false;
    this.isVideoMessage = false;
    this.isStandardMessage = false;
    this.isVideoCallMessage = false;
    this.fromSystem = false;
    this.fromMe = false;
    this.fromSender = false;

    if (this.message.sentBy === "SYSTEM") {
      if (this.message.type === "ConversationStarted") {
        this.isWelcomeMessage = true;
      } else {
        this.isSystemMessage = true;
      }
    } else if (this.message.type === "PatientFile") {
      this.isPatientFileMessage = true;
    } else if (this.message.type === "Photo") {
      this.isImageMessage = true;
    } else if (this.message.type === MessageType.VideoCall) {
      this.isVideoCallMessage = true;
      this.isVideoCallInProgress =
        this.message.metadata.resourceStatus === "InProgress";
    } else {
      this.isStandardMessage = true;
    }

    if (this.message.mediaType == "Video") {
      this.isImageMessage = false;
      this.isVideoMessage = true;
    }

    if (this.message.sentBy === "SYSTEM") {
      this.fromSystem = true;
    } else {
      this.fromSystem = false;
      if (this.userAccount.userId === this.message.sentBy) {
        this.fromMe = true;
        this.fromSender = false;
      } else {
        this.fromMe = false;
        this.fromSender = true;
      }
    }
  }

  public viewPhoto(photoId) {
    if (!photoId) {
      return;
    }
    const obj: LibraryLightboxMessageContent = {
      mediaType: "message",
      enableMediaNavigation: true,
      conversationId: this.conversationId,
      messageId: this.message.marker,
      payload: {
        id: photoId,
        mediaType: "photo",
      },
    };
    this.libraryService.openLightbox(obj, false, this.conversationType);
  }

  public viewVideo(id) {
    if (!id) {
      return;
    }
    const obj: LibraryLightboxMessageContent = {
      mediaType: "message",
      enableMediaNavigation: true,
      conversationId: this.conversationId,
      messageId: this.message.marker,
      payload: {
        id,
        mediaType: "video",
      },
    };
    this.libraryService.openLightbox(obj, false, this.conversationType);
  }

  public viewFile(fileId) {
    const obj: LibraryLightboxMessageContent = {
      mediaType: "message",
      conversationId: this.message.conversationId,
      messageId: this.message.marker,
      payload: {
        id: fileId,
        mediaType: "document",
      },
    };
    obj["payload"] = {
      id: fileId,
      mediaType: "document",
    };
    this.libraryService.openLightbox(obj, false, this.conversationType);
  }

  public findChat(message: any) {
    this.conversationService.emitFindChat(message);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.messageMetaSub) {
      this.messageMetaSub.unsubscribe();
    }
    if (this.subFocus) {
      this.subFocus.unsubscribe();
    }
  }

  clicked($event) {
    if ($event.target.className == "link") {
      $event.stopPropagation();
    }
  }

  handleMentionClicked(mention: MentionModel) {
    this.messageService.onMentionClickedSubject.next(mention);
  }

  public joinVideoCall($event: MouseEvent) {
    if (!this.isVideoCallInProgress) return;
    $event.stopPropagation();
    this.voipService.navigateToJoinCall(
      this.message.conversationId,
      this.message.metadata.resourceId
    );
  }

  public updateCallDurationMessage() {
    if (this.message?.type !== MessageType.VideoCall || this.message?.metadata?.resourceStatus !== 'Ended') {
      this.callDurationMessage = null;
      return;
    }

    const duration = Number(this.message.metadata?.resourceCallDurationInSeconds);
    if (!duration) {
      this.callDurationMessage = null;
      return;
    }

    if (duration < 60) {
      this.callDurationMessage = "Less than a min";
      return;
    }

    let durationMinutesRounded = Math.round(duration / 60);
    this.callDurationMessage = `${durationMinutesRounded} min`;
  }
}
