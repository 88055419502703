import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, ReplaySubject, Subject, of } from "rxjs";
import { catchError, finalize, retry, switchMap } from "rxjs/operators";
import {
  ConversationModel,
  ConversationModelV2,
  ConversationModelV2ApiPagedResult,
  ConversationParticipantModel,
  ConversationProfilePhotoUpdate,
  ConversationRoleUpdate,
  ConversationType,
  ConversationsV2RequestOptions,
  CreateConversationRequestOptions,
  MessageUpdate,
  PinnedConversationUpdate,
} from "types";
import {
  addOrReplaceElement,
  concatNotNull,
  dateCompareIfNotNull,
  localeCompareIfNotNull,
} from "utils";
import { environment } from "../../../../environments/environment";
import { ApplicationInsightsService } from "../services";
import { ApiService } from "../services/api.service";
import { AuthService } from "../services/auth.service";
import {
  ConversationParticipantModelV2,
  CreateConversationModel,
  MessageModel,
} from "./../../../../types/api-v2";
import { UserService } from "./../services/user.service";
import { AlertService } from "./alert.service";
import { SharedService } from "./shared.service";
import { getConversationName } from "utils/conversation-utils";

/** @deprecated Use `ConversationsService` in `CoreModule`. */
@Injectable({
  providedIn: "root",
})
export class ConversationService {
  page = 0;
  canLoadMore: boolean;
  mutedList: string[];
  notified: string[];
  protected basePath = environment.celoApiEndpoint;
  public findChatEmitted$ = new Subject();

  private conversationPinUpdatedSubject =
    new Subject<PinnedConversationUpdate>();
  public conversationPinUpdated$ =
    this.conversationPinUpdatedSubject.asObservable();

  private conversationProfilePhotoUpdatedSubject =
    new Subject<ConversationProfilePhotoUpdate>();
  public conversationProfilePhotoUpdated$ =
    this.conversationProfilePhotoUpdatedSubject.asObservable();

  public onInboxSorted = new Subject<void>();
  conversations: ConversationModelV2[];
  conversationsLoaded = false;
  public defaultHeaders: Headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    CeloAuthorization: environment.celoAuth,
  });

  messages: MessageModel[];
  public foundChatEmitted$ = new Subject();
  private updatedConversationSubject = new Subject<ConversationModelV2>();
  public updatedConversation$ = this.updatedConversationSubject.asObservable();

  private teamChats: ConversationModelV2[] = [];
  private externalChats: ConversationModelV2[] = [];

  constructor(
    protected httpClient: HttpClient,
    public authService: AuthService,
    public alertService: AlertService,
    public sharedService: SharedService,
    private apiService: ApiService,
    private userService: UserService,
    private applicationInsightService: ApplicationInsightsService
  ) {
    this.mutedList = [];
    this.clearNotifiedIdsInLocal();

    const updatedConversationEmittedSub = this.updatedConversation$.subscribe(
      (updatedConversation: any) => {
        this.replaceConversationById(updatedConversation);
        this.filterEmptyOneOnOnes();
        this.updateNotificationAfterCounting(this.conversations);
      }
    );
  }

  public getLatestConversationModifiedTimeOrMessageSentOnUtc(): Date | null {
    if (!this.conversations?.length) return null;
    const conversationsWithMessages = this.conversations.filter(
      (c) => c.lastMessage?.sentOnUtc
    );
    if (!conversationsWithMessages.length) {
      return new Date(this.conversations[0].lastModifiedOnUtc);
    }
    return new Date(conversationsWithMessages[0].lastMessage.sentOnUtc);
  }

  getChatId(receiver_id: any) {
    const sender_id = this.userService.getUserId(true);
    return this.sharedService.getChatId(receiver_id, sender_id);
  }

  public getOrCreateSelfChat() {
    const currentUserId = this.userService.getUserId(true);
    const selfChatId = `${currentUserId}_${currentUserId}`;
    const getSelfChatPath = `/api/v2/conversations/${selfChatId}`;
    return this.apiService
      .get<ConversationModelV2>({ path: getSelfChatPath })
      .pipe(
        catchError(() => {
          const createConversationPath = `/api/v2/conversations`;
          const body: CreateConversationModel = {
            id: selfChatId,
            type: ConversationType.SelfChat,
          };
          return this.apiService.post<ConversationModelV2>({
            path: createConversationPath,
            body,
          });
        })
      );
  }

  getName(
    conversation: ConversationModel,
    mustIncludeMe?: boolean,
    useTeamChatParticipantName: boolean = false
  ): string {
    if (conversation.type === ConversationType.External) {
      return (
        concatNotNull([
          conversation.patientData?.firstName,
          conversation.patientData?.lastName,
        ]) ?? "External Conversation"
      );
    }

    if (
      (!conversation.type ||
        conversation.type === "Group" ||
        conversation.type === "Case" ||
        conversation.type === "TeamChat") &&
      !useTeamChatParticipantName &&
      conversation.name &&
      conversation.name.trim()
    ) {
      return conversation.name.trim();
    }

    if (conversation.type === "TeamChat" && useTeamChatParticipantName) {
      const participant = this.getTeamChatParticipant(conversation);
      return concatNotNull([participant?.firstName, participant?.lastName]);
    }

    const names = [];
    for (const p of conversation.participants) {
      let name = p.firstName + " " + p.lastName;
      name = name.replace("  ", " ").trim();
      const isMe = p.userId === this.authService.getUserId();
      if ((mustIncludeMe || !isMe) && name) {
        names.push(name);
      }
    }
    return names.join(", ");
  }

  canParticipantSeeTheMessage(
    participantId: string,
    message: MessageUpdate,
    conversation: ConversationModelV2
  ) {
    const participant = this.getParticipant(participantId, conversation);
    if (!participant) {
      this.applicationInsightService.trackTrace("canParticipantSeeTheMessage", {
        participantId,
        message: {
          type: message?.type,
          conversationId: conversation?.id,
          sentOnUtc: message?.sentOnUtc,
        },
        conversation: {
          participants: conversation?.participants?.map((participant) => ({
            userId: participant?.userId,
            asReadToUtc: participant?.asReadToUtc,
          })),
        },
      });
    }
    const messageSentOnUtc = message?.sentOnUtc
      ? new Date(message?.sentOnUtc)
      : "";
    const asReadToUtc = participant?.asReadToUtc
      ? new Date(participant?.asReadToUtc)
      : "";
    const joinedOnUtc = participant?.joinedOnUtc
      ? new Date(participant?.joinedOnUtc)
      : "";
    if (
      (!asReadToUtc || asReadToUtc < messageSentOnUtc) &&
      (!joinedOnUtc || asReadToUtc < messageSentOnUtc)
    ) {
      return true;
    }
    return false;
  }

  getActiveParticipants(
    conversation,
    loggedInUserId?
  ): ConversationParticipantModel[] {
    const participants = [];
    const loggedInUser = this.getParticipant(loggedInUserId, conversation);
    let loggedInUserLeftTime;
    let loggedInUserJoinedTime;
    if (loggedInUser && loggedInUser.leftOnUtc) {
      loggedInUserLeftTime = new Date(loggedInUser.leftOnUtc);
    }
    if (loggedInUser && loggedInUser.joinedOnUtc) {
      loggedInUserJoinedTime = new Date(loggedInUser.joinedOnUtc);
    }

    for (const p of conversation.participants) {
      let participantJoinedTime;
      if (p.joinedOnUtc) {
        participantJoinedTime = new Date(p.joinedOnUtc);
      }
      let participantLeftTime;
      if (p.leftOnUtc) {
        participantLeftTime = new Date(p.leftOnUtc);
      }

      const participantsHasNotLeft =
        !loggedInUserLeftTime && p.leftOnUtc == null;

      const participantNotLoggedInUser = p.userId != loggedInUserId;

      const participantJoinTimeLessThanLoggedInUserLeftTime =
        participantJoinedTime < loggedInUserLeftTime;

      const participantIsNotLoggedInUserAndJoinedBeforeLoggedInUserLeft =
        loggedInUserLeftTime &&
        participantNotLoggedInUser &&
        participantJoinTimeLessThanLoggedInUserLeftTime;

      const participantJoinedAfterLoggedInUser =
        participantJoinedTime > loggedInUserJoinedTime;

      const participantLeftAfterLoggedInUser =
        participantLeftTime > loggedInUserLeftTime;

      const participantJoinedAfterLoggedInUserAndLeftBeforeLoggedInUser =
        participantJoinedAfterLoggedInUser && participantLeftAfterLoggedInUser;

      if (
        participantsHasNotLeft ||
        (participantIsNotLoggedInUserAndJoinedBeforeLoggedInUserLeft &&
          (participantJoinedAfterLoggedInUserAndLeftBeforeLoggedInUser ||
            !participantLeftTime))
      ) {
        participants.push(p);
      }
    }
    return participants;
  }

  getActiveAdminParticipantIds(conversation, loggedInUserId?) {
    let participantIds = [];
    const adminIds = [];
    participantIds = this.getActiveParticipants(conversation, loggedInUserId);
    for (const p of participantIds) {
      if (p["role"] == "Administrator") {
        adminIds.push(p.userId);
      }
    }
    return adminIds;
  }

  mergeConversations(newConversations) {
    return this.conversations.concat(newConversations);
  }

  updateNotificationAfterCounting = (convos: ConversationModelV2[]) => {
    this.sharedService.updateNotificationAfterCounting(convos);
  };

  addParticipantToConversation(conversation: any, participant: any) {
    conversation.participants = conversation.participants
      ? conversation.participants
      : [];
    for (let p of conversation.participants) {
      if (participant.userId == p.userId) {
        return (p = participant);
      }
    }
    conversation.participants.push(participant);
  }

  filterEmptyOneOnOnes() {
    this.conversations = this.conversations.filter((conversation) => {
      if (
        conversation.type !== ConversationType.Chat &&
        conversation.type !== ConversationType.TeamChat
      ) {
        return true;
      }
      return conversation.lastMessage != null;
    });
  }

  public getParticipant(userId, conversation: any): any {
    if (!conversation.participants) return null;
    for (const p of conversation.participants) {
      if (userId == p.userId) {
        return p;
      }
    }
    return null;
  }

  public hasLeft(userId, conversation: any) {
    const p = this.getParticipant(userId, conversation);
    if (p && !!p.leftOnUtc) {
      return true;
    }
    return false;
  }

  public emitFindChat(message: any) {
    this.findChatEmitted$.next(message);
  }

  public emitFoundChat(message: any, searchQuery: string) {
    const finderObject = {};
    finderObject["message"] = message;
    finderObject["searchQuery"] = searchQuery;
    this.foundChatEmitted$.next(finderObject);
  }

  public emitConversationUpdate(conversation: ConversationModelV2) {
    this.updatedConversationSubject.next(conversation);
  }

  getLoggedInParticipant(conversation: any) {
    const userId = this.authService.getUserId();
    let other: any;
    const others = conversation.participants.filter((p) => p.userId === userId);
    if (others[0]) {
      other = others[0];
    }
    return other;
  }

  public getTeamChatParticipant(
    conversation: ConversationModelV2
  ): ConversationParticipantModelV2 | null {
    if (conversation?.type == null) return null;
    if (conversation.type !== ConversationType.TeamChat)
      throw new Error(`Conversation type must be ${ConversationType.TeamChat}`);
    return conversation.participants?.find((p) => p.teamId == null) ?? null;
  }

  public getExternalChatParticipant(
    conversation: ConversationModelV2
  ): ConversationParticipantModelV2 | null {
    if (conversation?.type == null) return null;
    if (conversation.type !== ConversationType.External)
      throw new Error(`Conversation type must be ${ConversationType.External}`);
    const patient = conversation.patientData;
    if (!patient) return null;
    const participant: ConversationParticipantModelV2 = {
      userId: patient.uid,
      firstName: patient.firstName,
      lastName: patient.lastName,
      phoneNumber: patient.phoneNumber,
      isExternal: true,
    };
    return participant;
  }

  public getChatParticipant(conversation: any): any {
    const userId = this.authService.getUserId();
    let other: any;
    const others = conversation.participants.filter((p) => p.userId !== userId);
    if (others[0]) {
      other = others[0];
    }
    return other;
  }

  getConversationType(conversation: any, defaultType?: string): string {
    let type = defaultType ? defaultType : "Group";
    if (!conversation) {
      return type;
    }
    if (conversation["type"]) {
      type = conversation["type"];
    } else if (conversation.patientData) {
      type = "Case";
    }
    return type;
  }

  getParticipantNameById(conversation: any, userId: string): any {
    for (const p of conversation.participants) {
      if (userId == p.userId) {
        return p.firstName;
      }
    }
    return "Someone";
  }

  public insertOrReplaceMuteObject(list, id, mutedTo) {
    const muteObj = {
      conversationId: id,
      mutedToUtc: mutedTo,
    };
    for (let index = 0; index < list.length; index++) {
      if (list[index].conversationId == id) {
        list.splice(index, 1);
        index--;
      }
    }
    list.push(muteObj);
  }

  public saveMuteConversationIdToLocal(
    conversationId: string,
    mutedTo: string
  ) {
    let mutedConversations = JSON.parse(localStorage.getItem("muted"));
    if (!mutedConversations || mutedConversations.length == 0) {
      const newList = [];
      this.insertOrReplaceMuteObject(newList, conversationId, mutedTo);
      mutedConversations = newList;
    } else {
      this.insertOrReplaceMuteObject(
        mutedConversations,
        conversationId,
        mutedTo
      );
    }
    this.mutedList = mutedConversations;
    localStorage.setItem("muted", JSON.stringify(mutedConversations));
  }

  public clearMutedConversationIdsInLocal() {
    localStorage.removeItem("muted");
    this.mutedList = [];
  }

  public isMutedInLocalStorage(conversationId, sentOnUtc) {
    this.mutedList = JSON.parse(localStorage.getItem("muted"));
    let isMuted = false;
    if (!this.mutedList) {
      return false;
    }
    // return (this.mutedList.indexOf(conversationId)!=-1);
    for (const mutedObj of this.mutedList) {
      if (mutedObj["conversationId"] == conversationId) {
        const mutedToDate = new Date(mutedObj["mutedToUtc"]);
        const sentOnDate = new Date(sentOnUtc);
        if (sentOnDate < mutedToDate) {
          isMuted = true;
        }
      }
    }
    return isMuted;
  }

  public isAlreadyNotified(chat_id) {
    const notified = JSON.parse(localStorage.getItem("notified_chats"));
    if (!notified) {
      return false;
    }
    for (const id of notified) {
      if (chat_id == id) {
        return true;
      }
    }
    return false;
  }

  public onNotify(chat_id) {
    let notified = JSON.parse(localStorage.getItem("notified_chats"));
    notified = notified ? notified : [];
    notified.push(chat_id);
    localStorage.setItem("notified_chats", JSON.stringify(notified));
  }

  public clearNotifiedIdsInLocal() {
    localStorage.removeItem("notified_chats");
    this.mutedList = [];
  }

  getMessages(conversationId: any, count, before, after, sortBy?) {
    const path = `/api/Conversations/${conversationId}/GetMessages`;
    const queryParams: Record<string, string> = {};
    if (after !== undefined) {
      queryParams["After"] = after;
    }
    if (before !== undefined) {
      queryParams["Before"] = before;
    }
    if (sortBy !== undefined) {
      queryParams["sortBy"] = sortBy;
    }

    if (count !== undefined) {
      queryParams["Count"] = count;
    }

    return this.apiService.get<MessageModel[]>({
      path,
      queryParams,
    });
  }

  getUnreadTotal() {
    let unreadCount = 0;
    if (this.conversations) {
      for (const c of this.conversations) {
        if (c && c["unreadMessageIds"] && c["unreadMessageIds"].length > 0) {
          unreadCount += c["unreadMessageIds"].length;
        }
      }
    }
    return unreadCount;
  }

  updateUnreadMessageIds(message: any) {
    const conversation = this.getConversationById(message.conversationId);
    if (!conversation) {
      return;
    }
    let unread = conversation["unreadMessageIds"];
    if (unread && unread.length > 0) {
      unread = unread.filter((element) => element != message.id);
    }
    conversation["unreadMessageIds"] = unread;
  }

  markRead(id: string, callback) {
    const path = this.basePath + `/api/Conversations/ClearUnread`;
    const body = {
      conversationId: id,
      asReadToUtc: new Date().toISOString(),
    };
    this.sharedService.postObjectById(path, {}, [body]).subscribe((resp) => {
      if (resp) {
        return callback(resp);
      }
      return callback(undefined);
    });
  }

  getUserV2(id: string) {
    const path = this.basePath + `/api/v2/users/${id}`;
    return this.sharedService.getObjectById(path);
  }

  getUserById(id: string, callback) {
    const path = this.basePath + `/api/v2/users/${id}`;
    this.sharedService.getObjectById(path).subscribe((resp) => {
      if (resp) {
        return callback(resp);
      }
      return callback(undefined);
    });
  }

  getConversationById(id: string) {
    const allConversations = [
      ...(this.conversations ?? []),
      ...this.teamChats,
      ...this.externalChats,
    ];
    return (
      allConversations.find((c) => c.id?.toLowerCase() === id.toLowerCase()) ??
      null
    );
  }

  getConversationByIdApi(id: string): Observable<ConversationModel> {
    const path = `${environment.celoApiEndpoint}/api/v2/conversations/${id}`;
    return this.httpClient.get<ConversationModel>(path).pipe(
      retry(3),
      catchError(() => EMPTY),
      finalize(() => {})
    );
  }

  getConversation(conversationId: string): Observable<ConversationModel> {
    return of(this.getConversationById(conversationId)).pipe(
      switchMap((conversation) => {
        if (conversation) return of(conversation);
        return this.getConversationByIdApi(conversationId);
      })
    );
  }

  getConversationsByApiV2(
    options: ConversationsV2RequestOptions
  ): Observable<ConversationModelV2ApiPagedResult> {
    const path = `${environment.celoApiEndpoint}/api/v2/conversations`;
    return this.sharedService.getObjectById(path, options);
  }

  checkConversationExists(id: string): Observable<boolean> {
    const path = `${environment.celoApiEndpoint}/api/v2/conversations/${id}`;
    const subject = new ReplaySubject<boolean>(1);

    if (this.getConversationById(id)) {
      subject.next(true);
      subject.complete();
    } else {
      this.httpClient.get<any>(path).subscribe({
        next: () => subject.next(true),
        error: (e: HttpErrorResponse) => {
          if (e?.status === 404) {
            subject.next(false);
          } else {
            subject.error(e);
          }
        },
        complete: () => subject.complete(),
      });
    }

    return subject.asObservable();
  }

  addOrReplaceTeamChat(conversation: ConversationModelV2) {
    this.teamChats = addOrReplaceElement(
      this.teamChats,
      conversation,
      (c) => c.id === conversation.id
    );
  }

  addOrReplaceExternalChat(conversation: ConversationModelV2) {
    this.externalChats = addOrReplaceElement(
      this.externalChats,
      conversation,
      (c) => c.id === conversation.id
    );
  }

  /**
   * Should only be used to handle Chat, Group, Case, and SelfChat.
   */
  addOrReplaceConversation(conversation: ConversationModelV2) {
    if (!conversation.id) throw new Error("Invalid conversation id");
    if (
      ![
        ConversationType.Chat,
        ConversationType.Group,
        ConversationType.Case,
        ConversationType.SelfChat,
        ConversationType.TeamChat,
      ].includes(conversation.type)
    ) {
      return;
    }
    this.conversations = this.conversations ? this.conversations : [];
    const existingConversation = this.getConversationById(conversation.id);
    if (!existingConversation) {
      this.conversations.unshift(conversation);
    }
    // replace it if already exist
    else {
      this.replaceConversationById(conversation);
    }
    this.sortConversations();
  }

  replaceConversationDataById(newConversation: any) {
    if (!this.conversations) {
      return;
    }
    for (let index = 0; index < this.conversations.length; index++) {
      if (this.conversations[index].id == newConversation.id) {
        if (newConversation.name) {
          this.conversations[index].name = newConversation.name;
        }
        if (newConversation["lastModifiedOnUtc"]) {
          this.conversations[index]["lastModifiedOnUtc"] =
            newConversation["lastModifiedOnUtc"];
        }
        if (newConversation.patientData) {
          this.conversations[index].patientData = newConversation.patientData;
        }
        if (newConversation.participants) {
          this.conversations[index].participants = newConversation.participants;
        }
        this.conversations[index].invitation = newConversation.invitation;

        this.emitConversationUpdate(this.conversations[index]);
        return;
      }
    }
  }

  replaceConversationById(newConversation: any) {
    if (!this.conversations) {
      return;
    }
    for (let index = 0; index < this.conversations.length; index++) {
      if (this.conversations[index].id == newConversation.id) {
        this.conversations[index] = newConversation;
        return;
      }
    }
  }

  removeConversationById(newConversationId: string) {
    if (!this.conversations) {
      return;
    }
    for (let index = 0; index < this.conversations.length; index++) {
      if (this.conversations[index].id == newConversationId) {
        this.conversations.splice(index, 1);
        return;
      }
    }
  }

  sortConversations() {
    if (this.conversations) {
      this.sortConversation(this.conversations);
      const pinnedConversations = this.conversations.filter((c) =>
        c.participants.some(
          (cp) =>
            cp.userId === this.userService.getUserId(true) &&
            cp.pinnedOnUtc != null
        )
      );
      this.sortByPinnedDate(pinnedConversations);
      const unpinnedConversations = this.conversations.filter(
        (c) => !pinnedConversations.some((pc) => pc.id === c.id)
      );
      this.conversations = [...pinnedConversations, ...unpinnedConversations];
    }

    this.onInboxSorted.next();
  }

  private getConversationSortTime(conversation: ConversationModelV2) {
    if (conversation.lastMessage) {
      const currentUserId = this.userService.getUserId();

      if (conversation.lastMessage.sentBy === currentUserId) {
        return (
          conversation.lastMessage.createdOnUtc ??
          conversation.lastMessage.sentOnUtc
        );
      }

      return (
        conversation.lastMessage.sentOnUtc ??
        conversation.lastMessage.createdOnUtc
      );
    }
    return conversation.lastModifiedOnUtc ?? conversation.createdOnUtc;
  }

  sortConversation(array: ConversationModelV2[]) {
    array.sort((a, b) => {
      const dateA = this.getConversationSortTime(a);
      const dateB = this.getConversationSortTime(b);
      let sortVal = dateCompareIfNotNull(dateA, dateB);
      if (dateA === dateB) {
        let aName = getConversationName(a, this.userService.getUserId());
        let bName = getConversationName(b, this.userService.getUserId());
        sortVal = localeCompareIfNotNull(aName, bName);
        // at this point we no longer do another locale compare, if the IDs are the same then something
        // has gone catastrophically wrong
        if (sortVal === 0) sortVal = localeCompareIfNotNull(a.id, b.id);
      }

      return sortVal;
    });
  }

  sortByName(conversations, searchKeyword: string) {
    searchKeyword = searchKeyword.toLowerCase();
    for (let i = 0; i < conversations.length; i++) {
      if (!conversations[i].name) {
        continue;
      }
      const conversationName: string = conversations[i].name;
      if (conversationName.toLowerCase().indexOf(searchKeyword) != -1) {
        const temp = conversations[i];
        conversations.splice(i, 1);
        conversations.unshift(temp);
      }
    }
  }

  sortByType(conversations, type: string) {
    for (let i = 0; i < conversations.length; i++) {
      if (this.getConversationType(conversations[i]) == type) {
        const temp = conversations[i];
        conversations.splice(i, 1);
        conversations.unshift(temp);
      }
    }
  }

  sortParticipantsByName(participants, searchKeyword: string) {
    searchKeyword = searchKeyword.toLowerCase();
    for (let i = 0; i < participants.length; i++) {
      if (!participants[i].firstName && !participants[i].lastName) {
        continue;
      }
      const firstName: string = participants[i].firstName;
      const lastName: string = participants[i].lastName;
      if (
        firstName.toLowerCase().indexOf(searchKeyword) != -1 ||
        lastName.toLowerCase().indexOf(searchKeyword) != -1
      ) {
        const temp = participants[i];
        participants.splice(i, 1);
        participants.unshift(temp);
      }
    }
  }

  sortByPinnedDate(conversations: ConversationModelV2[]) {
    conversations.sort((a, b) => {
      const dateA = a.participants.find(
        (cp) =>
          cp.userId === this.userService.getUserId(true) &&
          cp.pinnedOnUtc != null
      ).pinnedOnUtc;
      const dateB = b.participants.find(
        (cp) =>
          cp.userId === this.userService.getUserId(true) &&
          cp.pinnedOnUtc != null
      ).pinnedOnUtc;
      return Date.parse(dateB) - Date.parse(dateA);
    });
  }

  /**
   * Adds participants to the given conversation.
   */
  addParticipants(
    conversationId: string,
    participantUserIds: string[]
  ): Observable<ConversationModel> {
    const path = `${environment.celoApiEndpoint}/api/Conversations/${conversationId}/Participants`;
    const payload = participantUserIds.map((userId) => ({ userId }));
    const subject = new Subject<ConversationModel>();

    this.sharedService.postObjectById(path, {}, payload).subscribe({
      next: (conversation) => {
        this.replaceConversationById(conversation);
        subject.next(conversation);
      },
      error: (e) => subject.error(e),
      complete: () => subject.complete(),
    });

    return subject.asObservable();
  }

  /**
   * Updates the roles for users in the given conversation.
   */
  modifyRoles(chatId: string, roles: ConversationRoleUpdate[]) {
    const path = `${environment.celoApiEndpoint}/api/Conversations/${chatId}/Participants/Roles`;
    const subject = new Subject<ConversationModel>();

    this.sharedService.postObjectById(path, {}, roles).subscribe({
      next: (conversation) => {
        this.replaceConversationById(conversation);
        subject.next(conversation);
      },
      error: (e) => subject.error(e),
      complete: () => subject.complete(),
    });

    return subject.asObservable();
  }

  /**
   * Creates a chat between the current user and the given recipient and navigates to it if creation succeeds or
   * a conversation between them already exists.
   *
   * @returns an observable that pushes the conversation if sucessful or an error if the conversation was unable
   * to be created or routed to.
   */
  createAndNavigateToChat(
    recipientUserId: string,
    chatId?: string
  ): Observable<ConversationModel> {
    if (!chatId) {
      chatId = this.getChatId(recipientUserId);
    }

    const subject = new Subject<ConversationModel>();

    this.createChat([recipientUserId], chatId).subscribe({
      next: (conversation: ConversationModel) => {
        if (conversation) {
          this.navigateToConversation(chatId);
          subject.next(conversation);
        }
      },
      error: (e) => {
        subject.error(e);
      },
      complete: () => subject.complete(),
    });

    return subject.asObservable();
  }

  public createConversation(
    options: CreateConversationRequestOptions
  ): Observable<ConversationModel> {
    const path = "/api/v2/Conversations";
    return this.apiService.post({ path, body: options });
  }

  public createAndNavigateToTeamChat(
    options: Omit<CreateConversationRequestOptions, "type">
  ): Observable<ConversationModel> {
    const subject = new Subject<ConversationModel>();

    this.createConversation({
      ...options,
      type: ConversationType.TeamChat,
    }).subscribe({
      next: (conversation: ConversationModel) => {
        if (conversation) {
          this.navigateToConversation(options.id);
          subject.next(conversation);
        }
      },
      error: (e) => {
        subject.error(e);
      },
      complete: () => subject.complete(),
    });

    return subject.asObservable();
  }

  /**
   * Navigates to the conversation with the given id
   */
  navigateToConversation(chatId: string) {
    this.sharedService.navigateByUrl(`/conversations/${chatId}/messages`);
  }

  createChat(
    receiver_ids: string[],
    chat_id: string
  ): Observable<ConversationModel> {
    const chatId = chat_id;
    const participants = [];
    for (const receiver_id of receiver_ids) {
      participants.push({
        userId: receiver_id,
      });
    }
    const convo: any = {
      id: chatId,
      type: "Chat",
      participants,
    };
    return this.createConversation(convo);
  }

  public navigateToSelfChat() {
    const currentUserId = this.userService.getUserId();
    if (!currentUserId) throw Error("Failed to get current user id");
    this.sharedService.navigateToConversation(
      `${currentUserId}_${currentUserId}`
    );
  }

  createSelfChat(userId, callback) {
    const chatId = userId + "_" + userId;
    const convo: any = {
      id: chatId,
      type: "SelfChat",
    };
    const path = `${environment.celoApiEndpoint}/api/v2/conversations`;
    this.sharedService.postObjectById(path, {}, convo).subscribe(
      (res) => {
        callback(res);
      },
      (err) => {}
    );
  }

  updateMuteStatusInConversation(
    participantId,
    conversationId,
    mutedToUtc,
    muteInterval?
  ) {
    this.conversations.forEach((conversation) => {
      if (conversation.id == conversationId) {
        for (let i = 0; i < conversation.participants.length; i++) {
          if (conversation.participants[i].userId == participantId) {
            conversation.participants[i].mutedToUtc = mutedToUtc;
            if (muteInterval) {
              conversation.participants[i].muteInterval = muteInterval;
            }
            conversation["refreshCount"] = conversation["refreshCount"]
              ? ++conversation["refreshCount"]
              : 1;
          }
        }
      }
    });
  }

  updateConversationPhoto(conversationId: string, photoId: string) {
    let conversation = this.conversations.find((c) => c.id === conversationId);

    if (!conversation) return;

    conversation.photoId = photoId;

    let index = this.conversations.findIndex((c) => c.id === conversationId);

    if (index < 0) return;

    this.conversations[index] = conversation;
    this.conversationProfilePhotoUpdatedSubject.next({
      conversationId: conversationId,
      photoId: photoId,
    });
  }

  updateConversationPin(pinnedConversationModel: PinnedConversationUpdate) {
    const conversation = this.conversations.find(
      (c) => c.id === pinnedConversationModel.conversationId
    );

    if (!conversation) {
      this.getConversationByIdApi(
        pinnedConversationModel.conversationId
      ).subscribe({
        next: (conversation) => {
          this.conversations.push(conversation);
          this.updateConversationParticipantPinStatus(pinnedConversationModel);
        },
      });
    } else {
      this.updateConversationParticipantPinStatus(pinnedConversationModel);
    }
  }

  updateConversationParticipantPinStatus(
    pinnedConversationModel: PinnedConversationUpdate
  ) {
    const { conversationId, pinnedOnUtc, userId } = pinnedConversationModel;
    const conversationIndex = this.conversations.findIndex(
      (c) => c.id === conversationId
    );

    if (conversationIndex === -1) {
      this.conversationPinUpdatedSubject.error("Failed to find conversation");
      return;
    }

    const conversationParticipantIndex = this.conversations[
      conversationIndex
    ].participants.findIndex((cp) => cp.userId === userId);

    if (conversationParticipantIndex === -1) {
      this.conversationPinUpdatedSubject.error(
        "Failed to find participant in conversation"
      );
      return;
    }

    this.conversations[conversationIndex].participants[
      conversationParticipantIndex
    ].pinnedOnUtc = pinnedOnUtc;

    this.sortConversations();

    this.conversationPinUpdatedSubject.next(pinnedConversationModel);
  }
}
