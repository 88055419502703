<div class="celo-grid">
  <div class="celo-left-side-nav">
    <div class="convo-inner-grid">
      <div>
        <app-conversations-header
          [isOnCall]="userAccount && userAccount.isOnCall === 'true'"
          (query)="search($event)"
        >
        </app-conversations-header>
      </div>
      <div id="conversations-scroll" class="celo-scrollable">
        <div id="topAnchor"></div>
        <div *ngIf="!searchTerm" class="fh">
          <app-conversation-list
            *ngIf="!isSearchLoading"
            (loadMore)="loadMore()"
            [userAccount]="userAccount ? userAccount : {}"
            [conversations]="conversations || []"
            [canLoadMore]="conversationService.canLoadMore"
            [loading]="loading"
          ></app-conversation-list>
          <div class="loadingcontainer" *ngIf="!conversations">
            <app-empty-state
              [isLoading]="true"
              [message]="'Conversations'"
              [stroke]="1"
              [diameter]="40"
            ></app-empty-state>
          </div>
          <div
            *ngIf="conversations && conversations.length === 0"
            class="no-messages"
          >
            <img
              
              src="../../assets/empty_states/empty-state-inbox.svg"
              alt=""
            />
            <div class="no-results celo-text-18 celo-bold-500">
              Start Messaging
            </div>
            <div class="no-results celo-text-14 celo-grey">
              Securely message your colleagues and mute conversations on days
              off.
            </div>
          </div>
        </div>
        <div *ngIf="searchTerm" class="fh">
          <app-conversation-results
            *ngIf="userAccount"
            [conversations]="results"
            [userAccount]="userAccount"
            [searchKeyword]="searchTerm"
            [canLoadMore]="searchCanLoadMore"
            (loadMore)="loadMore()"
          ></app-conversation-results>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
