import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from "@angular/core";
import { MessageModel, MessageStatuses, FullUserProfileModel } from "@types";
import { SharedService } from "../../modules/core";

@Component({
  selector: "app-message-status-preview-bar",
  templateUrl: "./message-status-preview-bar.component.html",
  styleUrls: ["./message-status-preview-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageStatusPreviewBarComponent implements OnChanges {
  @Input() message: MessageModel;
  @Input() pMap: { [key: string]: any };
  @Input() userAccount: FullUserProfileModel;
  @Input() refreshCount = -1;

  seenBy = [];
  maxBubbles = 5;

  constructor(private sharedService: SharedService) {}

  ngOnChanges() {
    this.seenBy = this.getSeenBy();
  }

  doFilter(stat: MessageStatuses) {
    return this.message.statuses
      .filter((s) => s.status === stat)
      .map((s) => {
        const date = s.createdOnUtc;
        const p: any = this.pMap[s.createdBy]; // Sent status has no createdBy
        return p ? p.firstName : "";
      });
  }

  doFilterBubbles(stat: MessageStatuses) {
    this.sharedService.sortDateArrayByField(
      this.message.statuses,
      "createdOnUtc"
    );
    return this.message.statuses
      .filter((s) => s.status === stat)
      .map((s) => {
        const participant: any = this.pMap[s.createdBy]; // Sent status has no createdBy
        const name = participant ? participant.firstName : "";
        return {
          participant,
          name,
        };
      });
  }

  getSeenBy() {
    return this.doFilterBubbles(MessageStatuses.Read);
  }
  getDelivered() {
    return this.doFilter(MessageStatuses.Delivered);
  }

  getSending() {
    // todo not used
    return this.doFilter(MessageStatuses.Sending);
  }

  getSent() {
    return this.doFilter(MessageStatuses.Sent);
  }
}
