import { HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { environment } from "@env";
import {
  Call,
  CreateCallResponse,
  JoinCallResponse,
  LeaveCallResponse,
  TokenPagedResultOfCall,
  TokenPagedResultOfCallParticipant,
  VideoCallStatus,
  VideoCallUpdate,
} from "@types";
import {
  SubscriptionContainer,
  concatNotNull,
  dateCompareIfNotNull,
  distinct,
  flatMap,
  sorted,
  toMap,
} from "@utils";
import { PinscreenService } from "app/pinscreen/pinscreen.service";
import {
  VoipIncomingCallDialogComponent,
  VoipIncomingCallDialogResult,
} from "app/voip/voip-incoming-call-dialog/voip-incoming-call-dialog.component";
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  RetryConfig,
  Subject,
  catchError,
  concat,
  defer,
  delay,
  first,
  forkJoin,
  fromEvent,
  last,
  map,
  of,
  pipe,
  raceWith,
  retry,
  share,
  shareReplay,
  switchMap,
  takeWhile,
  tap,
  throwError,
  timer
} from "rxjs";
import { DOMExceptionName } from "types/errors";
import { AlertComponent, AlertResult } from "../components";
import { ConService } from "../old";
import { CallParticipant } from "./../../../../types/video-api";
import { ApiService } from "./api.service";
import { ConversationsService } from "./conversations.service";
import { UserService } from "./user.service";
import { ZoomError, ZoomService } from "./zoom.service";

export interface StartCallModel {
  conversationId: string;
}

export interface GenerateCallTokenModel {
  id: string;
}

export interface getParticipantsRequest {
  callId: string;
  userKey?: string;
  count?: number;
  paginationToken?: string;
  latestOnly?: boolean;
}

export interface LeaveCallRequest {
  requestId: string;
  callId: string;
  participantId: string; // Not Celo Id or userKey (userIdentity)
}

interface UpdateCallsOptions {
  conversationId: string;
  updateOtherStatusesTo: VideoCallStatus;
}

enum VoipEventType {
  LeftCall,
  OpenLeaveCallDialog,
  LeaveCallDialogResult,
  GetInProgressCallDetails,
  InProgressCallDetails,
  LeaveCall
}

interface VoipLeftCallEventData {
  type: VoipEventType.LeftCall;
  requestId: string;
  callId?: string | null;
  participantId?: string | null;
}

interface VoipOpenLeaveCallDialogEventData {
  type: VoipEventType.OpenLeaveCallDialog;
}

interface VoipLeaveCallDialogResultEventData {
  type: VoipEventType.LeaveCallDialogResult;
  isConfirmed: boolean;
}

interface VoipGetInProgressCallDetailsEventData {
  type: VoipEventType.GetInProgressCallDetails;
}

interface VoipInProgressCallDetailsEventData {
  type: VoipEventType.InProgressCallDetails;
  call: Call;
}

interface VoipLeaveCallEventData {
  type: VoipEventType.LeaveCall
}

type VoipEventData = VoipLeftCallEventData
  | VoipOpenLeaveCallDialogEventData
  | VoipLeaveCallDialogResultEventData
  | VoipGetInProgressCallDetailsEventData
  | VoipInProgressCallDetailsEventData
  | VoipLeaveCallEventData;

enum OpenCallWindowActionType {
  Focus,
  Open
}

interface OpenCallWindowFocusAction {
  type: OpenCallWindowActionType.Focus;
}

interface OpenCallWindowOpenAction {
  type: OpenCallWindowActionType.Open;
  /** Release the lock associated with opening this call. */
  release: () => void;
}

export type OpenCallWindowAction = OpenCallWindowFocusAction | OpenCallWindowOpenAction;

// FIXME: Refactor path url for api
@Injectable()
export class VoipService implements OnDestroy {
  private openLeaveCallDialogSubject = new Subject<void>();
  public openLeaveCallDialog$ = this.openLeaveCallDialogSubject.asObservable();

  private leaveCallDialogResultSubject = new Subject<VoipLeaveCallDialogResultEventData>();
  private inProgressCallDetailsSubject = new Subject<VoipInProgressCallDetailsEventData>();

  private leaveCallSubject = new Subject<VoipLeaveCallEventData>();
  public leaveCall$ = this.leaveCallSubject.asObservable();

  protected basePath = environment.celoApiEndpoint;

  private callsSubject = new BehaviorSubject<Call[] | null>(null);
  public calls$ = this.callsSubject.asObservable();

  private callParticipantsSubjects: Map<
    string,
    BehaviorSubject<CallParticipant[]>
  > = new Map();

  private subscriptions: SubscriptionContainer = new SubscriptionContainer();

  private incomingCalls: Map<
    string,
    {
      timeout: number | NodeJS.Timeout;
      matDialogRef: MatDialogRef<
        VoipIncomingCallDialogComponent,
        VoipIncomingCallDialogResult
      > | null;
    }
  > = new Map();

  private ringingAudio = new Audio(
    "../../../../assets/sounds/call-ringtone.mp3"
  );
  private currentlyRingingCallId: string | null = null;
  private latestRingingCallId: string | null = null;

  private dialingAudio = new Audio("../../../../assets/sounds/dial-tone.mp3");
  private isDialing: boolean = false;

  private declinedCallIds = new Set<string>();

  /**
   * Note: These are __not__ call IDs. They are randomly generated IDs the client creates __before__ making a request
   * to start/join a call.
   *
   * This is to resolve an edge case where a user starts/joins a call and then leaves it before the response to
   * the request to start/join a call is received. In this case we have insufficient information to make a leave
   * call request at the time the user left the call. The solution is to record what calls the user has left
   * and when we get a response from any start/join call request, check if the user has already left that call, and, if
   * so, immediately make a request to leave the call.
   */
  private leftCallRequestIds = new Set<string>();

  private channelEventListener: (event: MessageEvent<VoipEventData>) => void;
  private channel = new BroadcastChannel('voip-channel');

  private callWindowTarget: string = "CeloCallWindow";

  private callWindowParentLockName: string = "CallWindowParentLock";
  private callWindowChildLockName: string = "CallWindowChildLock";

  private callWindow: Window | null = null;
  private releaseCallLock: (() => void) | null = null;

  private inProgressCall: Call | null = null;

  private leaveCallDialogRef: MatDialogRef<AlertComponent, AlertResult> | null = null;

  public constructor(
    private apiService: ApiService,
    private conService: ConService,
    private userService: UserService,
    private matDialog: MatDialog,
    private zoom: ZoomService,
    private pinScreen: PinscreenService,
    private conversationsService: ConversationsService
  ) {
    const videoCallUpdateSubscription =
      this.conService.videoCallUpdate$.subscribe({
        next: this.handleIncomingCall.bind(this),
      });

    const afterUnlockedSubscription = this.pinScreen.afterUnlocked$.subscribe({
      next: this.handleUnlock.bind(this),
    });

    this.subscriptions.add(
      videoCallUpdateSubscription,
      afterUnlockedSubscription
    );

    // Setup Audio
    this.ringingAudio.loop = true;
    this.ringingAudio.preload = "auto";
    this.dialingAudio.loop = true;
    this.dialingAudio.preload = "auto";

    this.channelEventListener = (event: MessageEvent<VoipEventData>) => {
      switch (event.data.type) {
        case VoipEventType.LeftCall:
          this.handleLeftCallEventData(event.data);
          break;
        case VoipEventType.OpenLeaveCallDialog:
          this.handleOpenLeaveCallDialogEventData(event.data);
          break;
        case VoipEventType.LeaveCallDialogResult:
          this.handleLeaveCallDialogResultEventData(event.data);
          break;
        case VoipEventType.GetInProgressCallDetails:
          this.handleGetInProgressCallDetailsEventData(event.data);
          break;
        case VoipEventType.InProgressCallDetails:
          this.handleInProgressCallDetailsEventData(event.data);
          break;
        case VoipEventType.LeaveCall:
          this.handleLeaveCallEventData(event.data);
          break;
        default:
          console.error("Unhandled VOIP event", event);
      }
    };

    this.channel.addEventListener('message', this.channelEventListener)
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.channel.removeEventListener('message', this.channelEventListener)
  }

  private handleLeftCallEventData(data: VoipLeftCallEventData) {
    const { requestId, callId, participantId } = data;
    if (!callId || !participantId) return;

    const leaveCallRequest: LeaveCallRequest = { requestId, callId, participantId };

    const lockName = concatNotNull([data.type, data.requestId], '_');

    try {
      navigator.locks.request(lockName, { ifAvailable: true }, (lock) => {
        if (!lock) return;
        this.leaveCallInternal(leaveCallRequest);
      })
    } catch (error) {
      if (!(error instanceof DOMException)) throw error;
      if (error.name !== DOMExceptionName.InvalidStateError && error.name !== DOMExceptionName.SecurityError) {
        throw error;
      }
      this.leaveCallInternal(leaveCallRequest);
    }
  }

  private handleOpenLeaveCallDialogEventData(event: VoipOpenLeaveCallDialogEventData) {
    this.openLeaveCallDialogSubject.next();
  }

  private handleLeaveCallDialogResultEventData(event: VoipLeaveCallDialogResultEventData) {
    this.leaveCallDialogResultSubject.next(event);
  }

  private handleGetInProgressCallDetailsEventData(event: VoipGetInProgressCallDetailsEventData) {
    this.postInProgressCallDetailsEvent();
  }

  private handleInProgressCallDetailsEventData(event: VoipInProgressCallDetailsEventData) {
    this.inProgressCallDetailsSubject.next(event);
    this.updateCalls([event.call])
  }

  private handleLeaveCallEventData(event: VoipLeaveCallEventData) {
    this.leaveCallSubject.next(event);
  }

  private clearInProgressCallDetails() {
    this.inProgressCall = null;
  }

  private setInProgressCallDetails(call: Call) {
    const clone: Call = structuredClone(call);
    clone.isOpen = true;
    this.inProgressCall = clone;
    this.postInProgressCallDetailsEvent();
  }

  public playDialingAudio() {
    if (!this.isDialing) this.dialingAudio.currentTime = 0;
    this.dialingAudio.play();
    this.isDialing = true;
  }

  public stopDialingAudio() {
    this.stopDialingAudioInternal();
  }

  private stopDialingAudioInternal(resumeWhenRingingStops: boolean = false) {
    this.dialingAudio.pause();
    if (!resumeWhenRingingStops) this.isDialing = false;
  }

  public playRingingAudio(callId: string) {
    if (this.userService.isDoNotDisturbActive()) return;
    if (!this.dialingAudio.paused) this.stopDialingAudioInternal(true);

    // If audio is already playing for the given call ID then allow it to
    // continue playing without any interruptions
    if (this.currentlyRingingCallId === callId && !this.ringingAudio.paused)
      return;
    this.stopRingingAudio(false);
    this.ringingAudio.currentTime = 0;
    this.ringingAudio.play();
  }

  private stopRingingAudio(resumeDialingAudio: boolean = true) {
    this.ringingAudio.pause();
    if (resumeDialingAudio && this.isDialing) this.playDialingAudio();
  }

  private removeIncomingCall(
    callId: string,
    action?: VoipIncomingCallDialogResult["action"] | 'Muted'
  ) {
    if (action === "Locked") return;

    if (!action || action === "Decline" || action === "Muted") {
      this.declinedCallIds.add(callId);
    }

    const dialog = this.incomingCalls.get(callId);
    if (dialog) {
      dialog.matDialogRef?.close();
      if (dialog.timeout) clearTimeout(dialog.timeout);
    }

    this.incomingCalls.delete(callId);
    this.conService.dismissVideoCallNotification(callId);
    if (callId === this.currentlyRingingCallId) this.stopRingingAudio();
    this.currentlyRingingCallId = null;
  }

  private updateIncomingCall(
    update: VideoCallUpdate,
    timeout: number | NodeJS.Timeout,
    matDialogRef: MatDialogRef<
      VoipIncomingCallDialogComponent,
      VoipIncomingCallDialogResult
    > | null
  ) {
    const dialog = this.incomingCalls.get(update.id);
    if (!dialog) return;
    clearTimeout(dialog.timeout);
    this.incomingCalls.set(update.id, {
      timeout,
      matDialogRef,
    });
  }

  private handleIncomingCall(update: VideoCallUpdate) {
    // Call popup windows should not handle incoming calls
    if (window.name === this.callWindowTarget) return;

    this.updateCalls([update]);

    // Calls started by the current user should not trigger the incoming call dialog
    if (update.createdBy === this.userService.getUserId(true)) return;

    // Ignore any other calls while a call is ringing
    if (
      this.currentlyRingingCallId &&
      this.currentlyRingingCallId !== update.id
    )
      return;

    switch (update.status) {
      case VideoCallStatus.InProgress:
        const isCurrentlyRingingCall =
          this.currentlyRingingCallId === update.id;
        const isLocked = this.pinScreen.isLocked();
        this.currentlyRingingCallId = update.id;
        this.latestRingingCallId = update.id;

        let matDialogRef: MatDialogRef<
          VoipIncomingCallDialogComponent,
          VoipIncomingCallDialogResult
        > | null = null;

        const getIncomingCallConversationSubscription =
          this.conversationsService
            .getConversation(update.conversationId, { latestOnly: true })
            .pipe(first())
            .subscribe({
              next: (conversation) => {
                const currentUserId = this.userService.getUserId(true);

                const currentUserParticipant = conversation.participants.find(
                  (p) => p.userId === currentUserId
                );
                const isMuted =
                  this.conversationsService.isConversationMutedForParticipant(
                    currentUserParticipant
                  );

                const isDoNotDisturbActive = this.userService.isDoNotDisturbActive();

                // Don't show the incomming call dialog if the associated conversation is muted or the user has
                // do not disturb enabled
                if (isMuted || isDoNotDisturbActive) {
                  this.removeIncomingCall(update.id, "Muted");
                  return;
                };

                this.playRingingAudio(update.id);

                if (!isLocked) {
                  // Only open a dialog if the application isn't locked
                  matDialogRef = VoipIncomingCallDialogComponent.openDialog(
                    this.matDialog,
                    {
                      callId: update.id,
                      conversationId: update.conversationId,
                      maxRingLength: update.maxRingLength,
                    }
                  );

                  const dialogClosedSubscription = matDialogRef
                    .afterClosed()
                    .subscribe({
                      next: (result) => {
                        this.removeIncomingCall(update.id, result?.action);
                      },
                    });
                  this.subscriptions.add(dialogClosedSubscription);
                }

                const timeout = setTimeout(() => {
                  this.removeIncomingCall(update.id);
                }, update.maxRingLength * 1000);

                if (isCurrentlyRingingCall) {
                  this.updateIncomingCall(update, timeout, matDialogRef);
                } else {
                  this.incomingCalls.set(update.id, {
                    timeout,
                    matDialogRef,
                  });
                }
              },
            });

        this.subscriptions.add(getIncomingCallConversationSubscription);
        break;
      case VideoCallStatus.Ended:
        this.removeIncomingCall(update.id);
        break;
      default:
        throw new Error(
          `Unhandled VideoCall message status: '${update.status}'`
        );
    }
  }

  private handleUnlock() {
    // Show the oldest incoming call that hasn't already been dismissed
    const inProgressCalls = this.callsSubject.value?.filter(
      (call) =>
        call.status === VideoCallStatus.InProgress &&
        !this.declinedCallIds.has(call.id)
    );

    if (!inProgressCalls) return;

    let call =
      inProgressCalls.find((s) => s.id === this.currentlyRingingCallId) ??
      inProgressCalls.find((s) => s.id === this.latestRingingCallId);

    if (!call) {
      const calls = sorted(inProgressCalls, (a, b) =>
        dateCompareIfNotNull(a.startedOn, b.startedOn)
      );
      if (!calls.length) return;
      call = calls[0];
    }

    this.handleIncomingCall(call);
  }

  /**
   * Tries to acquire the relevant lock for a call. Returns an observable that emits a function that can be called to
   * release the lock or emits an error if the lock could not be acquired.
   */
  private acquireCallLock(): Observable<{ release: () => void }> {
    return new Observable((subscriber) => {
      const isChild = window.name === this.callWindowTarget;
      const lockName = isChild ? this.callWindowChildLockName : this.callWindowParentLockName;

      let releaseLock: (() => void) | null = null;

      navigator.locks.request(lockName, { ifAvailable: true }, (lock) => {
        if (subscriber.closed) return;
        return new Promise<void>((resolve) => {
          if (!lock) {
            subscriber.error();
            return;
          };

          releaseLock = resolve;

          subscriber.next({
            release: () => resolve()
          });
          subscriber.complete();
        });
      });
    })
  }

  public isCallInProgress(): Observable<boolean> {
    // This method will not catch all cases, but covers most of the common edge cases.
    // A known case which will not be caught by this is if a user closes the parent window, reopens it, and then starts
    // another call before the child window has acquired it's lock. In this case there is a failsafe which will prevent
    // multiple calls from being started - the same window target is always used so the previous call will simply be
    // be replaced by the new one.
    return defer(async () => {
      const snapshot = await navigator.locks.query();
      return snapshot.held.some(lock =>
        lock.name === this.callWindowParentLockName ||
        lock.name == this.callWindowChildLockName);
    })
  }

  /**
   * Opens a leave call dialog if a call is already in progress. Returns an observable that emits true if there is an
   * in progress call and the user hasn't confirmed they would like to leave it, otherwise emits false.
   */
  public openLeaveCallDialogIfRequired(): Observable<boolean> {
    return this.isCallInProgress().pipe(switchMap((isCallInProgress) => {
      if (!isCallInProgress) return of(false);
      return this.openLeaveCallDialogInCallWindow()
    }));
  }

  public openLeaveCallDialog() {
    this.leaveCallDialogRef?.close();

    this.leaveCallDialogRef = AlertComponent.openDialog(this.matDialog, {
      title: "Leave Call",
      message: "Are you sure you want to leave the call?",
      acceptOnly: false,
      reverseButtonOrder: true,
      acceptButtonText: "Leave",
      closeButtonText: "Cancel",
      disableClose: true
    })

    return this.leaveCallDialogRef.afterClosed().pipe(
      switchMap((isConfirmed) => {
        const data: VoipLeaveCallDialogResultEventData = {
          type: VoipEventType.LeaveCallDialogResult,
          isConfirmed,
        };
        this.channel.postMessage(data);
        return of(isConfirmed);
      }));
  }

  private openLeaveCallDialogInCallWindow(): Observable<boolean> {
    const maxWindowCloseDelay = 50;

    if (!this.callWindow) {
      return new Observable((subscriber) => {
        const innerSubscription = this.leaveCallDialogResultSubject.pipe(
          first(),
          switchMap((result: VoipLeaveCallDialogResultEventData | null) => {
            if (!result?.isConfirmed) return of(true);
            this.postLeaveCallEvent();
            return of(false).pipe(delay(maxWindowCloseDelay));
          })
        ).subscribe({
          next: (value) => subscriber.next(value),
          error: (err) => subscriber.next(err),
          complete: () => subscriber.complete()
        });

        this.postLeaveCallDialogEvent();

        return () => {
          innerSubscription.unsubscribe();
        };
      })
    }

    if (this.callWindow.closed) return of(false);

    // This is not perfect - we can only focus the child window if this window opened it. At the moment we've
    // chosen to accept that we won't always be able to focus the call window without usage of a service worker.
    this.callWindow.focus();

    return new Observable((subscriber) => {
      const innerSubscription = fromEvent(this.callWindow, 'beforeunload').pipe(map(() => false))
        .pipe(
          raceWith(this.leaveCallDialogResultSubject.pipe(map(result => result.isConfirmed))),
          first(),
          switchMap((isLeft) => {
            if (!isLeft) return of(true);
            const closeCallWindowObservable = timer(0, maxWindowCloseDelay).pipe(
              takeWhile((_, i) => i === 0 || (this.callWindow && !this.callWindow.closed)),
              last(),
              map(() => false),
              share()
            );
            this.callWindow?.close();
            return closeCallWindowObservable;
          })
        ).subscribe({
          next: (value) => subscriber.next(value),
          error: (err) => subscriber.error(err),
          complete: () => subscriber.complete()
        });

      this.postLeaveCallDialogEvent();

      return () => {
        innerSubscription.unsubscribe();
      }
    })
  }

  private postGetInProgressCallDetailsEvent() {
    const data: VoipGetInProgressCallDetailsEventData = {
      type: VoipEventType.GetInProgressCallDetails,
    }
    this.channel.postMessage(data);
  }

  private postInProgressCallDetailsEvent() {
    if (!this.inProgressCall) return;
    const data: VoipInProgressCallDetailsEventData = {
      type: VoipEventType.InProgressCallDetails,
      call: this.inProgressCall
    }
    this.channel.postMessage(data);
  }

  private postLeaveCallEvent() {
    const data: VoipLeaveCallEventData = {
      type: VoipEventType.LeaveCall
    };
    this.channel.postMessage(data);
  }

  private postLeaveCallDialogEvent() {
    const data: VoipOpenLeaveCallDialogEventData = {
      type: VoipEventType.OpenLeaveCallDialog
    };
    this.channel.postMessage(data);
  }

  private openCallWindow(conversationId: string, callId?: string | null) {
    const openWindowObservable = this.openLeaveCallDialogIfRequired()
      .pipe(
        switchMap((isCallInProgress) => {
          if (isCallInProgress) return EMPTY;
          return this.acquireCallLock();
        }),
        map(({ release }) => {
          const action: OpenCallWindowOpenAction = {
            type: OpenCallWindowActionType.Open,
            release
          };
          return action;
        })
      )

    // Focus call window if it's already open for this conversation
    this.isCallInProgress().pipe(switchMap((isCallInProgress) => {
      if (!isCallInProgress) return openWindowObservable;

      const inProgressCallDetailsObservable = this.inProgressCallDetailsSubject.pipe(
        raceWith(timer(250).pipe(map(() => null))),
        first(),
        switchMap((details) => {
          if (!details || details.call.conversationId !== conversationId) {
            return openWindowObservable;
          }

          const action: OpenCallWindowFocusAction = {
            type: OpenCallWindowActionType.Focus,
          }

          return of(action);
        }),
        share());

      this.postGetInProgressCallDetailsEvent();

      return inProgressCallDetailsObservable;
    })).subscribe({
      next: (action: OpenCallWindowAction) => {
        if (action.type === OpenCallWindowActionType.Focus) {

          if (this.callWindow) {
            this.callWindow.focus();
            return;
          }

          AlertComponent.openDialog(this.matDialog, {
            title: "Return to call",
            message: "We are unable to return to this call automatically. Please find the call window and select it manually."
          });
          return;
        }

        const { release } = action;

        try {
          // Open the popup window scaled down and centered on top of the parent window
          const scaleFactor = 0.8;
          const width = Math.max(100, window.outerWidth * scaleFactor);
          const height = Math.max(100, window.outerHeight * scaleFactor);
          const left = (window.screenLeft + window.outerWidth / 2) - (width / 2);
          const top = (window.screenTop + window.outerHeight / 2) - (height / 2);

          const featureMap = new Map<string, number>([
            ['popup', 1],
            ['width', width],
            ['height', height],
            ['left', left],
            ['top', top]
          ]);
          const features = Array.from(featureMap.entries()).map(([k, v]) => `${k}=${v}`).join(',');

          const search = new URLSearchParams({ conversationId });
          if (callId) search.append('callId', callId)

          this.callWindow = window.open(`/call?${search}`, this.callWindowTarget, features);

          if (!this.callWindow) {
            // Window failed to open. This could be because the user has blocked popups.
            AlertComponent.openDialog(this.matDialog, {
              title: 'Enable Pop-ups',
              message: "Please ensure you have enabled pop-ups to use this feature and try again.",
              acceptOnly: true,
              acceptButtonText: 'Okay'
            })
            release();
            return;
          }

          // Poll call window to see if it's been closed - this is to handle the case where a user closes the call
          // window before we are able to register any event listeners to listen for it being closed
          // We can stop polling once we've registered our beforeunload listener
          const callWindowClosedInterval = setInterval(() => {
            if (this.callWindow && !this.callWindow.closed) return;
            release();
            clearInterval(callWindowClosedInterval);
          }, 50);

          // Only register the beforeunload event handler after the page has been loaded. This is because in some cases
          // the browser fires the 'unload' event on the initial navigation in a popup window.
          this.callWindow.addEventListener('load', () => {
            this.callWindow.addEventListener('beforeunload', () => {
              release();
              this.callWindow = null;
            });
            clearInterval(callWindowClosedInterval);
          });
        } catch {
          // We must release the lock if anything goes wrong otherwise a user will be unable to start anymore calls
          release();
        }
      },
      error: (err) => {
        console.error('Error opening call window', err);
        AlertComponent.openErrorDialog(this.matDialog);
      }
    });
  }

  public navigateToStartCall(conversationId: string) {
    this.openCallWindow(conversationId);
  }

  public navigateToJoinCall(conversationId: string, callId: string) {
    this.openCallWindow(conversationId, callId);
  }

  private leaveCallMap(requestId: string) {
    return pipe(
      switchMap<
        CreateCallResponse | JoinCallResponse,
        Observable<CreateCallResponse | JoinCallResponse>
      >((response) => {
        if (!this.leftCallRequestIds.has(requestId)) {
          return of(response);
        }

        return this.leaveCallInternal({
          callId: response.call.id,
          participantId: response.participant.id,
          requestId,
        }).pipe(switchMap(() => EMPTY));
      })
    );
  }

  public isCallWindow(): boolean {
    return window.name === this.callWindowTarget;
  }

  /**
   * Start a call and join it immediately if successful.
   *
   * This should only be used on the call screen. Use `navigateToStartCall` to
   * start a call from elsewhere in the application.
   */
  public startCall(
    requestId: string,
    conversationId: string
  ): Observable<CreateCallResponse> {
    // shareReplay(1) is used to prevent this request from being aborted if the observable is unsubscribed from.
    // We need to do this because we cannot know how far the backend has processed this request by the time we abort it,
    // so the call may have already been started on the backend by the time we abort it, and we need to ensure that we
    // leave the call immediately if the user left the call before we received a response.
    return this.acquireCallLock().pipe(
      tap({
        next: ({ release }) => {
          this.releaseCallLock = release;
        }
      }),
      switchMap(() => this.startCallInternal({
        conversationId: conversationId,
      })),
      tap({
        next: (response) => this.setInProgressCallDetails(response.call)
      }),
      this.leaveCallMap(requestId),
      shareReplay(1)
    )
  }

  /**
   * Join a call.
   *
   * This should only be used on the call screen. Use `navigateToJoinCall` to
   * start a call from elsewhere in the application.
   */
  public joinCall(
    requestId: string,
    callId: string
  ): Observable<JoinCallResponse> {
    // shareReplay(1) is used to prevent this request from being aborted if the observable is unsubscribed from.
    // We need to do this because we cannot know how far the backend has processed this request by the time we abort it,
    // so the call may have already been started on the backend by the time we abort it, and we need to ensure that we
    // leave the call immediately if the user left the call before we received a response.
    return this.acquireCallLock().pipe(
      tap({
        next: ({ release }) => {
          this.releaseCallLock = release;
        }
      }),
      switchMap(() => this.generateToken({
        id: callId
      })),
      tap({
        next: (response) => this.setInProgressCallDetails(response.call)
      }),
      this.leaveCallMap(requestId),
      shareReplay(1)
    );
  }

  public joinSession(token: string, sessionName: string) {
    return this.userService.getUserObservable().pipe(
      first(user => user !== null),
      switchMap((user) => {
        const userName = concatNotNull([user.firstName, user.lastName]);
        return this.zoom.joinSession({
          sessionName,
          token,
          userName,
        });
      }))
  }

  private startCallInternal(
    options: StartCallModel
  ): Observable<CreateCallResponse> {
    const path = `/api/v2/Conversations/${options.conversationId}/videocalls/start`;
    return this.apiService.post({
      basePath: this.basePath,
      path,
    });
  }

  private generateToken(
    options: GenerateCallTokenModel
  ): Observable<JoinCallResponse> {
    const path = `/api/v2/VideoCalls/${options.id}/join`;
    return this.apiService.post({
      basePath: this.basePath,
      path,
    });
  }

  private updateCalls(calls: Call[], options?: Partial<UpdateCallsOptions>) {
    const callsMap = toMap(
      calls,
      (c) => c.id,
      (c) => c
    );

    const updatedCalls: Call[] =
      this.callsSubject.value?.map((call) => {
        if (!call.id) return call;
        const existingCall = call;
        const updatedCall = callsMap.get(call.id);

        if (
          existingCall.conversationId === options?.conversationId &&
          options?.updateOtherStatusesTo != null
        ) {
          existingCall.status = options.updateOtherStatusesTo;
        }

        return updatedCall ?? existingCall;
      }) ?? [];

    const newCalls = distinct([...updatedCalls, ...calls], (c) => c.id);
    this.callsSubject.next(newCalls);
  }

  private updateCallsTap(options?: Partial<UpdateCallsOptions>) {
    const pages: TokenPagedResultOfCall[] = [];
    return pipe(
      tap<TokenPagedResultOfCall>({
        next: (page) => pages.push(page),
        complete: () =>
          this.updateCalls(
            pages.flatMap((page) => page.data ?? []),
            options
          ),
      })
    );
  }

  public getInProgressCall(conversationId: string): Observable<Call | null> {
    const path = "/api/v2/VideoCalls";
    let params = new HttpParams()
      .set("ConversationId", conversationId)
      .set("Count", 1)
      .set("Status", VideoCallStatus.InProgress);
    return this.apiService
      .get({
        basePath: this.basePath,
        path,
        queryParams: params,
      })
      .pipe(
        this.updateCallsTap({
          conversationId: conversationId,
          updateOtherStatusesTo: VideoCallStatus.Ended,
        }),
        switchMap(() => {
          // Wait a short duration for any call popups to respond
          const observable = this.inProgressCallDetailsSubject.pipe(raceWith(timer(50)), first(), switchMap(() => {
            return this.calls$.pipe(
              map(
                (s) =>
                  s?.find(
                    (c) =>
                      c.conversationId === conversationId &&
                      c.status === VideoCallStatus.InProgress
                  ) ?? null
              ),
              share()
            );
          }))

          this.postGetInProgressCallDetailsEvent();

          return observable;
        })
      );
  }

  private updateCallParticipants(
    callId: string,
    participants: CallParticipant[]
  ) {
    let subject = this.callParticipantsSubjects.get(callId);
    if (!subject) {
      subject = new BehaviorSubject<CallParticipant[]>([]);
      this.callParticipantsSubjects.set(callId, subject);
    }

    const participantMap = toMap(
      participants,
      (p) => p.id,
      (p) => p
    );

    const updatedParticipants: Call[] =
      subject.value?.map((participant) => {
        if (!participant.id) return participant;
        return participantMap.get(participant.id) ?? participant;
      }) ?? [];

    const newParticipants = distinct(
      [...updatedParticipants, ...participants],
      (c) => c.id
    );
    subject.next(newParticipants);
  }

  private updateCallParticipantsTap(callId: string) {
    const pages: TokenPagedResultOfCallParticipant[] = [];
    return pipe(
      tap<TokenPagedResultOfCallParticipant>({
        next: (page) => pages.push(page),
        complete: () =>
          this.updateCallParticipants(
            callId,
            pages.flatMap((p) => p.data)
          ),
      })
    );
  }

  // #TODO refactor
  public getCallParticipants(
    options: getParticipantsRequest
  ): Observable<CallParticipant[]> {
    const path = `/api/v2/VideoCalls/${options.callId}/participants`;
    let params = new HttpParams();
    if (options.userKey) {
      params = params.set("UserKey", options.userKey);
    }
    if (options.count) {
      params = params.set("Count", options.count);
    }
    if (options.paginationToken) {
      params = params.set("PaginationToken", options.paginationToken);
    }

    const participants$ = this.apiService
      .get<TokenPagedResultOfCallParticipant>({
        basePath: this.basePath,
        path,
        queryParams: params,
      })
      .pipe(
        this.updateCallParticipantsTap(options.callId),
        map((p) => p.data ?? []),
        shareReplay(1)
      );

    participants$.subscribe();

    if (options.latestOnly) return participants$;

    return concat(
      participants$,
      defer(() => {
        const subject = this.callParticipantsSubjects.get(options.callId);
        return subject.asObservable();
      })
    );
  }

  // #TODO refactor
  public getAllCallParticipants(
    options: getParticipantsRequest
  ): Observable<CallParticipant[]> {
    const path = `/api/v2/VideoCalls/${options.callId}/participants`;
    let params = new HttpParams();
    if (options.userKey) {
      params = params.set("UserKey", options.userKey);
    }
    if (options.count) {
      params = params.set("Count", options.count);
    }
    if (options.paginationToken) {
      params = params.set("PaginationToken", options.paginationToken);
    }

    return concat(
      this.apiService
        .getAllByToken<TokenPagedResultOfCallParticipant>({
          basePath: this.basePath,
          path,
          queryParams: params,
        })
        .pipe(
          this.updateCallParticipantsTap(options.callId),
          flatMap((page) => page.data)
        ),
      defer(() => {
        const subject = this.callParticipantsSubjects.get(options.callId);
        return subject.asObservable();
      })
    );
  }

  private leaveCallInternal(
    options: LeaveCallRequest
  ): Observable<LeaveCallResponse> {
    const path = `/api/v2/VideoCalls/${options.callId}/participants/${options.participantId}/leave`;
    return this.apiService
      .post<LeaveCallResponse>({
        basePath: this.basePath,
        path,
      })
      .pipe(
        tap({
          next: () => {
            this.leftCallRequestIds.delete(options.requestId);
            this.clearInProgressCallDetails();
            const call = this.callsSubject.value.find(call => call.id === options.callId);
            const clone = structuredClone(call);
            call.isOpen = false;
            this.updateCalls([clone]);
          },
        })
      );
  }

  public leaveCall(
    requestId: string,
    callId?: string | null,
    participantId?: string | null
  ) {
    this.releaseCallLock?.();
    this.releaseCallLock = null;

    // Notify any parent windows that this call needs to be left
    const data: VoipLeftCallEventData = {
      type: VoipEventType.LeftCall,
      requestId,
      callId,
      participantId
    }
    this.channel.postMessage(data);

    this.leftCallRequestIds.add(requestId);

    this.clearInProgressCallDetails();

    const retryConfig: RetryConfig = {
      count: 20, // Max count is added as a safeguard, we never want to allow something to run infinitely
      delay: (error, retryCount) => {
        // Keep retrying while camera is starting
        if (
          !(error instanceof ZoomError) ||
          error.type !== "INVALID_OPERATION_CAMERA_IS_STARTING"
        ) {
          return throwError(() => error);
        }
        return timer(1000);
      },
    };

    const observable = concat(
      forkJoin([
        this.zoom.stopVideo().pipe(
          retry(retryConfig),
          catchError(() => EMPTY)
        ),
        this.zoom.muteAudio().pipe(catchError(() => EMPTY)),
      ]),
      this.zoom.leave().pipe(retry(3))
    ).pipe(
      last(),
      switchMap(() => {
        if (!callId || !participantId) return EMPTY;

        // We still may need to try leave the call in this context as there may not be any other windows open to handle
        // the message we posted; however, this operation is not guaranteed to execute in some cases, e.g. if the window
        // is closed.
        return this.leaveCallInternal({
          requestId,
          callId,
          participantId,
        });
      }),
      shareReplay(1)
    );

    observable.subscribe({
      error: (err) => {
        console.error("Failed to leave call", err);
      },
    });

    return observable;
  }
}
