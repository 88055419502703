import pack from "../../package.json";

export interface Environment {
  enableAppInsightsHeartbeatLogging: boolean;
  production: boolean;
  celoAuth: string;
  celoApiEndpoint: string;
  celoBroadcastApiEndpoint: string;
  celoAuthApiEndpoint: string;
  celoSocketLocation: string;
  celoVideocallApiEndpoint: string;
  origin: string;
  invitationOrigin: string;

  clientId: string;
  onboardLink: string;
  appVersion: string;
  emailThrottleInSeconds: number;
  env: string;

  workspaceAdminUrl: string;
  applicationInsightsConnectionString: string;

  defaultLockoutPeriodSeconds: number;

  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}

export const defaultEnvironment: Environment = {
  enableAppInsightsHeartbeatLogging: true,
  production: false,
  celoAuth: "59ac8c4bb45f5b0001e38d913d00e8f4a95b45a46ab694d4b8fbdef3",
  celoApiEndpoint: "https://localhost:44393",
  celoBroadcastApiEndpoint: "https://localhost:44393",
  celoAuthApiEndpoint: "https://localhost:44361",
  celoSocketLocation: "https://localhost:44393",
  celoVideocallApiEndpoint: "https://localhost:44393",
  origin: "http://localhost:4200",
  invitationOrigin: "link-dev.celohealth.com",

  clientId: "celo.desktop.6CC9BEAE2341",
  onboardLink: "https://celo-user.celohealth.com/onboard",
  appVersion: `web-${pack.version}`,
  emailThrottleInSeconds: 30,
  env: "dev",

  workspaceAdminUrl: "https://workspace-admin.celohealth.com/",
  applicationInsightsConnectionString:
    "InstrumentationKey=7c233ad3-872b-4946-94b5-73431ef4f70a;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/",

  defaultLockoutPeriodSeconds: 300,

  firebaseConfig: {
    apiKey: "AIzaSyDw5uexXjgdmQ3IVdzzWhqmVnyI3tDYx2M",
    authDomain: "celo-1470698531705.firebaseapp.com",
    databaseURL: "https://celo-1470698531705.firebaseio.com",
    projectId: "celo-1470698531705",
    storageBucket: "celo-1470698531705.appspot.com",
    messagingSenderId: "564504357537",
    //staging
    appId: "1:564504357537:web:f1b11015adb64027c31ee0",
    measurementId: "G-MS32MM395T",
  },
};
