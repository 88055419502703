import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
  ConService,
  ConnectionStatus,
  ConversationsService,
} from "@modules/core";
import { config } from "configurations/config";
import { Subscription, throttleTime } from "rxjs";
import {
  ConversationModelV2,
  ConversationProfilePhotoUpdate,
  ConversationType,
  ConversationsV2RequestOptions,
  PinnedConversationUpdate,
  PinnedMode,
} from "types";
import { SubscriptionContainer, distinct } from "utils";
import { environment } from "../../../environments/environment";
import { ConversationService, SharedService, SnackbarService } from "../core";
import { UserService } from "./../core/services/user.service";

@Component({
  selector: "app-conversations",
  templateUrl: "./conversations.component.html",
  styleUrls: ["./conversations.component.scss"],
})
export class ConversationsComponent implements OnInit, OnDestroy {
  conversations: ConversationModelV2[];
  isSearchLoading = false;
  searchTerm: string = null;
  userAccount: any;
  defaultPageSize = 20;
  subjectFromMessages = null;
  canLoadMore = false;
  results: ConversationModelV2[] | undefined;
  searchPage: any = 0;
  searchCanLoadMore: any;
  loading: boolean;
  searchSub: any;
  inboxSubscriber: Subscription | null = null;
  pinSubscriber: Subscription | null = null;
  profilePhotoSubscriber: Subscription | null = null;

  private isInitialConversationsLoaded: boolean = false;

  private subscriptions = new SubscriptionContainer();

  constructor(
    private conversationService: ConversationService,
    public sharedService: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private conversationsService: ConversationsService,
    private conService: ConService
  ) {}

  ngOnInit() {
    this.inboxSubscriber = this.conversationService.onInboxSorted.subscribe(
      () => {
        this.conversations = this.conversationService.conversations;
        this.changeDetectorRef.detectChanges();
      }
    );

    this.profilePhotoSubscriber = this.conversationService.conversationProfilePhotoUpdated$.subscribe({
      next: (data: ConversationProfilePhotoUpdate) => {        
        if(!this.results) return;
        const resultConversationIndex = this.results.findIndex(
          (c) => c.id === data.conversationId
        );

        if(resultConversationIndex === -1) return;

        this.results[resultConversationIndex].photoId = data.photoId;
      }
    });

    this.pinSubscriber =
      this.conversationService.conversationPinUpdated$.subscribe({
        next: (data: PinnedConversationUpdate) => {
          if (!this.results) return;

          const resultConversationIndex = this.results.findIndex(
            (c) => c.id === data.conversationId
          );

          if (resultConversationIndex === -1) {
            this.snackbarService.show(
              "Conversation could not be pinned, please try again",
              3
            );
            throw new Error("Failed to find conversation");
          }

          const resultConversationParticipantIndex = this.results[
            resultConversationIndex
          ].participants.findIndex((cp) => cp.userId === data.userId);

          if (resultConversationParticipantIndex === -1) {
            this.snackbarService.show(
              "Conversation could not be pinned, please try again",
              3
            );
            throw new Error("Failed to find participant in conversation");
          }

          this.results[resultConversationIndex].participants[
            resultConversationParticipantIndex
          ].pinnedOnUtc = data.pinnedOnUtc;
        },
      });

    const userAccountSubscription = this.userService.currentUser$.subscribe({
      next: (userAccount) => (this.userAccount = userAccount),
    });

    // Load initial conversations after user has been loaded or if the current user changes
    const userIdSubscription = this.userService.userId$.subscribe({
      next: (userId) => {
        if (!userId) return;
        this.conversationService.getOrCreateSelfChat().subscribe({
          next: () => this.loadInitialConversations(),
          error: () => this.loadInitialConversations(),
        });
      },
    });

    if (this.conversationService.conversations?.length) {
      this.conversations = this.conversationService.conversations;
    }

    this.sharedService.setTitle(config.title);

    const unreadConversationIdsSubscription = this.conversationsService
      .getUnreadConversationIds({
        conversationTypes: [
          ConversationType.Chat,
          ConversationType.Case,
          ConversationType.Group,
          ConversationType.TeamChat,
        ],
        isNonTeamParticipant: true,
      })
      .subscribe();

    const connectionStatusSubscription = this.conService.connectionStatus$
      .pipe(
        throttleTime(5000, null, {
          leading: true,
          trailing: true,
        })
      )
      .subscribe({
        next: (connectionStatus) => {
          if (connectionStatus !== ConnectionStatus.Connected) return;
          if (!this.isInitialConversationsLoaded) return;

          this.updateConversations((conversations) => {
            this.conversationService.conversations = distinct(
              [...conversations, ...this.conversationService.conversations],
              (c) => c.id
            );
            this.handleConversations();
          });
        },
      });

    this.subscriptions.add(
      userAccountSubscription,
      userIdSubscription,
      unreadConversationIdsSubscription,
      connectionStatusSubscription
    );
  }

  replaceConversationById(updatedConversation: any) {
    for (const i in this.conversations) {
      if (this.conversations[i].id == updatedConversation.id) {
        this.conversations[i] = updatedConversation;
      }
    }
  }

  loadInitialConversations() {
    this.conversationService.page = 0;
    this.updateConversations((conversations, isMore) => {
      const filtered = this.filterOutLeftTeamChats(conversations);

      this.conversationService.conversations = [];
      this.conversationService.conversations =
        this.conversationService.mergeConversations(filtered);
      this.handleConversations();
      this.isInitialConversationsLoaded = true;
    });
  }

  private filterOutLeftTeamChats(
    conversations: ConversationModelV2[]
  ): ConversationModelV2[] {
    const currentUserId = this.userService.getUserId();

    if (!currentUserId) throw new Error("Failed to get current user Id");

    return conversations.filter((c) => {
      if (c.type !== ConversationType.TeamChat) return true;
      if (!c.participants) return false;

      const currentUserParticipant = c.participants.find(
        (p) => p.userId === currentUserId
      );

      return currentUserParticipant.leftOnUtc == null;
    });
  }

  updateConversations = (
    callback: (conversations: ConversationModelV2[], isMore: boolean) => void
  ) => {
    this.loading = true;

    const params: ConversationsV2RequestOptions = {
      pageSize: this.defaultPageSize,
      page: this.conversationService.page,
      pinnedMode: PinnedMode.PinnedTop,
    };

    if (this.searchTerm) {
      params.search = this.searchTerm;
    }

    this.conversationService.getConversationsByApiV2(params).subscribe({
      next: (page) => {
        this.loading = false;
        this.conversationService.canLoadMore = page.hasNext;
        this.conversationService.conversationsLoaded = true;
        callback(page.data, page.hasNext);
      },
      error: () => (this.loading = false),
    });
  };

  handleConversations() {
    this.conversationService.filterEmptyOneOnOnes();
    this.conversationService.updateNotificationAfterCounting(
      this.conversationService.conversations
    );
    this.isSearchLoading = false;
    this.conversationService.clearMutedConversationIdsInLocal();
    this.conversationService.sortConversations();
    this.conversations = this.conversationService.conversations;
  }

  search($event) {
    const query = $event;
    if (!query || query.length < config.minSearchLength) {
      this.searchTerm = "";
      this.isSearchLoading = false;

      const anchor = document.getElementById("topAnchor") as HTMLElement;
      this.scrollTo(anchor);

      // Only trigger if user has input anything
      if (query.length) {
        this.snackbarService.show(
          `Please enter at least ${config.minSearchLength} characters to begin your search.`
        );
      }
      return;
    }
    this.searchTerm = query;
    this.isSearchLoading = true; // set it here, we don't show it when polling.
    this.results = undefined;
    this.searchPage = 0;
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    this.searchConversations();
  }

  searchConversations() {
    const path = `${environment.celoApiEndpoint}/api/v2/Conversations`;
    const params = {
      Search: this.searchTerm,
      Page: this.searchPage,
      PageSize: 50,
    };
    this.searchSub = this.sharedService
      .getObjectById(path, params)
      .subscribe((response) => {
        this.results = this.results ? this.results : [];
        this.results = this.results.concat(response.data);
        this.searchCanLoadMore = response["hasNext"];
        if (this.searchCanLoadMore) {
          setTimeout(() => {
            this.loadMore();
          }, 500);
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    if (this.subjectFromMessages) {
      this.subjectFromMessages.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
    if (this.inboxSubscriber) {
      this.inboxSubscriber.unsubscribe();
    }
  }

  loadMore() {
    if (!this.searchTerm) {
      this.conversationService.page++;
      this.updateConversations((conversations) => {
        this.conversationService.conversations = distinct(
          [...conversations, ...this.conversationService.conversations],
          (c) => c.id
        );
        this.handleConversations();
      });
      return;
    }
    this.searchPage++;
    this.searchConversations();
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
