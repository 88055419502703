import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import {
  AlertService,
  ApplicationInsightsService,
  AuthService,
  ConversationsService,
  LinkService,
  SharedService,
  UserAccountService,
} from "@modules/core";
import { VoipService } from "@modules/core/services/voip.service";
import { initReconnect } from "@utils";
import { config } from "configurations/config";
import { PinscreenService } from "./pinscreen/pinscreen.service";

const supportedBrowsers = {
  Chrome: 60,
  Edge: 16,
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy, OnInit {
  isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent,
    );

  isBrowserSupported = false;
  isBigScreen = window.innerWidth > 500;
  browser: any;
  navHidden: boolean = true;
  focused = true;

  constructor(
    private pinScreenService: PinscreenService,
    public sharedService: SharedService,
    public linkService: LinkService,
    public alertService: AlertService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private userAccountService: UserAccountService,
    private router: Router,
    private dialog: MatDialog,
    private applicationInsightsService: ApplicationInsightsService,
    private conversationsService: ConversationsService,
    private voipService: VoipService,
  ) { }

  ngOnInit() {
    this.applicationInsightsService.initialize();
    initReconnect();

    this.registerIcons(this.iconRegistry, this.sanitizer);
    this.setBrowserSupport();

    this.pinScreenService.isLocked$.subscribe({
      next: () => {
        this.handleInvitationCode();
      },
    });

    // This is here to support legacy components/services that assume
    // a user's account is initially loaded
    this.authService.userId$.subscribe({
      next: (userId) => {
        if (!userId) return;
        this.userAccountService.getUserAccount(true);
      },
    });

    this.sharedService.onFocusChange.subscribe((focused) => {
      this.focused = focused;
      if (focused) {
        this.onRegainFocus();
      }
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navHidden = false;
        if (
          val.url.startsWith("/login") ||
          val.url.startsWith("/signin-oidc") ||
          val.url.startsWith("/?") ||
          val.url.startsWith("/invite?") ||
          val.url.startsWith("/signout-callback-oidc") ||
          val.url.startsWith("/pin") ||
          val.url.startsWith("/set-password") ||
          val.url.startsWith("/onboard") ||
          val.url.startsWith("/unauthorized") ||
          val.url == "/" ||
          val.url == ""
        ) {
          this.navHidden = true;
        }
      } else if (val instanceof NavigationStart) {
        this.dialog.closeAll();

        if (!val.url.startsWith("/network"))
          this.sharedService.setTitle(config.title);
      }
    });

    this.conversationsService.unreadConversationIds$.subscribe({
      next: (unreadConversationIds) => {
        this.sharedService.updateRemoteUnreadConversationIds(
          unreadConversationIds,
        );
      },
    });
  }

  handleInvitationCode() {
    // Invite code is stored in auth service during auth initialization
    const invitationCode = this.authService.getInvitationCode();
    if (
      !invitationCode ||
      !this.authService.isAuthenticated() ||
      this.pinScreenService.isLocked() ||
      this.router.url.indexOf("/onboard/") != -1
    ) {
      return;
    }
    this.authService.removeInvitationCode();
    this.linkService.handleInviteCode(invitationCode, true);
  }

  ngOnDestroy() {
    this.userAccountService.clearMemory();
  }

  onRegainFocus() {
    if (this.router.url.indexOf("/onboard/") != -1) return;

    if (!this.sharedService.isOnline()) return;

    if (this.router.url.indexOf("/pin") != -1)
      this.handleLoggedInStateOnPinScreen();
    this.handleRegainedFocusState();
  }

  handleRegainedFocusState() {
    if (!this.authService.isAuthenticated()) {
      if (this.router.url == "/") {
        return;
      }
      this.router.navigate(["/"]);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      // window.location.reload();
    } else {
      if (this.router.url == "/") {
        window.location.reload();
        // this.login();
      }
    }
  }

  setBrowserSupport() {
    this.browser = this.getBrowser();
    if (
      this.browser.name &&
      supportedBrowsers[this.browser.name] &&
      this.browser.version >= supportedBrowsers[this.browser.name]
    ) {
      this.isBrowserSupported = true;
    }
  }

  getBrowser() {
    let ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bEdge\/(\d+)/);
      if (tem != null) {
        return { name: "Edge", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }

  @HostListener("document:mouseleave", ["$event"])
  onMouseLeave(e) { }

  login() {
    this.sharedService.navigateTo("/");
  }

  handleLoggedInStateOnPinScreen() {
    if (!this.authService.isAuthenticated()) {
      return location.reload();
    }
    this.userAccountService.getUserAccount(false, (data) => {
      let id = this.authService.getUserId();
      if (data.userId != id) {
        return location.reload();
      }
    });
  }

  isCall(): boolean {
    const urlArray = this.router.url.split("/");
    return urlArray[urlArray.length - 1].split("?")[0] === "call";
  }

  registerIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      "celo-network",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/overrides-icons-icon-1.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "message",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/glyphs-messages-white.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "message-orange",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/glyphs-create-new.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "remove-red",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/remove-photo.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "photos",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-photosv2.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "photos-white",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-photos-whitev2.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "documents",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-documentsv2.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "consents",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-consentsv2.png",
      ),
    );
    iconRegistry.addSvgIcon(
      "home_outline",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-home.svg"),
    );

    iconRegistry.addSvgIcon(
      "email_outline",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-email.svg"),
    );

    iconRegistry.addSvgIcon(
      "phone_outline",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-phone.svg"),
    );

    iconRegistry.addSvgIcon(
      "lookup-button",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/lookup-button.svg",
      ),
    );

    iconRegistry.addSvgIcon(
      "ic-emr",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-emr.svg"),
    );

    iconRegistry.addSvgIcon(
      "ic-emr-red",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-emr.svg"),
    );

    iconRegistry.addSvgIcon(
      "new-case",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-case.svg"),
    );
    iconRegistry.addSvgIcon(
      "new-group",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-group.svg"),
    );

    iconRegistry.addSvgIcon(
      "icon-dnd",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-dnd.svg"),
    );

    iconRegistry.addSvgIcon(
      "icon-info",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-info.svg"),
    );

    iconRegistry.addSvgIcon(
      "icon-privacy",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-privacy.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-security",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-security.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-contactus",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-contactus.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-userdocuments",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-userdocuments.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-invitecolleagues",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-invitecolleagues.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-helpcenter",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-helpcenter.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-verified",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-verified.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-unverified",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-unverified.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-verified-workspace",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-verified-workspace.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-message",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/message.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-documents-consents",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-save-send.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-pin",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-pin.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-unpin",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-unpin.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-mute",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-mute.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-unmute",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-unmute.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-clear-badge",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-clear-badge.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-archive",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-archive.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-notifications",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-notifications.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-arrow-down",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/icon-arrow-down.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-close",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-close.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-video",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-video.svg"),
    );
    iconRegistry.addSvgIcon(
      "ic-share-link",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-share-link.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "ic-group",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-group.svg"),
    );
    iconRegistry.addSvgIcon(
      "ic-settings",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-settings.svg"),
    );
    iconRegistry.addSvgIcon(
      "ic-case",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-case.svg"),
    );
    iconRegistry.addSvgIcon(
      "ic-remove-users",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-remove-users.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-edit",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-edit.svg"),
    );
    iconRegistry.addSvgIcon(
      "icon-add-outline",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-add-outline.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "icon-join-workspace",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-join-workspace.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "ic-workspace-member",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-workspace-member.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "ic-add-colleagues",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-add-colleagues.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "admin-settings",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/admin-settings.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "ic-share",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-share.svg"),
    );
    iconRegistry.addSvgIcon(
      "ic-reset",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-reset.svg"),
    );
    iconRegistry.addSvgIcon(
      "avatar-patient",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/avatar-patient.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "avatar-group",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/avatar-group.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "avatar-workspace",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/avatar-workspace.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "add-filled",
      sanitizer.bypassSecurityTrustResourceUrl(
        "/assets/icons/ic-add-filled.svg",
      ),
    );
    iconRegistry.addSvgIcon(
      "ic-roles",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-roles.svg"),
    );
    iconRegistry.addSvgIcon(
      "ic-alert",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ic-alert.svg"),
    );
    iconRegistry.addSvgIcon(
      "video-on",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/video-on.svg"),
    );
    iconRegistry.addSvgIcon(
      "video-off",
      sanitizer.bypassSecurityTrustResourceUrl("/assets/icons/video-off.svg"),
    );
  }
}
