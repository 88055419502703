<div class="parent">
  <div *ngIf="!previewMode">
    <div>
      <app-celo-pick-participants
        [title]="title ? title : ''"
        [participants]="participants"
        [selectedParticipantIds]="selectedParticipantIds"
        [hideProfession]="false"
        (pick)="onUpdate($event)"
        (preview)="openPreview($event)"
        [showBadge]="true"
        [noResultsMessage]="'Add another participant to make them an admin.'"
      ></app-celo-pick-participants>
    </div>
    <div class="buttons">
      <div *ngIf="hasChanged && !saving" class="p-2">
        <app-celo-cancel-save-buttons
          [showNow]="true"
          (cancel)="cancel()"
          (save)="saving = true; saveParticipants()"
        >
        </app-celo-cancel-save-buttons>
      </div>
    </div>
  </div>
  <div *ngIf="previewMode && previewId">
    <button mat-icon-button (click)="previewMode = false; previewId = ''">
      <i class="material-icons">close</i>
    </button>
    <app-profile [contactId]="previewId" [hideSecureMessageButton]="true">
    </app-profile>
  </div>
</div>
